import React, {useState, useEffect} from "react";
import {Wrap} from "./ElementsMembre";


export default function MembreNewsST({className}) {
 

    return (
        <Wrap className={className}>
          <h3 className="center homemadeTitle initial orange">Mes newsletters</h3>
        </Wrap>
  );
}
