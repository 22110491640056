import React from "react";
import styled from 'styled-components';
import {FaYoutube} from "react-icons/fa";

const Btn = styled.a`
text-decoration: none;
background: #000;
border-radius: 20px;
display: flex; 
align-items: center; 
justify-content: center;
align-items: center;
gap: 10px;
padding: ${props => props.padding || "15px 20px"};
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;
color: #fff;
font-size: 18px;
font-weight: 400;
transition: all .2s ease-out;
&>svg {
    font-size: 28px;
}
  &:hover {
    transform: scale(1.1);
    text-decoration: none;
    }

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "auto"};
    margin: auto;
}
`;


export default function BoutonYTST(props) {

    return <Btn className={props.className} margin={props.margin} padding={props.padding} width={props.width} widthMob={props.widthMob} href={props.href} target={props.target}><FaYoutube/>{props.lib}</Btn>   


}