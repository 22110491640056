import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import PopErrorST from "../PopupsST/PopError";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import FormLocalisationST from "../FormsST/FormLocalisation";
import { AiOutlineCloseCircle,AiOutlineCheckCircle } from "react-icons/ai";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import PointsRelaisListeST from "./PointsRelaisListe";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;

const Link = styled.button`
border: none;
background: none;
color: ${props => props.theme.orangeST};
font-size: 20px;
text-decoration: underline;
text-align: left;
padding: 20px 0;
&:hover {
    color: ${props => props.theme.greenST};
}
`
const Title = styled.div`
color: ${props => props.theme.orangeST};
font-size: 20px;
display: flex;  
gap: 10px;
justify-content: center;
align-items: center;
`

const Mode = styled.div`
color: ${props => props.theme.grey02ST};
font-size: 20px;
`

const Message = styled.div`
position: relative;
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
  &>svg {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    &.red {
      color: #f61400;
    }
  }
}
`;

export default function LivraisonST({className,onNotInStockChange}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user?.Contact); 
  const mag = useSelector(state => state.magasin?.magasin);
  const basket = useSelector(state => state.basket);
  const relais = useSelector(state => state.relais?.relais);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [showMode, setShowMode] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRelais, setIsLoadingRelais] = useState(false);
  const [nomMag, setNomMag] = useState(mag ? mag.Nom : "WEB");
  const [idMag, setIdMag] = useState(mag ? mag.ID : 2);
  const [modeLivr, setModeLivr] = useState((idMag === 2 && !relais) ? "Livraison à domicile" : (idMag !== 2 && !relais) ? "Retrait en magasin " + nomMag : "Livraison en point relais " + (relais?.Nom || ""));
  const [stock, setStock] = useState([]);
  const [blockSteps, setBlockSteps] = useState(false);
  const [mode, setMode] = useState((idMag === 2 && !relais) ? "livraison" : (idMag !== 2 && !relais) ? "retrait" : "relais");
  const [pointsR, setPointsR] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');

  const handleSearch = (data) => {
    console.log("Search Data:", data);
    const { name, postcode, city } = data;
    if (name || postcode || city) {
      const formattedAddress = `${name || ''}, ${postcode || ''}, ${city || ''}`;
      setSearchData(formattedAddress);
    } else {
      setSearchData(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
  };

  const handleSelectedPoint = (point) => {
    setSelectedPoint(point);
    dispatch({
      type: "SET_RELAIS",
      payload: {
        ID: point.identifiant,
        Nom: point.nom,
        Adresse1: point.adresse1,
        Adresse2: point.adresse2,
        Adresse3: point.adresse3,
        CP: point.codePostal,
        Ville: point.localite
      }
    });
    setModeLivr("Livraison en point relais "+ (relais?.Nom || ""));
  };

  const newSearch = (value) => {
    const today = new Date().toISOString().split('T')[0];
    setIsLoadingRelais(true);
    axios.post(`/users/pointlivraisonliste`, { Adresse: value, DateExpedition: selectedDate || "" })
      .then((response) => {
        setPointsR(response?.data?.PointLivraisonListe?.Result?.listePointRetraitAcheminement);
        setIsLoadingRelais(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingRelais(false);
        setIsErrorVisible(true);
        setTxt(err?.PointLivraisonListe?.Erreur ? err?.PointLivraisonListe?.Erreur : "Aucun point relais n'a été trouvé pour votre recherche");
      });
  }; 
  
  const newSearchByContact = () => {
    const today = new Date().toISOString().split('T')[0];
    setIsLoadingRelais(true);
    axios.post(`/users/pointlivraisonliste`, { DateExpedition: selectedDate || "" })
      .then((response) => {
        setPointsR(response?.data?.PointLivraisonListe?.Result?.listePointRetraitAcheminement);
        setIsLoadingRelais(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingRelais(false);
        setIsErrorVisible(true);
        setTxt(err?.PointLivraisonListe?.Erreur ? err?.PointLivraisonListe?.Erreur : "Aucun point relais n'a été trouvé pour votre recherche");
      });
  };

  useEffect(() => {
    if (selectedOption === "relais") {
   
    if (searchData) {
      newSearch(searchData);
    } else {  
      newSearchByContact();
    }
    setModeLivr("Livraison en point relais "+ relais?.Nom);
  }
  }, [searchData,selectedDate, mode]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "livraison" || e.target.value === "relais") { 
      dispatch({
        type: "SET_MAGASIN",
        payload: {
            ID: 2,
            Nom: "WEB"
        }
    });
    setIdMag(mag?.ID || 2);
    setNomMag(mag?.Nom || "WEB");
  }

  if (e.target.value === "livraison") {
    dispatch({
      type: "DELETE_RELAIS",
    });
    setMode("livraison");
    setModeLivr("Livraison à domicile");
    setTxt("Votre choix a bien été pris en compte");
    setIsErrorVisible(true);
  };

  if (e.target.value === "retrait") {
    dispatch({
      type: "DELETE_RELAIS",
    });
  };
  if (e.target.value === "relais") {
    setMode("relais");
    setModeLivr("Livraison en point relais "+ (relais?.Nom || ""));
    setIsLoadingRelais(true);
    newSearchByContact();
  }
};

  useEffect(() => {
    setIdMag(mag?.ID || 2);
    setNomMag(mag?.Nom || "WEB");
    verifStock();
  }, [idMag, nomMag, mag, mode]);

  useEffect(() => {
    if (idMag !== 2) {
      setModeLivr("Retrait en magasin " + nomMag);
      verifStock();
    } else if (mode === "livraison" && idMag === 2) {
      setModeLivr("Livraison à domicile");
    } else if (mode === "relais") {
      setModeLivr("Livraison en point relais "+ (relais?.Nom || ""));
    }
  }, [idMag, nomMag, mag, mode]);
 
  
  const verifStock = () => {
    setIsLoading(true);
    let results = [];
    basket && basket.items.filter(item => item.mode === "article").forEach(item => {
      axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: idMag })
        .then(response => {
          // console.log("response", response.data);
          // Handle the response for each item
          const stockInfo = { id: item.ID, stock: response.data?.EnStock };
          results.push(stockInfo);
          setStock([...results]);
          setIsLoading(false);
        })
        .catch(error => {
          // Handle errors for each item
          console.error('Error verifying stock:', error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const inStock = stock.every(item => item.stock > 0);

  useEffect(() => {
    setBlockSteps(!inStock);
    onNotInStockChange(inStock);
  }, [inStock, onNotInStockChange]);

  const handleButtonClick = () => {
    console.log("mode",mode);
    console.log("idMag",idMag);
    console.log("idRel",relais?.ID);
  };

    return (
        <Wrap className={className}>
          {isLoading && <SpinnerInlineST/>}
          {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Livraison"} onClick={() => setIsErrorVisible(false)}/>}
          <h2 className="center homemadeTitle initial green">Votre mode de livraison</h2>
          <Title>Livraison choisi : <Mode>{modeLivr}</Mode></Title>
          {isLoading ? <Message><SpinnerInlineST/></Message> : <Message>
            {inStock ? (
              <p className='orange bold center'><AiOutlineCheckCircle className="green"/>Bonne nouvelle ! Tous les articles de votre panier sont en stock :)</p>
             ) : (
              <p className='orange bold center'><AiOutlineCloseCircle className="red"/>Malheureusement, certains articles de votre panier ne sont pas en stock. Veuillez vérifier les quantités ou les retirer de votre panier.<br></br>
              <LienNoirST lib="Voir mon panier" href="/panier" />
              </p>
             )}
          </Message>  }
                    <Link onClick={() => (setShowMode(!showMode))}>Changer de mode de livraison</Link>
                    {showMode && <><Checks>
                        <FormGroup check>
                        <InputStyled
                            name="retrait"
                            type="radio"
                            value="retrait"
                            checked={selectedOption === 'retrait'}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                            Retrait en magasin
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                        <InputStyled
                            name="livraison"
                            type="radio"
                            value="livraison"
                            checked={selectedOption === 'livraison'}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                        Livraison à domicile
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                        <InputStyled
                            name="relais"
                            type="radio"
                            value="relais"
                            checked={selectedOption === 'relais'}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                        Livraison en point relais
                        </Label>
                        </FormGroup>
                    </Checks>
                    {selectedOption === 'retrait' && <FormLocalisationST/>}
                    {selectedOption === 'relais' && <PointsRelaisListeST pointsR={pointsR} onPointSelect={handleSelectedPoint} isLoading={isLoadingRelais} onSearch={handleSearch} onDateChange={handleDateChange}/>}
                    {/* {searchData && <div>Search Data: {JSON.stringify(searchData)}</div>} */}
                    </>
                    }

                  {/* <BoutonST lib={"OK"} 
                  onClick={handleButtonClick}  
                  background={props => props.theme.greenST}
                  width={"300px"}/> */}
        </Wrap>
  );
}
