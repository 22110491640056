import React, {useState,useEffect} from 'react';
import { navigate } from '@reach/router';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import styled from "styled-components";
import { Card } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { InputStyled, FormGroupStyled } from "../DesignSystem/SelfTissus/Forms";
import { FaTrashAlt } from "react-icons/fa";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import {Badge} from 'antd';
import PopErrorST from "../PopupsST/PopError";
import BoutonBlancBtnST from '../DesignSystem/SelfTissus/BoutonBlancBtn';

const BadgeStyled = styled(Badge)`
  .ant-badge-status-dot {
    width: 13px !important;
    height: 13px !important;
    margin-right: 5px;
  }
  `;

const Stock = styled.div`
position: absolute;
right: 0px;
top: 0px;
`;

const CardGridStyled = styled(Card.Grid)`
position: relative; 
&.nom {
    width: 60%;
    &.mob {
       width: 100%; 
       padding: 15px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}

&.quantite{
    width: 25%;
    &.mob {
       width: 100%; 
       padding: 15px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.prix{
    width: 15%;
    &.mob {
       width: 100%; 
       padding: 15px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
`;

const PanierWrap = styled.div`
width: 100%;
&>.ant-card {
   background: #ffffff75;
   padding: 20px;
}
&>.ant-card > div.ant-card-body > .ant-card-grid {
    display: flex;
    align-items: center;
}
&.mob {
    &>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    display: none;
}
}
&>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    @media only screen and (max-width: 786px) {
        display: none;
    }
}
`;

const Produit = styled.div`
position: relative;
display: flex;
align-items: center;
gap: 30px;
`

const Img = styled.img`
width: 100px;
height: 100px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`
const Txt = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
flex-wrap: wrap;
&>span {
    font-size: 20px; 
    color: ${props => props.theme.grey02ST};
}
&.mob {
    &>span {
        padding: 0;
    }
    &>a {
        margin: 0;
    }
}
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
&.mob {
    text-align: center;
}
`

const Quantity = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: space-between;
&>.trash {
    font-size: 20px;
    color: ${props => props.theme.grey02ST};
    &:hover {
        color: ${props => props.theme.redST};
        cursor: pointer;
    }
}
` 

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`
const Message2 = styled.span`
position: absolute;
bottom: 10px;
color: ${(props) => props.theme.orangeST};
font-size: 14px; 
.mob {
    bottom: -3px;
}
@media only screen and (max-width: 786px) {
    font-size: 12px;
    bottom: 3px;
}
`;

const NoProducts = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
`


export default function PanierST({className}) {
const basket = useSelector(state => state.basket);
const extractRelevantProperties = (basket) => {
    if (!basket?.items) {
      return [];
    }
    return basket.items.map(item => ({
      IDArticle: item.IDArticle,
      Prix: item.Prix,
      PrixVDontEchoTaxe: item.PrixVDontEchoTaxe,
      PromoWeb: item.PromoWeb,
      PromoMois: item.PromoMois,
      quantity: item.quantity
    }));
  };
const relevantBasketData = extractRelevantProperties(basket);
const user = useSelector(state => state.user?.user?.Contact); 
const [quantities, setQuantities] = useState(basket?.items?.map(item => item.quantity));
const mag = useSelector(state => state.magasin);
const [data, setData] = useState();
const [stockData, setStockData] = useState({});
const idMagasinLS = mag?.magasin?.ID;
const [isLoading, setIsLoading] = useState(false);
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [txt, setTxt] = useState("");
const [showStockMessage, setShowStockMessage] = useState(false);

useEffect(() => {
    const fetchStockData = async () => {
      setIsLoading(true);
      try {
        const filteredItems = basket.items?.filter(item => item.mode === "article");
        const stockPromises = filteredItems?.map(item =>
          axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: idMagasinLS })
            .then(response => ({ id: item.ID, stock: response.data?.EnStock }))
        );
        const stockResults = await Promise.all(stockPromises);
        const stockMap = stockResults.reduce((acc, { id, stock }) => {
          acc[id] = stock;
          return acc;
        }, {});
        setStockData(stockMap);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchStockData();
  }, [basket, idMagasinLS]);

  const getOutOfStockItems = () => {
    return basket.items
      .filter((item, index) => 
        item.mode === "article" && 
        (stockData[item.ID] === 0 || stockData[item.ID] < quantities[index])
      )
      .map(item => item.libArticle);
  };
  const outOfStockItems = getOutOfStockItems();
  console.log("outOfStockItems", outOfStockItems);
    const dispatch = useDispatch();
    useEffect(() => {
    setQuantities(basket?.items?.map(item => item.quantity));
  }, [basket]);

  const handleQuantityChange = (e, itemId,dataStock) => {
    const newQuantity = e.target.value;
    if (newQuantity > dataStock) {
        setShowStockMessage(true);
        }
    if (!isNaN(newQuantity)) {
      setQuantities(prevQuantities => 
        prevQuantities.map((quantity, index) => 
          basket.items[index].ID === itemId ? newQuantity : quantity
        )
      );
     setShowStockMessage(false);
      dispatch({
        type: "UPDATE_BASKET",
        payload: {
          id: itemId,
          quantity: newQuantity
        }
      });
    }
  };
  const totalEstimatedPrice = basket?.items?.reduce((total, item, index) => {
    const price = item.Prix ?? item?.PUTTC;
        return total + (quantities[index] * price);
    }, 0).toFixed(2);


    const handleDelete = (id) => {
        dispatch({ type: 'DELETE_ARTICLE', payload: id });
    };

    const blockCommande = () => {
        setTxt("Certains produits de votre panier ne sont plus disponibles ou le stock n'est pas suffisant. Veuillez les retirer ou modifier pour continuer.")
        setIsErrorVisible(true);
    }

    const handleContinueShopping = () => {
        navigate(-1);
    };

    const sauvePanier = () => {
        const dataToSend = JSON.stringify(relevantBasketData);
        console.log("dataToSend", dataToSend);
        console.log("Panier saved");
      };
    
      const handleClick = () => {
        sauvePanier();
        window.location.href = "/commande";
      };

console.log("panier",basket)
    return (
        <PanierWrap className={className}>
            {isErrorVisible && <PopErrorST  classNameBut={"white"} message={txt} titre={"Votre Panier"} onClick={() => setIsErrorVisible(false)}/>}
            {basket?.items?.length>0 ? (
                <>
                <h2 className="center homemadeTitle initial green">Votre panier</h2>
                    {outOfStockItems.length > 0 ? (
                    <Message>
                        <ul className='white bold center'>Attention! Certains produits qui se trouvent dans votre panier ne sont plus disponibles ou le stock n'est pas suffisant :</ul>
                        
                        {outOfStockItems.map((item, index) => (
                            <li className='white center' key={index}>{item}</li>
                        ))}
                        
                    </Message>
                    ) : 
                    null}

                <Card bordered={false} style={{ padding: className === "mob" ? 0 : 20 }}>
                    <Card.Grid className="pc" hoverable={false} style={{width:"60%"}}>
                    <p className="mb0 uppercase">Produit</p>
                    </Card.Grid>

                    <Card.Grid className="pc"  hoverable={false} style={{width:"25%"}}>
                    <p className="mb0 uppercase">Quantité</p>
                    </Card.Grid>

                    <Card.Grid className="pc" hoverable={false} style={{width:"15%",textAlign:"right"}}>
                    <p className="mb0 uppercase right">Total</p>
                    </Card.Grid>

                    {basket.items.map((item, index) => {
                    return (
                        <>
                            {item?.mode === "article" ? (
                                <>
                                    <CardGridStyled className={`nom ${className}`}>
                                        {stockData[item.ID] > 0 ? (
                                            stockData[item.ID] < quantities[index] ? (
                                                <Stock title="Stock limité"><BadgeStyled status="warning" /></Stock>
                                            ) : (
                                                <Stock title="En stock"><BadgeStyled status="success" /></Stock>
                                            )
                                        ) : (
                                            <Stock title="En rupture"><BadgeStyled status="error" /></Stock>
                                        )}
                                        <Produit>
                                            <Img src={"https://selftissus.yogan.pro/" + item?.Photos[0]?.image?.url} alt={item?.Photos[0]?.image?.alt} />
                                            <Txt className={className}>
                                                <LienNoirST lib={item?.libArticle} href={item?.slug} />
                                                <span>€{item?.Prix}</span>
                                            </Txt>
                                        </Produit>
                                    </CardGridStyled>
                
                                    <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                        <Quantity>
                                            <FormGroupStyled>
                                                {(stockData[item.ID] !== 0 && quantities[index]>stockData[item.ID]) ? (<Message2 className={className}>Maximum : {stockData[item.ID]}</Message2>) : null}
                                                <InputStyled
                                                    id={`quantity-${index}`}
                                                    name="quantity"
                                                    placeholder="0"
                                                    type="number"
                                                    min={data?.Unite === 1 ? "1" : item?.Unite === 2 ? "0.5" : "1"}
                                                    step={data?.Unite === 1 ? "1" : item?.Unite === 2 ? "0.1" : "1"}
                                                    value={quantities[index]}
                                                    onChange={(e) => handleQuantityChange(e, item.ID,stockData[item.ID])}
                                                />
                                            </FormGroupStyled>
                                            <FaTrashAlt className="trash" onClick={() => handleDelete(item.ID)} />
                                        </Quantity>
                                    </CardGridStyled>
                
                                    <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                        <Prix className={["fs20", className]}>€{(quantities[index] * item?.Prix).toFixed(2)}</Prix>
                                    </CardGridStyled>
                                </>
                            ) : item?.mode === "atelier" ? (
                                <>
                                <CardGridStyled className={`nom ${className}`}>
                                    <Produit>
                                        <Img src={"https://selftissus.yogan.pro/" + item?.img?.url} alt={item?.img?.alt} />
                                        <Txt className={className}>
                                            <LienNoirST lib={item?.lib} href={item?.slug} />
                                            <span>€{item?.PUTTC}</span>
                                        </Txt>
                                    </Produit>
                                </CardGridStyled>
            
                                <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                    <Quantity>
                                        <FormGroupStyled>
                                            <InputStyled
                                                id={`quantity-${index}`}
                                                name="quantity"
                                                placeholder="0"
                                                type="number"
                                                min="0"
                                                value={quantities[index]}
                                                onChange={(e) => handleQuantityChange(e, item.ID)}
                                            />
                                        </FormGroupStyled>
                                        <FaTrashAlt className="trash" onClick={() => handleDelete(item.ID)} />
                                    </Quantity>
                                </CardGridStyled>
            
                                <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                    <Prix className={["fs20", className]}>€{(quantities[index] * item?.PUTTC).toFixed(2)}</Prix>
                                </CardGridStyled>
                            </>
                            ) : null}
                        </>
                    );
                })}

                <Total className={className}>
                    <Prix className="fs16">Total estimé : &nbsp;<span> €{totalEstimatedPrice} EUR </span></Prix>
                </Total>
                {outOfStockItems.length > 0 ? (<BoutonNoirBtnST lib={"Valider votre commande"} onClick={blockCommande}/>) :
                (<BoutonNoirBtnST lib={"Valider votre commande"} onClick={handleClick}/>)}
            </Card>
            </>) : ( 
            <NoProducts>
                <h2 className="center homemadeTitle initial green">Votre panier est vide</h2>
                <BoutonNoirBtnST lib={"Continuer les achats"} onClick={handleContinueShopping}/>
                {!user && 
                <><h3>Vous possédez un compte ?</h3>
                <p><LienNoirST lib={"Connectez-vous"} href={"/membre"}/> pour payer plus vite.</p></>}
            </NoProducts>
            )}
        </PanierWrap>
  );
}
