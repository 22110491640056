const theme = {
  light: {
    greenST :"#79b69f",
    redST :"#DF5A4E",
    grey01ST : "#ced1d9",
    grey02ST : "#A29995",
    grey03ST : "#4F524E",
    grey04ST : "#646863",
    grey05ST : "#D0CEC9",
    grey06ST : "#121212bf",
    grey07ST : "#12121214",
    grey08ST : "#F3F3F3",
    grey09ST : "#3336",
    beige01ST : "#FCF9F4",
    beige02ST : "#fdf8f2",
    rose01ST : "#F5E1DC",
    rose02ST : "#ebd8d4",
    rose03ST : "#f6e6e2",
    blackST : "#121212",
    jaune01ST : "#ffce00",
    blueST : "#036B87",
    orangeST : "#f05b32",
  },
//   dark: {
//     background: '#5d4561',
//     textPrimary: '#fff',
//     textSecondary: '#37364d',
//     buttonPrimary: '#fff',
//     buttonGhost: 'transparent',
//   },
 }

export default theme
