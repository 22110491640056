import React, {useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import {
    Nav,
    NavItem,
    NavLink,
  } from "reactstrap";
import { MdStorefront } from "react-icons/md";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";

const Wrap = styled.div`
width: 100%;
display: flex; 
flex-direction: row; 
align-items: flex-start; 
justify-content: space-around; 
flex-shrink: 0; 
width: 100%; 
padding: 35px;
position: relative; 
overflow: hidden;
flex-wrap: wrap;
`;

const StyledNavLink = styled(NavLink)`
color: ${props => props.theme.grey02ST};   
text-align: left;
padding-left: 0;
font-size: 18px;
font-weight: 600;
line-height: 25px;
position: relative;
&:hover {
    color: ${props => props.theme.redST};
}
`;

const NavItemStyled = styled(NavItem)`
flex-grow: 0 !important;
flex-basis: 160px !important;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
&>svg {
    font-size: 25px;
    color: ${props => props.theme.orangeST};
}
`;


export default function MagasinsListeST({}) {
    const [magasins, setMagasins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
        .then((response) => {
          setMagasins(response?.data?.Magasin);
            setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
      }, []);
  return (
      <Wrap className="ignoreParent">
        <h2>Nos Magasins</h2>
        {isLoading && <SpinnerInlineST/>}
            <Nav horizontal style={{justifyContent:"center"}}>
                {
                    magasins.map((magasin, index) => {
                        return (
                            <NavItemStyled key={index}>
                                <MdStorefront/><StyledNavLink
                                active
                                href={magasin?.slug}
                                >
                                {magasin?.Nom}
                                </StyledNavLink>
                            </NavItemStyled>
                        )
                    })
                }
            </Nav>
      </Wrap>
  );
}
