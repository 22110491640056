import React, { useState} from 'react';
import styled, {keyframes} from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import { 
  TabContent, TabPane, 
  Row, Col , Nav, NavItem, NavLink,
} from 'reactstrap'; 
import FormSearchFaqSelfST from '../FormsST/FormSearchFaqSelf';

const Wrap = styled.div`
display: flex;
width: 100%;
gap: 50px;
@media screen and (max-width: 768px) {
  flex-direction: column;
  gap: 20px;
}
`

const NavStyled = styled(Nav)`
width: 20%;
display: flex;
flex-direction: column;
@media screen and (max-width: 768px) {
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
`
const TabContentStyled = styled(TabContent)`
width: 80%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`

const WrapAccordeon = styled.div`
width: 100%;
margin: 0 auto;
padding: 0 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:15px;
&::after {
    content: "";
    width: 100%;
    background-color: ${props => props.theme.grey02ST};
    height: 1px;
}
@media screen and (max-width: 600px) {
    padding: 30px 20px;
}
`
const Btn = styled.button`
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 0 10px 0;
border: none;
background-color: transparent;
margin: 0 auto;
width: 100%;
color: ${props => props.theme.blackST};
font-size: 20px;
text-align: left;
cursor: pointer;

&>svg{color:${props => props.theme.grey02ST};
min-width: 25px;
height: 30px;
margin-right: 10px;
}

&:hover {
  color: ${props => props.theme.greenST};
    &>svg{
        color: ${props => props.theme.greenST};
    }
}


@media screen and (max-width: 768px) {
font-size: 17px;
}

&.open{
&>svg.plus{display:none;}
&>svg.minus{display:inline-block}
}
&.closed{
&>svg.plus{display:inline-block;}
&>svg.minus{display:none}
}
`
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeOpen = styled.div`
width: 100%;
animation: ${fadeIn} 0.6s;
border-radius: 10px;
font-size: 20px;
text-align: left;
margin: auto;
background-color: #f05b3217;
`;

const NavLinkStyled = styled(NavLink)`
font-size: 20px;
border: none !important;
color: ${props => props.theme.orangeST} !important;
background-color: transparent !important;
&.active {
color: ${props => props.theme.greenST} !important;
border-bottom: 2px solid ${props => props.theme.greenST} !important;
}
&:hover>div {
text-decoration: none;
color: ${props => props.theme.greenST};
}
`;

const NavItemStyled = styled(NavItem)`
@media only screen and (max-width: 768px) {

}

`;

const TabTitle = styled.div`
  font-size: 18px;
  `


export default function FaqST({}) {
  const [searchTerm, setSearchTerm] = useState('');
  const data = [
    {
      Questions: "Les références produits inscrites sur le site sont elles les même qu'en magasin ?",
      Réponses: "<p>Vous avez repéré une référence et vous souhaitez savoir si cette&nbsp;<strong>référence</strong>&nbsp;est similaire que celle en magasin ? Voici toutes les informations à ce sujet !</p><p>Les références mentionnées sur les pages produits du site internet sont&nbsp;<strong>identiques</strong>&nbsp;à celles inscrites sur les rouleaux en magasin. Il est donc possible de retrouver votre tissu en magasin à l'aide de la référence mentionnée sur le site internet.</p><p>Vous pourrez connaitre la référence d'un produit sur la page produit. La&nbsp;<strong>référence</strong>&nbsp;est située sous la photo du produit et se compose de&nbsp;<strong>3, 4, 5 ou 6 chiffres.</strong></p>"
    },
    {
      Questions: "Comment connaitre la largeur du tissu ? ",
      Réponses: "<p>La<strong> largeur du tissu</strong> ne vous convient pas ? Retrouvez ici toutes les informations relatives à la<strong> laize des tissus.</strong>&nbsp;</p><p>Pour connaitre toutes les<strong> informations relatives à un produit</strong>, rendez-vous sur la page du produit souhaité.&nbsp;&nbsp;</p><p>La<strong> largeur du tissu</strong> correspond à la notion de <strong>laize</strong> indiquée dans la fiche produit.&nbsp;</p>"
    },
    {
      Questions: "Est-il possible de changer la laize d'un tissu ? ",
      Réponses: "<p>La <strong>laize d'un tissu</strong> est-elle <strong>modifiable</strong> ? Est-il possible d'avoir un <strong>tissu plus large</strong> ? Voici toutes les réponses à vos questions.&nbsp;</p>"
    },
    {
      Questions: "Quel est le métrage minimum pour un achat ?",
      Réponses: "<p>Chez Self Tissus nous appliquons un&nbsp;<strong>métrage minimum</strong>&nbsp;pour vos achats. En&nbsp;<strong>magasin</strong>&nbsp;ou en&nbsp;<strong>ligne</strong>, ces métrages sont différents, découvrez-les !</p><p>Sur le site internet Selftissus.fr, la dimension minimale autorisée est de&nbsp;<strong>30 cm.&nbsp;</strong></p><p>En magasin, cette dimension varie entre&nbsp;<strong>30 et 50 cm</strong>&nbsp;selon le magasin. Pour connaitre les dimensions appliquées dans celui de votre choix, veuillez vous renseigner auprès des vendeurs en magasin.</p>"
    },
  ];
const [isOpened, setIsOpened] = useState(false);
const [activeTab, setActiveTab] = useState(0);
const toggle = tab => {
  if(activeTab !== tab) setActiveTab(tab);
}

const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
};
const filteredData = data.filter(item =>
  item.Questions.toLowerCase().includes(searchTerm.toLowerCase()) ||
  item.Réponses.toLowerCase().includes(searchTerm.toLowerCase())
);

const highlightText = (text, highlight) => {
  if (!highlight) return text;
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      `<span key=${index} style="background-color: #ffce00;border-radius:5px">${part}</span>`
    ) : (
      part
    )
  ).join('');
};

const Content = ({content}) => {
  return (
    filteredData.map((item, index) => (
      item.Questions !== "" ? 
      <WrapAccordeon key={index}>
        <Btn
          onClick={() => {
            setIsOpened(prevIsOpened => index === prevIsOpened ? null : index);
          }}
          aria-expanded={isOpened === index ? "true" : "false"}
          className={isOpened === index ? 'open' : 'closed'}
        >
          <div>{ReactHtmlParser(highlightText(item.Questions, searchTerm))}</div>
          <FaChevronUp className="minus"/>
          <FaChevronDown className="plus"/>
        </Btn>
        {isOpened === index ? (
        <FadeOpen style={{ padding: item.Réponses ? '25px' : '0' }}>
          <div>
            {ReactHtmlParser(highlightText(item.Réponses, searchTerm))}
          </div>
        </FadeOpen>
      ) : null}
      </WrapAccordeon> : null
    ))
  )
}

const tabData = [
  { label: <TabTitle>Produits</TabTitle>, content: <Content/>,},
  { label: <TabTitle>Ma commande</TabTitle>, content: <p>.....</p>},
  { label: <TabTitle>Mon compte</TabTitle>, content: <p></p>},
  { label: <TabTitle>Livraison</TabTitle>, content: <p></p>},
  { label: <TabTitle>Paiement</TabTitle>, content: <p></p>},
  { label: <TabTitle>Retours</TabTitle>, content: <p></p>},
  { label: <TabTitle>Professionnels</TabTitle>, content: <p></p>},
  { label: <TabTitle>Carte Cadeau</TabTitle>, content: <p></p>},
  { label: <TabTitle>Labels & Certifications</TabTitle>, content: <p></p>},
  { label: <TabTitle>Click & Collect</TabTitle>, content: <p></p>},
  { label: <TabTitle>Magasins</TabTitle>, content: <p></p>},
  { label: <TabTitle>Ateliers</TabTitle>, content: <p></p>},
  { label: <TabTitle>Projets couture</TabTitle>, content: <p></p>},
  { label: <TabTitle>Contactez-nous</TabTitle>, content: <p></p>},
  { label: <TabTitle>Programme Fidélité</TabTitle>, content: <p></p>},
];
return (
            <>
            <FormSearchFaqSelfST className={"max"} 
            placeholder={"Tapez le mot clé pour trouver la réponse"}  
            value={searchTerm}
            onChange={handleSearchChange}/>
            <Wrap>
            <NavStyled tabs>
              {tabData.map((tab, index) => (
                <NavItemStyled className={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </NavStyled>
            <TabContentStyled activeTab={activeTab}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContentStyled>
            </Wrap>
              </>
            );
}

