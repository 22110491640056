import React from "react";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonBlueST from "../DesignSystem/SelfTissus/BoutonBlue";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
border-radius: 10px;
&>input {
  border-radius: 10px;
}
&>a {
position: absolute;
right: 0;
top: 0;
overflow: visible;
width: 200px;
height: 100%;
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 390px) {
  flex-direction: column;
  gap: 5px;
  &>input {
    width: 100%;
  }
  &>a {
    position: relative;
    width: 100%;
    border-radius: 10px;
  }
}
`;

export default function FormRecommandST({closeR}) {

  const subscribe = () => {
    localStorage.setItem('subscribedRecommand', true);
  }

    return (
          <Wrap>
            <Txt> 
              <h3 className="center" style={{margin:"0.5rem auto"}}>Recommandez et obtenez 10% de réduction</h3>
              <p className="center fs17">Invitez vos amis pour obtenir plus de réductions. Pour chaque recommandation réussie, vous obtiendrez 10% de réduction</p>
              </Txt>
             <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="email"
                        placeholder="Votre adresse e-mail"
                        type="email"
                    />
                     <Label for="exampleEmail">Votre adresse e-mail</Label>
                    <BoutonBlueST lib={" Obtenir le lien"} onClick={() => {
                          subscribe();
                          closeR(); 
                        }}/>
                </FormStyled>
          </Wrap>
  );
}
