import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { FaLocationCrosshairs } from "react-icons/fa6";

const Btn = styled.button`
text-decoration: none;
border: 2px solid ${props => props.theme.greenST};
background: #fff;
display: inline-block; 
text-align: center;
align-items: center; 
justify-content: center;
align-items: center;
padding: 3px;
width: auto;
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;
color: #fff; 
font-size: 17px;
font-weight: 600;
text-transform: uppercase;
transition: all .2s ease-out;
&> span {
  display: block;
  padding: 9px; 
  border: 2px dashed ${props => props.theme.greenST};
  &>svg {
    transition:   fill 0.3s ease;
    fill: ${(props) => props.theme.greenST};
    height: 25px;
    width: 25px;
  }
}
&:hover {
  background: ${props => props.theme.greenST};
  &> span {
  border-color: #fff;
  &>svg {
    fill: #fff;
  }
}
}

`;


export default function BoutonLocST(props) {


    return <Btn className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} onClick={props.onClick}><span><FaLocationCrosshairs/>{props.lib}</span></Btn>   


}