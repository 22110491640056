import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import FormOubliPassST from "./FormOubliPass";
import {PopUpContainer} from "../DesignSystem/PopUpST";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 0;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const Buts = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
`


export default function FormAdresseST({onClose}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user?.Contact);
  const [form, setForm] = useState({
    Nom: "",
    Prenom: "",
    Adresse: "",
    CP: "",
    Ville: "",
    Pays: "",
    Tel: ""
  });

const sendNewInfos = () => {
console.log("sendNewInfos");
}


    return (
        <Wrap>
          <h4 className="center homemadeTitle initial green m0">Modifier mes informations</h4>
           {isLoading && <SpinnerST />}
           {isErrorVisible && <PopErrorST message={txt} titre={"Connexion"} onClick={() => setIsErrorVisible(false)}/>}
            <Txt> 
              </Txt>
             <FormStyled floating>
                <InputStyled
                    id="nom"
                    name="nom"
                    placeholder="Nom"
                    type="text"
                    onChange={(e) => setForm({...form, Nom: e.target.value})}
                />
                  <Label for="email">Nom</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="prenom"
                  name="prenom"
                  placeholder="Prénom"
                  type="text"
                  onChange={(e) => setForm({...form, Prenom: e.target.value})}
              />
                <Label for="prenom">Prénom</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="adresse"
                  name="adresse"
                  placeholder="Adresse"
                  type="text"
                  onChange={(e) => setForm({...form, Adresse: e.target.value})}
              />
                <Label for="adresse">Adresse</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="cp"
                  name="cp"
                  placeholder="Code postal"
                  type="text"
                  onChange={(e) => setForm({...form, CP: e.target.value})}
              />
                <Label for="adresse">Code postal</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="ville"
                  name="ville"
                  placeholder="Ville"
                  type="text"
                  onChange={(e) => setForm({...form, Ville: e.target.value})}
              />
                <Label for="ville">Ville</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="pays"
                  name="pays"
                  placeholder="Pays"
                  type="text"
                  onChange={(e) => setForm({...form, Pays: e.target.value})}
              />
                <Label for="pays">Pays</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                  id="tel"
                  name="tel"
                  placeholder="Téléphone"
                  type="text"
                  onChange={(e) => setForm({...form, Tel: e.target.value})}
              />
                <Label for="tel">Téléphone</Label>
              </FormStyled>

              <Buts>
                <BoutonNoirBtnST lib={"Enregistrer"} onClick={sendNewInfos} />
                <BoutonBlancBtnST lib={"Annuler"} onClick={onClose}/>
              </Buts>
          </Wrap>
  );
}
