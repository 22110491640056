// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    user: null,
};

// Create the reducer function
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
          return {
            ...state,
            user: action.payload,
          };
        case "UPDATE_USER":
          return {
            ...state,
            user: {
              ...state.user,
              ...action.payload,
            },
          };
        case "DELETE_USER":
          return {
            ...state,
            user: null,
          };
        default:
            return state;
    }
};

export default userReducer;