import React, { lazy, Suspense } from "react";
import PageLoading from "../components/DesignSystem/PageLoading.jsx";
import { Container } from "../components/DesignSystem/SelfTissus/ContainersST.jsx";
import CheckoutST from "../components/Panier/Checkout.jsx";

const Footer1ST = lazy(() => import("../components/FooterST/Footer1_ST"));
const Footer2ST = lazy(() => import("../components/FooterST/Footer2_ST"));
const Footer3ST = lazy(() => import("../components/FooterST/Footer3_ST"));
const Footer4ST = lazy(() => import("../components/FooterST/Footer4_ST"));

export default function CommandeST() {
  return (
  
    <Suspense fallback={<PageLoading/>}>
    <Container>
      <CheckoutST/>
    </Container>
    <Footer1ST />
    <Footer2ST />
    <Footer3ST />
    <Footer4ST />
    </Suspense>
    
  );
}

