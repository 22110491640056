import React from "react";
import styled from "styled-components";
import  {FormGroup ,Label, Input} from "reactstrap";
import { InputStyled,InputCheckStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import {Socials, LinkStyledSocial} from "../DesignSystem/SelfTissus/ElementsST";
import { FaFacebook } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import img from "../../assets/images/img.jpg";
import TextArea from "antd/es/input/TextArea";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
z-index: 1;
align-items: center; 
justify-content: center;
width: 100%;

  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Bck = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: -1;
background-image: url(${img});
background-size: cover;
background-position: center;
opacity: 0.2;
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;

const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
align-items: center;
gap: 10px;
width: 100%;
max-width: 600px;
`
export default function FormNewsletterST({close}) {

  const subscribe = () => {
    console.log("subscribed")
    localStorage.setItem('subscribedNL', true);
  }
    return (
        <Wrap>
          <Bck></Bck>
            <Txt> 
              <h3 className="center">Rejoignez notre newsletter</h3>
              <p className="center fs17">Rejoignez notre liste de diffusion pour être informé des tendances, des nouveautés et de notre actualité.</p>
              </Txt>
             <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="email"
                        placeholder="Votre e-mail"
                        type="email"
                    />
                     <Label for="exampleEmail">Votre e-mail</Label>
                </FormStyled>
             <FormStyled>
                    <InputStyled
                        id="cond"
                        name="email"
                        type="checkbox"
                    />
                     <Label for="cond" style={{textAlign:"left"}}>
                     J'accepte de m'inscrire à cette newsletter et de recevoir des communications.</Label>
                </FormStyled>
                <BoutonST lib={"Inscription"} 
                    background={props => props.theme.orangeST}
                    width={"100%"}
                    onClick={() => {
                      subscribe();
                      close(); 
                    }}
                    />
                <p className="center fs17">En vous inscrivant à la newsletter vous acceptez de recevoir des mails de Self Tissus sur notre actualité, nos offres en cours et tout ce que nous partageons avec notre communauté de passionné·e·s. Nous ne partageons pas vos données personnelles à des tiers. Vous pouvez à tout moment vous désinscrire au bas de chaque email envoyé.</p>
                <Socials>
                  <LinkStyledSocial href="https://www.facebook.com/SelfTissus"><FaFacebook/></LinkStyledSocial>
                  <LinkStyledSocial href="https://www.instagram.com/self_tissus/"><FaInstagram/></LinkStyledSocial>
                  <LinkStyledSocial href=""><FaTiktok/></LinkStyledSocial>
                  <LinkStyledSocial href="https://www.youtube.com/@selftissusfrance"><FaYoutube/></LinkStyledSocial>
                  <LinkStyledSocial href="https://fr.linkedin.com/company/self-tissus"><FaLinkedinIn/></LinkStyledSocial>
                  <LinkStyledSocial href="https://www.pinterest.fr/selftissusfr/"><FaPinterest/></LinkStyledSocial>
                </Socials>
          </Wrap>
  );
}
