import React from "react";
import styled from 'styled-components';

const Btn = styled.a`
text-decoration: none;
background: transparent;
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
padding: 10px;
border: 2px solid #000;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0 auto"};
position: relative;
cursor: pointer;
color: #000;
font-size: 18px;
font-weight: 400;
text-align: center;
transition: all .2s ease-out;
&:hover {
    color: #fff;
    background: #000;
}
&.absolute {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
  

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonTransparentST(props) {
    return <Btn className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} href={props.href}>{props.lib}</Btn>   


}