import React, {useState,useRef} from "react";
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { IoMdPhotos } from "react-icons/io";
import { FaFacebook,FaPinterest } from "react-icons/fa";
import BoutonTransparentSmallST from "../DesignSystem/SelfTissus/BoutonTransparentSmall";
import FormRealisationST from "../FormsST/FormRealisation";
import {WrapFormPop} from "../DesignSystem/PopUpST";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import BoutonTransparentBtnST from "../DesignSystem/SelfTissus/BoutonTransparentBtn";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import ShareST from "../Share/ShareST";
const data1 = [
  {
    id: 1,
    title: "La laine : décryptage de ce hobby très tendance",
    url: "/blog/1",
    category: "Tutos",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec vulputate dolor. Praesent tellus mi, tincidunt a mattis vitae, fermentum et mauris. Cras eget tortor in nibh aliquet pellentesque. Aliquam erat volutpat. Vestibulum fermentum ipsum ipsum, at pellentesque nisi scelerisque eu. Proin augue velit, facilisis quis mauris et, dapibus blandit erat. Ut rutrum sed augue eu dictum. Suspendisse ac nibh non dui elementum commodo eu nec augue. Quisque condimentum enim nec ipsum suscipit molestie. Cras ultricies semper quam at dictum. Vestibulum ornare posuere mollis. Nulla ullamcorper commodo nisl, at tempor nibh viverra a. Donec ullamcorper est ac elit finibus, in pulvinar quam rutrum. Nulla vitae risus pharetra, aliquam metus eu, congue ex.",
    image: "https://dropinblog.net/34254688/files/featured/design-sans-titre-8-hlxvo.jpg",
    original: "https://dropinblog.net/34254688/files/featured/design-sans-titre-8-hlxvo.jpg",
    thumbnail: "https://dropinblog.net/34254688/files/featured/design-sans-titre-8-hlxvo.jpg",
    date: "2022-01-01",
    views: 100,
    autor: "Auteur 1",
    time: 5,
  },
  {
    id: 2,
    title: "Tuto sac à main : le sac Julia",
    url: "/blog/2",
    category: "Tendances du moment", 
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec vulputate dolor. Praesent tellus mi, tincidunt a mattis vitae, fermentum et mauris. Cras eget tortor in nibh aliquet pellentesque. Aliquam erat volutpat. Vestibulum fermentum ipsum ipsum, at pellentesque nisi scelerisque eu. Proin augue velit, facilisis quis mauris et, dapibus blandit erat. Ut rutrum sed augue eu dictum. Suspendisse ac nibh non dui elementum commodo eu nec augue. Quisque condimentum enim nec ipsum suscipit molestie. Cras ultricies semper quam at dictum. Vestibulum ornare posuere mollis. Nulla ullamcorper commodo nisl, at tempor nibh viverra a. Donec ullamcorper est ac elit finibus, in pulvinar quam rutrum. Nulla vitae risus pharetra, aliquam metus eu, congue ex.", 
    image: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    original: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    thumbnail: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    date: "2022-01-02",
    views: 200,
    autor: "Auteur 2",
    time: 10,
  },
  {
    id: 3,
    url: "/blog/3",
    title: "Blog 3",
    category: "Tendances du moment", 
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec vulputate dolor. Praesent tellus mi, tincidunt a mattis vitae, fermentum et mauris. Cras eget tortor in nibh aliquet pellentesque. Aliquam erat volutpat. Vestibulum fermentum ipsum ipsum, at pellentesque nisi scelerisque eu. Proin augue velit, facilisis quis mauris et, dapibus blandit erat. Ut rutrum sed augue eu dictum. Suspendisse ac nibh non dui elementum commodo eu nec augue. Quisque condimentum enim nec ipsum suscipit molestie. Cras ultricies semper quam at dictum. Vestibulum ornare posuere mollis. Nulla ullamcorper commodo nisl, at tempor nibh viverra a. Donec ullamcorper est ac elit finibus, in pulvinar quam rutrum. Nulla vitae risus pharetra, aliquam metus eu, congue ex.", 
    image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    original: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    thumbnail: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    date: "2022-01-03",
    views: 300,
    autor: "Auteur 3",
    time: 15,
  },
  {
    id: 4,
    title: "Blog 4",
    url: "/blog/4",
    category: "Catégorie 4",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec vulputate dolor. Praesent tellus mi, tincidunt a mattis vitae, fermentum et mauris. Cras eget tortor in nibh aliquet pellentesque. Aliquam erat volutpat. Vestibulum fermentum ipsum ipsum, at pellentesque nisi scelerisque eu. Proin augue velit, facilisis quis mauris et, dapibus blandit erat. Ut rutrum sed augue eu dictum. Suspendisse ac nibh non dui elementum commodo eu nec augue. Quisque condimentum enim nec ipsum suscipit molestie. Cras ultricies semper quam at dictum. Vestibulum ornare posuere mollis. Nulla ullamcorper commodo nisl, at tempor nibh viverra a. Donec ullamcorper est ac elit finibus, in pulvinar quam rutrum. Nulla vitae risus pharetra, aliquam metus eu, congue ex.",
    image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    original: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    thumbnail: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    date: "2022-01-04",
    views: 400,
    autor: "Auteur 4",
    time: 20,
  },
  {
    id: 5,
    title: "Blog 5",
    url: "/blog/5",
    category: "Catégorie 5",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec vulputate dolor. Praesent tellus mi, tincidunt a mattis vitae, fermentum et mauris. Cras eget tortor in nibh aliquet pellentesque. Aliquam erat volutpat. Vestibulum fermentum ipsum ipsum, at pellentesque nisi scelerisque eu. Proin augue velit, facilisis quis mauris et, dapibus blandit erat. Ut rutrum sed augue eu dictum. Suspendisse ac nibh non dui elementum commodo eu nec augue. Quisque condimentum enim nec ipsum suscipit molestie. Cras ultricies semper quam at dictum. Vestibulum ornare posuere mollis. Nulla ullamcorper commodo nisl, at tempor nibh viverra a. Donec ullamcorper est ac elit finibus, in pulvinar quam rutrum. Nulla vitae risus pharetra, aliquam metus eu, congue ex.",
    image: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    original: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    thumbnail: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    date: "2022-01-05",
    views: 500,
    autor: "Auteur 5",
    time: 25,
  },
]

const StyledSwiper = styled(Swiper)`
padding: 10px 0; 
  .swiper-button-next,
  .swiper-button-prev {
    cursor: pointer;
    &::after {
    }
  }
  .swiper-button-next {
    right: 40px;
  @media (max-width: 768px) {
    right: 10px;
  }
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
background: ${(props) => props.theme.grey08ST};
min-height: 380px !important;
max-height: 380px !important;
padding: 0;
`;

const ImgDiv = styled.div`
background-image: url(${(props) => props.src});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
width: 100%;
height: 100%;
`;

const Overlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: transparent;
opacity: 0;
z-index: 1;
transition: all 0.3s ease-in-out;
`;

const Bloc = styled.div`
position: relative;
display: block;
text-decoration: none;
overflow: hidden;
height: 100%;
width: 100%;
height: 380px;
max-height: 380px !important;
&>button {
  z-index: 2;
  opacity: 0;
}

&:hover {
  text-decoration: none;
  &>button {
    opacity: 1;
  }
  &> ${Overlay} {
  background-color: #ffffff5c;
  opacity: 0.8;
  }
}
`;

const Photos = styled.div`
position: relative; 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  &>img {
    width: 275px;
    height: 285px;
    object-fit: cover;
    cursor: pointer;
  }
  `
const WrapGal = styled.div`
height: 100%;
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
z-index: 9999999999;
background: rgba(0, 0, 0, 0.75);
&> .image-gallery {
  width: 81%;
  margin: 90px auto;
    &> .image-gallery-content > .image-gallery-slide-wrapper > .image-gallery-swipe > .image-gallery-slides > div >img {
      width: 65%;
      object-fit: contain !important;
    }
    &> .image-gallery-content > .image-gallery-slide-wrapper > .image-gallery-icon  {
      &.image-gallery-right-nav {
        right: -50px;
      }
      &.image-gallery-left-nav {
        left: -50px;
      }
    }
    &> .image-gallery-content > .image-gallery-slide-wrapper > .image-gallery-swipe > .image-gallery-slides  {
      white-space: wrap;
      line-height: normal;
    }
    &> .image-gallery-content > .image-gallery-slide-wrapper > .image-gallery-swipe > .image-gallery-slides > div  {
      min-height: 760px;
      max-height: 760px;
      height: 760px;
      background: rgb(0, 0, 0);
    }
  }
`

const StyledDiv = styled.div`
display: flex;
flex-direction: column;
background-color: #fff;
padding: 20px;
width: 40%;
height: 100%;
gap:20px;
overflow-y: auto;
@media only screen and (max-width: 768px) {
  width: 100%;
}
`;

const TxtGroup = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
margin: 60px 0;
height: 100%;
`

const Titre = styled.div`
color: ${(props) => props.theme.grey03ST};
padding: 20px 0;
text-align: left;
font-size: 18px;
display: flex;
align-items: center;
gap: 14px;
&.prod {
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.grey03ST};
}
`;

const Txt = styled.div`
color: ${(props) => props.theme.grey03ST};
text-align: justify;
padding-top: 20px;
font-size: 14px;
line-height: 20px;
`;

const ImageGalleryStyled = styled(ImageGallery)`
position: absolute;
width: 80%;
top: 10%;
`
const Wrapper = styled.div`
display: flex;
height: 100%;
@media only screen and (max-width: 768px) {
 flex-direction: column;
}
`

const ImageStyled = styled.img`
width: 60% !important;
object-fit: contain !important;
@media only screen and (max-width: 768px) {
  width: 100% !important;
}
`

const ImageProd = styled.img`
padding-top: 15px;
width: 150px;
height: 150px;
`

const Close = styled.div`
position: absolute;
top: 10px;
right: 10px;
cursor: pointer;
color: #fff;
font-size: 30px;
`

const Buts = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${(props) => props.theme.grey03ST};
`
const Prods = styled.div`
color: ${(props) => props.theme.grey03ST};
`
const LinkProd = styled.a`
display: flex;
flex-direction: column;
gap: 10px;
color: ${(props) => props.theme.grey03ST};
font-size: 14px;
`




export default function SliderRealisationsST({data}) {
  const [showForm, setShowForm] = useState(false);
  const [currentImage, setCurrentImage] = useState(0); 
  const [displayGal, setDisplayGal] = useState("none"); 
  const swiperRef = useRef(null);

  const handleImageClick = (item,index) => {
    setCurrentImage(index); 
    setDisplayGal("block");
  };
  const handleCloseClick = () => {
    setDisplayGal("none");
    setCurrentImage(0);
  };

  const handleChangeOpen = (value) => {
    setShowForm(value);
  } 
  
  const MyRenderItem = ({ index }) => (
  
    <>
    <Wrapper className=''>
   
   
      <ImageStyled
      onError={(e) => {
        e.target.onerror = null;
      }} 
      className="image-gallery-image" src={data1[index].original} />
      
        <StyledDiv>
       
          <Titre><IoMdPhotos/>{ data1[index] && data1[index]?.autor}</Titre>
          <Buts>
            <BoutonTransparentSmallST margin={"0"} lib={"Partager votre réalisation"} onClick={() => { setShowForm(true); setDisplayGal('none');setCurrentImage(0); }} color={(props) => props.theme.grey03ST}/>
            {/* <FaFacebook/>
            <FaPinterest/> */}
            <div style={{position:"relative"}}>
            <ShareST shareUrl="/realisations" title="Partager" className={"static"} media="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
            </div>
       
          </Buts>
          
          <Txt>{data1[index] && data1[index]?.description}</Txt>
          <Prods>
            <Titre className="prod">Tissus utilisés</Titre>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
          </Prods>
          
        </StyledDiv>
     
    </Wrapper>
    </>
  );
  const items = Array.from({ length: data1.length }, (_, index) => ({
    original: data1[index].original,
    thumbnail: data1[index].thumbnail,
    renderItem: () => <MyRenderItem index={index}/>,
  }));

  return (
    <>
    <div>
    {data?.Titre && data?.Titre !=="WebComposant" ? <h2 className="initial center">{data?.Titre}</h2> : null}
    <TxtGroup>
    {data?.Corps &&<p>{data?.Corps}</p>}
      <BoutonNoirST lib={data?.BTNLib} onClick={() => setShowForm(true)}/>
    </TxtGroup>
    {/* <Photos>
    {data && data.map((item, i) => (
          <img
          key={i}
            width={200}
            src={item.image}
            alt={`Description of the image`}
            onClick={() => handleImageClick(item,i)}
            style={{ cursor: 'pointer' }}
          />
        ))}
        
    </Photos> */}
    <StyledSwiper ref={swiperRef}
          grabCursor={true}
          loop={true}
          // autoplay={{pauseOnMouseEnter: true, delay: 1000, disableOnInteraction:false}}
          slideActiveClass="active"
          slideNextClass="next"
          slidePrevClass="prev"
          slidesPerView= {4}
          spaceBetween={10} 
          breakpoints={{
            100: {
                slidesPerView: 1,
            },
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        }}
          navigation={true}
          modules={[Navigation, Autoplay]}
          
        >
         
          {
            data1 && data1.map((item, i) => {
              return (
                <SwiperSlideStyled key={i}>
                   <Bloc>
                    {item.txt && <Txt>
                    {item.title && <h3 className="center">{item.title}</h3>}
                    <p className="center">{item.txt}</p>
                    </Txt>}
                      {/* {item.img &&<Img src={item.img} alt={item.title} />} */}
                      {item.image &&<ImgDiv src={item.image}/>}
                      <BoutonTransparentBtnST lib="Je découvre" onClick={() => handleImageClick(item,i)} className="absolute" widthMob={"auto"}/>
                      <Overlay></Overlay>
                  </Bloc>
                  
                </SwiperSlideStyled>
              );
            })
          }
          
        </StyledSwiper>
  
    
  </div>
  {displayGal ==="block" ?
   ReactDOM.createPortal(
    <WrapGal style={{ display: displayGal }}>
      <Close onClick={() => handleCloseClick()}>X</Close>
        <ImageGalleryStyled
            items={items}
            thumbnailPosition="bottom"
            showPlayButton={false}
            showFullscreenButton={false}
            startIndex={currentImage}
            originalClass = "selfTissus"
            />
      </WrapGal>,
            document.getElementById('root')
  ) : ""}
  {showForm === true ?
   ReactDOM.createPortal(
    <WrapFormPop className="form"><FormRealisationST className="npPop" onChangeOpen={handleChangeOpen}/></WrapFormPop>,
            document.getElementById('root')
  ) : ""}
    </>
  );
}
