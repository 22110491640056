import React, {useState,useEffect} from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineHeart,AiFillHeart  } from "react-icons/ai";
import { useSelector } from "react-redux";

const radioWave = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  90% {
    transform: scale(2.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

const Btn = styled.button`
text-decoration: none;
border: none;
background: #000;
display: flex; 
align-items: center; 
justify-content: center;
align-items: center;
gap: 20px;
padding: 20px;
overflow: hidden;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0 auto"};
position: relative;
cursor: pointer;
color: #fff;
font-size: 18px;
font-weight: 400;
transition: all .2s ease-out;
&>svg {
    margin-left: 10px;
    font-size: 25px;
    fill: ${(props) => props.theme.orangeST};
    transition: all .2s ease-out;
    &.animate{
    animation: ${radioWave} 1.5s infinite;
  }
}
  &:hover {
    background-color: ${(props) => props.theme.redST};
    color: #fff;
    &>svg {
        fill: #fff;
    }
    }

  
@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;




export default function BoutonFavouriteST({onClick,margin,width,widthMob,isFav, ...props}) {
  const [isFavourite, setIsFavourite] = useState(isFav);
  useEffect(() => {
    setIsFavourite(isFav);
  }, [isFav]);
    const [animate, setAnimate] = useState(false);
    const handleClick = () => {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 1500);
        setIsFavourite(!isFavourite);
        if (onClick) {
          onClick();
        }
      };
  
    return <Btn  margin={margin} width={width} 
    widthMob={widthMob} 
    onClick={handleClick}
    {...props}
    >
    {/* <AiOutlineHeart className={animate ? "animate" : ""}/> */}
    {isFavourite ? (
        <AiFillHeart className={animate ? "animate" : ""} />
      ) : (
        <AiOutlineHeart />
      )}
    {props.lib}
    </Btn>   
}