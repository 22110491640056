import React, {useEffect, useState} from 'react'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/reset.css';
import GlobalStyles from './styles/GlobalStyles'
import { PageLayout } from './styles/PageLayout'
import theme from './constants/theme'
import { useTheme } from './hooks/useTheme'
import Header from './components/Header'
import RouterAppReact from "./Router";
import RouterDynamique from './RouterDynamique';
import axios from "axios";
// @flow

const App = ({ children }) => {

  const [currentTheme, setCurrentTheme] = useState(theme.light);
  const [mode, toggleMode] = useTheme()

  const themeMode = mode === 'light' ? theme.light : theme.dark
  // useEffect(() => {
  //   const fetchTheme = async () => {
  //     try {
  //       const response = await axios.get(`/theme?{"CleCtrl":"TaTaYoYo","act":"PlanDeCouleurs"}`);
  //       const themeName = response.data.Couleurs; // adjust this line based on the API response structure
  //       setCurrentTheme(themeName);
  //       console.log("themeName", themeName);
  //     } catch (error) {
  //       console.error('Error fetching theme:', error);
  //       axios.get(`${API_URL2}{"CleCtrl":"TaTaYoYo","act":"AjoutBug","Titre":"Error de Theme","Bug":"{"CleCtrl":"TaTaYoYo","act":"PlanDeCouleurs"}"}`)

  //     }
  //   };

  //   fetchTheme();
  // }, []);
  const [dataPage, setDataPage] = useState([]);
  const [showBtn, setShowBtn] = useState(true);
  const [showBtnDoc, setShowBtnDoc] = useState(false);
  const [showBtnMenu, setShowBtnMenu] = useState(true);
  const [buttonsVolantsScrollValue, setButtonsVolantsScrollValue] = useState(400);

  const isMobile = window.innerWidth <= 1200;
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
    
      const params = new URLSearchParams({
        CleCtrl: "TaTaYoYo",
        act: "DataSlug",
        Slug: window.location.pathname
      });
      setShowBtnMenu(isMobile);
     
    }
  }, []);
  return (
    <>
      
      {/* <Typography /> */}
      <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
        <Header  showBtn={showBtn} showBtnDoc={showBtnDoc} showBtnMenu={showBtnMenu} scrollValue={buttonsVolantsScrollValue}/> 
        {/* <ScrollToTop/>
        <ScrollToBottom/> */}
        {/* mode={mode} toggleMode={toggleMode} */}
        <PageLayout>
           <RouterAppReact /> 
          <RouterDynamique />
        </PageLayout>
      </ThemeProvider>
    </>
  )
}

export default App 


