import React, { useEffect, useState, lazy, Suspense } from "react";
import styled, { keyframes } from "styled-components";
import Logo from "../assets/images/Logo.svg";
import img from "../assets/images/maintenance.jpg"

const Wrapper = styled.div`
display: flex;
flex-direction: row-reverse;
width: 100vw;
min-height: calc(100vh - 50px);
margin: 0 auto;
justify-content: flex-start;
align-items: stretch;
overflow: hidden;
&>*{
  flex-grow:1;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
}
`

const DivPhoto = styled.div`
display: block;
width:100%;
filter: brightness(0.6);
position:relative;
background-image:url(${img});
background-position:center center;
background-repeat:no-repeat;
background-size:cover;
opacity: 0.5;
@media only screen and (max-width: 768px) {
  width: 100%;
  min-width: 100%;
}
`

const DivTxt = styled.div`
position: absolute;
top: 100px;
right: 50px;
width: 80%;
padding: 20px;
text-align: left;
background-color: #79b69fbd;
border-radius: 5px;
box-shadow: 0 0 5px 0 #0000002e;
@media screen and (max-width: 768px) {
  top: 80px;
  right: 0;
  width: 100%;
}
@media screen and (max-width: 310px) {
  top: 0px;
}
`

const Img = styled.img`
box-shadow: 0 0 10px 0 #000000a3;
`


const Maintenance = () => {
  return (
    <>
   <Wrapper>
    <DivPhoto></DivPhoto>
        <DivTxt>
          <div>
            <Img
              class="logo"
              src={Logo}
              alt="Self Tissus"
              title="Self Tissus"
            />
             <h1>Maintenance en cours</h1>
            <p className="black fs18">Le site Self Tissus est en cours de mise à jour. Nous sommes désolés pour l’interruption. On travaille dur pour revenir au plus vite !  </p>
            <p className="black fs18"> Merci de votre compréhension pendant ces améliorations essentielles. On se retrouve très bientôt ! </p>
          </div>
          <div>
          <p className="white fs20">En attendant le rétablissement de ce service : </p>
            <p>vous pouvez nous contacter par téléphone au <br></br> <strong className="red">????</strong></p>
            <p>et par mail <br></br> <strong className="red">????</strong></p>
          </div>
        </DivTxt>
    </Wrapper>
    </>
  )
}

export default Maintenance
