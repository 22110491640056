import React, {useState} from "react";
import styled from 'styled-components';
import { FaRegClock } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import imgBlogDefault from "../../images/blog.webp"
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlogs } from "../DesignSystem/SelfTissus/ContainersST";

import { Card } from 'antd';
const { Meta } = Card;

const data1 = [
  {
    id: 1,
    title: "La laine : décryptage de ce hobby très tendance",
    url: "/blogFiche",
    category: "Tutos",
    image: "https://dropinblog.net/34254688/files/featured/design-sans-titre-8-hlxvo.jpg",
    date: "2022-01-01",
    views: 100,
    autor: "Auteur 1",
    time: 5,
  },
  {
    id: 2,
    title: "Tuto sac à main : le sac Julia",
    url: "/blogFiche",
    category: "Tendances du moment", 
    image: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    date: "2022-01-02",
    views: 200,
    autor: "Auteur 2",
    time: 10,
  },
  {
    id: 3,
    url: "blogFiche",
    title: "Blog 3",
    category: "Tendances du moment", 
    image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    date: "2022-01-03",
    views: 300,
    autor: "Auteur 3",
    time: 15,
  },
  {
    id: 4,
    title: "Blog 4",
    url: "/blogFiche",
    category: "Catégorie 4",
    image: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    date: "2022-01-04",
    views: 400,
    autor: "Auteur 4",
    time: 20,
  },
  {
    id: 5,
    title: "Blog 5",
    url: "/blogFiche",
    category: "Catégorie 5",
    image: "https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg",
    date: "2022-01-05",
    views: 500,
    autor: "Auteur 5",
    time: 25,
  },
]

const Infos = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 10px 0 20px 0;
  &>span {
    color :${props => props.theme.grey09ST};
    font-size: 11px;
  }
  `;

const Title = styled.h2`
margin: 0 0 20px 0;
font-size: 29px;
`


export default function BlogListeST({data}) {
  console.log("data1", data);
function FormateDate(date){
  if(date !== undefined && date !== null && date !== ""){
    const dateObj = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString('fr-FR', options);
  }
  return "";
}

  return (
      <Section className="noPadding">
        {data?.Titre && data?.Titre !=="WebComposant" ? <h2 className="center">{data?.Titre}</h2> : null}
         <WrapperBlogs>
          {
            data && data?.Data?.sort((a, b) => a.Tri - b.Tri).map((item, i) => {
              return (
                <Card
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={<img alt="image de blog Self Tissus" src={item?.image?.url ? "https://selftissus.yogan.pro/"+item?.image?.url : imgBlogDefault} 
                  onClick={() => window.location.href = item?.BTNUrl}
                  />}
                >
                  {/* <Infos>
                    <span>{item?.autor +" - "}</span> 
                    <span>{FormateDate(item?.date) +" - "}</span> 
                    <span>{item?.time +" minutes "}</span>
                  </Infos> */}
                  <Title className="blog grey">{item.Titre}</Title>
                  <p className="center">{item.Corps}</p>
                  <a href={item?.BTNUrl} target={item?.BTNMode ? "_blank" : "_self"}>{item?.BTNLib}</a>
                </Card>
              );
            })
          }
            
        </WrapperBlogs>
        {/* {isLoading && <SpinnerHP />}  */}
        
      </Section>
  );
}