import React,{useState,useEffect} from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux";
import { useLocation, navigate } from '@reach/router';
import BoutonDeconnexiontST from "../DesignSystem/SelfTissus/BoutonDeconnexiontST"
import styled from "styled-components"
import CommandesListeST from "./CommandesListe";
import MembreInfosST from "./MembreInfos";
import MembreRealisationsST from "./MembreRealisations";
import MembreAteliersST from "./MembreAteliers";
import { 
  TabContent, TabPane, 
  Row, Col , Nav, NavItem, NavLink,
} from 'reactstrap'; 
import MembrePointsST from "./MembrePoints";
import MembreNewsST from "./MembreNews";
import FormConnexionST from "../FormsST/FormConnexion";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import MembreFacturesST from "./MembreFactures";


const NavLinkStyled = styled(NavLink)`
font-size: 20px;
border: none !important;
color: ${props => props.theme.grey03ST} !important;
background-color: transparent !important;
&.active {
color: ${props => props.theme.greenST} !important;
border-bottom: 2px solid ${props => props.theme.greenST} !important;
}
&:hover>div {
text-decoration: none;
color: ${props => props.theme.greenST};
}
`;

const NavItemStyled = styled(NavItem)`
@media only screen and (max-width: 768px) {
  width: 100%;
}

`;

const Box=styled.div`
width: 100%;
margin: 0 auto;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
@media only screen and (max-width: 768px) {
  padding: 0;
}
`
const Title=styled.p`
font-size: 30px;
color: ${(props) => props.theme.greenST};
font-family: "Homemade Apple", sans-serif;
`

const TabTitle = styled.div`
  font-size: 18px;
  `;

export default function EspaceMembreST() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const user = useSelector(state => state?.user?.user?.Contact);
  const [factures, setFactures] = useState([]);
  const [commandes, setCommandes] = useState([]);
  const [ateliers, setAteliers] = useState([]);
  const [realisations, setRealisations] = useState([]);
  const [points, setPoints] = useState([]);
  const [news, setNews] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const content1tab = (
  
    <CommandesListeST/>
  );

  const content2tab = (
    <MembreFacturesST data={factures}/>
  );
  
  const content3tab = (
    <MembreAteliersST/>
  );
  const content4tab = (
    <MembreRealisationsST/>
    
  );
  const content5tab = (
    <MembrePointsST/>
  );
  const content6tab = (
    <MembreInfosST/>
  );
  const content7tab = (
    <MembreNewsST/>
  );
 

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/membre`)
    .then((response) => {
      console.log("response", response);
      setFactures(response?.data?.Contact?.Factures);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab !== null) {
      toggle(parseInt(tab, 10));
      // Remove the tab parameter from the URL
      params.delete('tab');
      const newSearch = params.toString();
      navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`, { replace: true });
    }
  }, [location]);

  const handleTabClick = index => {
    toggle(index);
    // Remove the tab parameter from the URL
    const params = new URLSearchParams(location.search);
    params.delete('tab');
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const deconnexion = () => {
      setIsLoading(true);
       axios.post("/users/deconnexion")   
       .then(res => {
          dispatch({type: "DELETE_USER"})
           setIsLoading(false);
           setIsErrorVisible(true);
           setTxt("Vous êtes déconnecté");
       })
       .catch(err => {
           console.log("err", err);
           setIsLoading(false);
           setTxt(err?.response?.data ? err.response.data : "Une erreur est survenue");
           setIsErrorVisible(true);
           dispatch({type: "DELETE_USER"})
       })
   } 

   const tabData = [
    { label: <TabTitle>Mes commandes</TabTitle>, content: content1tab,},
    { label: <TabTitle>Mes factures</TabTitle>, content: content2tab,},
    { label: <TabTitle>Mes ateliers</TabTitle>, content: content3tab},
    { label: <TabTitle>Mes réalisations</TabTitle>, content: content4tab},
    { label: <TabTitle>Mes points de fidélité</TabTitle>, content: content5tab},
    { label: <TabTitle>Mes infos personnelles</TabTitle>, content: content6tab},
    { label: <TabTitle>Mes newsletters</TabTitle>, content: content7tab},
  ];

    return (
        <Box>
           {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Déconnexion"} onClick={() => setIsErrorVisible(false)}/>}
            {isLoading && <SpinnerST />}
            {user ? <><Title className="homemadeTitle">Bonjour {user?.Prenom} !</Title>
            <div>
            <Nav tabs style={{justifyContent:"center", marginBottom:30}}>
              {tabData.map((tab, index) => (
                <NavItemStyled className={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
            </div>
            <BoutonDeconnexiontST className="flex" width={"100%"} lib={"Déconnexion"} onClick={deconnexion}/></> : <FormConnexionST/>}
        </Box>
    )
}