import React, { useState} from "react";
import styled from "styled-components";
import FormLocalisationST from "../FormsST/FormLocalisation";
import PopErrorST from "../PopupsST/PopError";

const Box=styled.div`
position: relative;
width: 90%;
margin: 0 auto;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
&.pop {
  overflow-y: scroll;
  height: 80vh;
}
@media screen and (max-width: 768px) {
  padding: 0;
  width: 100%;
}
`


export default function LocalisationST({onClick, mode}) {
    
   
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState("");
  

  
    return (
      <>
        <Box className={mode}>
        {isErrorVisible && <PopErrorST message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
          <h2 className="center homemadeTitle initial green">Astuce</h2>
          <p>
            Choissisez votre magasin pour afficher les produits disponibles en
            click and collect près de chez vous
          </p>
          <FormLocalisationST onClick={onClick} mode={mode}/>
        </Box>
       
      </>
    );
  }
