import React from "react";
import { Card } from "antd";
import { Section } from "../DesignSystem/SelfTissus/ContainersST";
import img from "../../assets/images/img.jpg";
import BoutonFlecheST from "../DesignSystem/SelfTissus/BoutonFleche";
import styled from "styled-components";
import picDefault from "../../assets/images/photo.jpg";

const Wrap = styled.div`
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
`;

const CardStyled = styled(Card)`
width: 300px;
&> .ant-card-cover {
    height: 300px; 
}
&> .ant-card-cover > img {
    height: 100%; 
}

@media screen and (max-width: 786px) {
    width: 100%;
}

`

export default function CardsST({data, titre}) {
    return (
        <Section>
            <h2>{titre}</h2>
            <Wrap>
            {data && data.map((item, i) => (
                <CardStyled
                    key={i}
                    hoverable
                    cover={ <div
                        style={{
                          backgroundImage: `url("${item?.image?.url ? item?.image?.url : picDefault}")`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%'
                        }}
                        aria-label={item?.image?.alt}
                      />}
                >
                    <BoutonFlecheST padding={"0"} lib={item?.BTNLib} href={item?.BTNUrl} target={data?.BTNMode ? "_blank" : "_self"}/>
                </CardStyled>
            ))}
            </Wrap>
        </Section>
    );
}