import React from "react";
import {PopUp,PopUpTitle, Wrap, ButFermer} from "../DesignSystem/PopUpST";
import {IoCloseCircleOutline} from "react-icons/io5";
import LocalisationST from "../Localisation/Localisation";



export default function PopLocalisationST({ onClick, className}) {

  return (
    <>
      <PopUp className={className}>
        <ButFermer onClick={onClick} className="white"><IoCloseCircleOutline/></ButFermer>
          <PopUpTitle>Choissisez votre magasin</PopUpTitle>
          <Wrap>
            <LocalisationST onClick={onClick} mode="pop"/>
          </Wrap>
        </PopUp>
    </>
  );
}
