import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled,Legal } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import {IoCloseCircleOutline} from "react-icons/io5";
import { ButFermer } from "../DesignSystem/PopUpST";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";

const Wrap = styled.div`
position: relative;
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 600px;
max-width: 600px;
margin: 100px auto 0;
padding: 20px;
background-color: ${(props) => props.theme.beige02ST};
  @media only screen and (max-width: 992px) {
    max-width: 90%;
    gap: 10px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
export default function FormOubliPassST({onClose}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState("");
    const [Nom, setNom] = useState("");
    const [Prenom, setPrenom] = useState("");
    const [Mail, setMail] = useState("");
    const [Identifiant, setIdentifiant] = useState("");


    const handleOubliPass = (e) => {
      setIsLoading(true);
      axios.post("/users/connexionoubliepass", {"Nom": Nom, "Prenom": Prenom, "Mail": Mail, "Identifiant": Identifiant})  
      .then(res => {
        console.log("res", res);
          setIsLoading(false);
          setIsErrorVisible(true);
          setTxt(res?.data?.ResultInfos);
      })
      .catch(err => {
          console.log("err", err);
          setIsLoading(false);
          setTxt(err?.response?.data ? err.response.data : "Une erreur est survenue");
          setIsErrorVisible(true);
      });
  }

    return (
        <Wrap>
          {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Réinitialisation de mot de passe"} onClick={() => setIsErrorVisible(false)}/>}
          {isLoading && <SpinnerST />}
          <ButFermer onClick={onClose}><IoCloseCircleOutline/></ButFermer>
            <Txt> 
              <h2>Réinitialiser son mot de passe</h2>
              <p>Nous vous ferons parvenir un e-mail pour réinitialiser votre mot de passe</p>
              </Txt>
              <FormStyled floating>
                  <InputStyled
                        id="nom"
                        name="nom"
                        placeholder="Nom"
                        type="text"
                        value={Nom}
                        onChange={(e) => setNom(e.target.value)}  
                        autoComplete
                  />
                  <Label for="nom">Nom</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="prenom"
                      name="prenom"
                      placeholder="Prénom"
                      type="text"
                      value={Prenom}
                      onChange={(e) => setPrenom(e.target.value)}
                      autoComplete
                  />
                  <Label for="prenom">Prénom</Label>
                  </FormStyled>

                

                <FormStyled floating>
                  <InputStyled
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      value={Mail}
                      onChange={(e) => setMail(e.target.value)}
                      autoComplete
                  />
                  <Label for="email">E-mail</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="identifiant"
                    name="identifiant"
                    placeholder="Identifiant"
                    value={Identifiant}
                    onChange={(e) => setIdentifiant(e.target.value)}
                    type="text"
                  >
                    </InputStyled>
                  <Label for="identifiant">Identifiant</Label>
                </FormStyled>



                <BoutonNoirST lib={"Envoyer"} onClick={handleOubliPass}/>
                <LienNoirBtnST lib={"Annuler"}  onClick={onClose}/>
               
          </Wrap>
  );
}
