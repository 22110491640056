import React from "react";
import styled from 'styled-components';
import {Section} from "../DesignSystem/SelfTissus/ContainersST";
import bg from "../../assets/images/bg-tissus-fonce.jpg"

const Bg = styled.div`
padding: 10px 10px 30px;
background-image: url(${bg});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
width: 100%;
`;

const Blocs = styled.div`
display: flex;
flex-wrap: wrap;
`;
const Bloc = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
flex: 1;
min-width: 25%;
padding: 20px;
border-right: 1px dashed #d0cec9;
&:last-child {
border-right: none;
}
&>h3{
    margin: 10px auto;
}
&>a{
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 19px;
    color :${props => props.theme.orangeST};
}
@media (max-width: 992px) {
    min-width: 35%;
    margin: 10px 0;
}
@media (max-width: 768px) {
    border-right: none;
    width: 100%;
    min-width: 100%;
    }

`;


export default function BandeauTutosST({data}) {

    return (
        <Section className="ignoreParent">
            <Bg>
                <h2 className="white center">{data?.Titre}</h2>
                <Blocs>
                    {
                        data?.Data.map((bloc, index) => (
                            <Bloc key={index}>
                                <h3 className="white center">{bloc?.Titre}</h3>
                                <p className="white center">{bloc?.Corps}</p>
                                {bloc?.BTNUrl ? <a href={bloc?.BTNUrl} target={bloc?.BTNMode ? "_blank" : "_self"}>{bloc?.BTNLib}</a> : null}
                            </Bloc>
                        ))
                    }
                  
                    {/* <Bloc>
                        <h3 className="white center">Débutant</h3>
                        <p className="white center">J’ai de légères bases et je souhaite progresser.</p>
                    </Bloc>
                    <Bloc>
                        <h3 className="white center">Intermédiaire</h3>
                        <p className="white center">Je connais quelques techniques, je suis habituée à coudre.</p>
                    </Bloc>
                    <Bloc>
                        <h3 className="white center">Expert</h3>
                        <p className="white center">Je suis professionnel·le et/ou je maîtrise beaucoup de techniques. Je sais coudre de nombreuses matières et réalise des ouvrages complexes.</p>
                    </Bloc> */}
                </Blocs>
            </Bg>
        </Section>   
    )
 


}