import React from "react";
import styled from 'styled-components';
import {FaChevronDown} from 'react-icons/fa'
import {FaChevronUp} from 'react-icons/fa'

const Espace= styled.hr`
width: 100%;
margin: 50px 0;
border: 0;
height: 2.5px;
background-image: linear-gradient(to right, rgba(0, 0, 0, 0), ${props => props.color || props.theme.greenST}, rgba(0, 0, 0, 0)); 

`

export default function EspaceST(props) {

    return <Espace color={props.color}/>

}