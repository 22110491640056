import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const Btn = styled.button`
text-decoration: none;
text-align: center;
text-transform: uppercase;
border: none;
background: ${props => props.background || "#fff"};
padding: 18px;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "auto"};
position: relative;
cursor: pointer;
color: #fff;
font-weight: 600;
font-size: 15px;
transition: all .2s ease-out;
  &:hover {
    opacity: 0.8;
}
&.selected {
    background: ${props => props.theme.orangeST};
    color: #fff;
    scale: 1.1;
}
@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonST(props) {
    return <Btn 
    className={props.className} 
    margin={props.margin} 
    width={props.width} 
    widthMob={props.widthMob} 
    onClick={props.onClick}
    background={props.background}
    >
    {props.lib}
    </Btn>   


}