import React from "react";
import styled from 'styled-components';

export const WrapperForm = styled.div`
position: fixed;
height: 100px;
display: flex;
left: 0;
align-items: center;
justify-content: center;
gap: 50px;
top: 0;
width: 100%;
z-index: 99999;
margin: auto;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
background: #fffdfd;
opacity: 0;
transform: translateY(-200px);
transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
&> svg {
  fill: ${(props) => props.theme.grey01ST};
  height: 30px;
    width: 30px;
  cursor: pointer;
  &:hover {
    fill: ${(props) => props.theme.redST};
  }
}
&.show {
  transform: translateY(0);
  opacity: 1;
}
&.mob {
    gap: 15px;
    padding: 25px;
}
@media screen and (max-width: 576px) {
    gap: 15px;
    padding: 25px;
}

  `