import styled, {css} from 'styled-components';

export const BoutonVertSearch = styled.button`
position: ${props => props.position || "absolute"};
right: 0;
top: 0;
text-decoration: none;
border: none;
background: ${props => props.theme.greenST};
display: flex; 
text-align: center;
align-items: center; 
justify-content: center;
align-items: center;
padding: 4px;
color: #fff; 
font-size: 17px;
text-transform: uppercase;
transition: all .2s ease-out;
&> span {
  display: block;
  padding: 9.8px; 
  border: 2px dashed #fff; 
    &>svg {
    transition:   fill 0.3s ease;
    fill: #fff;
    height: 25px;
    width: 25px;
  }
}
&:hover {
  box-shadow: inset 0 0 0 1px #A29995;
    background: #fff;
    color: ${props => props.theme.greenST};
    text-decoration: none;
    &> span {
      border-color: ${props => props.theme.greenST};
      &>svg {
      fill: ${props => props.theme.greenST};
    }
  }
}
`