import React, {useState, useEffect} from "react";
import styled from "styled-components";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { List } from 'antd';
import {Wrap} from "./ElementsMembre";

const Commande = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
& img {
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  border-radius: 8px;
  width: 172px;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap !important;
  }
  `;

const ListItemStyled = styled(List.Item)`
border: 1px solid ${(props) => props.theme.grey01ST} !important;
border-radius: 8px;
padding: 20px;
margin: 0 0 20px 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
&>.ant-list-item-extra {
  display: flex;
  align-items: end;
  margin-inline-start: 0 !important;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap !important;
  &>.ant-list-item-extra {
    margin: 0 !important;
    &> div > a {
      padding: 5px 0;
    }
  }
  }
`;

const data = Array.from({ length: 2 }).map((_, i) => ({
    href: 'https://ant.design',
    title: `Atelier n° ${i}`,
    description:
      '',
    content:
      <Commande>
        <img
          width={172}
          alt="logo"
          src="https://selftissus.myshopify.com/cdn/shop/files/atelier-surjeteuse-3h.jpg?v=1698414685&width=823"
        />
        <div>
          <p>Date de l'inscription: 2021-09-01</p>
        </div>
      </Commande>
  }));



export default function MembreAteliersST({className}) {
 

    return (
        <Wrap className={className}>
            <h3 className="center homemadeTitle initial orange">Mes ateliers</h3>
            
            <List style={{width:"100%"}}
                itemLayout="vertical"
                size="large"
                // pagination={{
                //     position: 'bottom',
                //     align: 'start',
                //     total: 15, // Total number of items
                //     showTotal: (total, range) => `Commande ${range[0]}-${range[1]} sur ${total} au total`, // Function to display the range of items and total
                //     defaultPageSize: 10, // Number of items per page by default
                //     defaultCurrent: 1, // Initial page
                //     pageSizeOptions: ['10', '20', '30'], // Options for changing the page size
                //     onChange: (page, pageSize) => {
                //       // Function to handle page change
                //     },
                //   }}
                  dataSource={data}
                // footer={""}
                renderItem={(item) => (
                <ListItemStyled
                    key={item.title}
                    extra={
                      <>
                    {/* <img
                        width={272}
                        alt="logo"
                        src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                    /> */}
                    <div>
                    <LienNoirST display={"block"} lib={"Voir les détails"} href={"/produit"}/>
                    <LienNoirST display={"block"}  lib={"Suivi"} href={"/commandeFiche"}/>
                    </div>
                    </>
                    }
                >
                    <List.Item.Meta
                    title={<p>{item.title}</p>}
                    description={item.description}
                    />
                
                    {item.content}
                </ListItemStyled>
                )}
            />
        </Wrap>
  );
}
