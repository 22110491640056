import React from "react";
import styled from "styled-components";
import ReactPlayer from 'react-player'

const Wrap = styled.div`
width: 100%;
`;

const StyledDiv37 = styled.div`
width: 100%;
max-width: 910px;
height: 500px;
max-height: 500px;
margin: auto;
@media (max-width: 768px) {
    height: 260px;
    max-height: 300px;
    }
`;



export default function VideoST({data}) {

  return (
      <Wrap>  
      {/* {dataVideo?.Titre && dataVideo?.Titre !=="WebComposant" ? <TitreVideo>{ReactHtmlParser(dataVideo?.Titre)}</TitreVideo> : ""} */}
      <h2 className="mb30 center">{data?.Titre}</h2>
        <StyledDiv37>
        <ReactPlayer 
        url={data?.Corps} 
        controls={true}
        width="100%"
        height="100%"
        />
        </StyledDiv37>
         
        </Wrap>
  );
}