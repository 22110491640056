import styled from 'styled-components';
import Fade from 'reactstrap/lib/Fade';

export const Container = styled.div`
background: ${props => props.theme.beige02ST};
padding: 0 100px 50px 100px; 
margin: 0;
width: 100%;
box-shadow: 0px -8px 8px -5px rgba(0,0,0,0.2);
display: flex;
flex-direction: column;
gap: 30px;
&.white {
  background: #fff;
}
&.gap10 {
  gap: 10px;
}
  @media (max-width: 768px) {
    padding: 50px 20px;
}
`;

export const Section = styled.div`
padding: 50px 0;
&.flex {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
}
&.noPadding {
padding: 0;
}
&.rel404 {
  position: relative;
  padding: 150px 0;
  margin-bottom: -50px;
  @media screen and (max-width: 768px) {
    padding: 80px 0;
  }
}
&.ignoreParent {
  width: calc(100% + 200px) !important; 
  margin-left: -100px; 
  margin-right: -100px;
  @media screen and (max-width: 768px) {
    width: calc(100% + 40px) !important;
    margin-left: -20px;
    margin-right: -20px;
  }
}
`
export const WrapperBlogs = styled.div`
padding-top: 30px;
display: flex;
flex-wrap: wrap;
gap: 50px;
align-items: flex-start;
justify-content: center;
`;

export const WrapperBlog = styled.div`
width: 100%;
padding-top: 30px;
display: flex;
flex-direction: column;
flex-wrap: wrap;
gap: 50px;
align-items: center;
justify-content: center;
`;

export const WrapperProds = styled.div`
padding-top: 30px;
display: flex;
flex-wrap: wrap;
gap: 50px;
align-items: flex-start;
justify-content: flex-start;
`;

export const StyledFade = styled.div`
width: 100%;
&.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
`;

export const Buts = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
gap: 20px;
`;


