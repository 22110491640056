import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const Img = styled.img`
flex-basis: 50%;
width: 100%;
max-width: 100%;
max-height: 465px;
order: 0;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    max-height: 300px;
}
`;

const Wrap = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap: 0;
justify-content: center;
&>div:nth-child(even) {
    &>img {
        order: 1;
    }
}
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
    gap: 50px;
    &>div:nth-child(even) {
    &>img {
        order: 0;
    }
    }
}
`;

const Txt = styled.div`
   padding: 0 50px;
   @media screen and (max-width: 786px) {
    padding: 0 20px;
   }
`;



const Bloc = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 0;
justify-content: flex-start;
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;



export default function TitreTextST({data}) {
    return (
        <Bloc>
        <Txt>
            {data?.Titre && data?.Titre !=="WebComposant" ? <h1>{data?.Titre}</h1> : null}
            <p>{ReactHtmlParser(data?.Corps)}</p>
        </Txt>
    </Bloc>
    )
}