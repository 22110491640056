import React, {useEffect,useRef, useState} from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import BoutonFlecheST from "../DesignSystem/SelfTissus/BoutonFleche";
import {Section} from "../DesignSystem/SelfTissus/ContainersST";
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'

const Wrapper = styled.div`
position: relative;
background: transparent;
width: 100%;
transition: all 0.5s ease-in-out;
`;

const Bloc = styled.a`
display: block;
text-decoration: none;
overflow: hidden;
height: 100%;
width: 100%;
height: 380px;
max-height: 380px !important;
&:hover {
  text-decoration: none;
}
  `;

const Keen = styled.div`
background: ${(props) => props.theme.grey08ST};
min-height: 380px !important;
max-height: 380px !important;
padding: 0;
`

const ImgDiv = styled.div`
background-image: url(${(props) => props.src});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
width: 100%;
height: 100%;
`;

const Txt = styled.div`

text-align: left;
position: relative; 
width: 100%;
padding: 20px;
`;

const Link = styled.div`
position: absolute;
bottom: 0;
width: 100%;
background-color: #fff;
`;
function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default function SliderProduitsAdminST({data}) {
  console.log("data", data);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (data) {
      setLoaded(true);
    }
  }, [data]);
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: {
      perView: 4,
      spacing: 25,
    },
    breakpoints: {
      "(max-width: 1360px)": {
        slides: { perView: 3, spacing: 15 },
      },
      "(max-width: 992px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(max-width: 768px)": {
        slides: { perView: 2, spacing: 25 },
      },
      "(max-width: 670px)": {
        slides: { perView: 1, spacing: 0 },
      },
    },
  }, [
    (slider) => {
      let timeout;
      let mouseOver = false;

      function clearNextTimeout() {
        clearTimeout(timeout);
      }

      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 2000);
      }

      slider.on("created", () => {
        slider.container.addEventListener("mouseover", () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener("mouseout", () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });

      slider.on("dragStarted", clearNextTimeout);
      slider.on("animationEnded", nextTimeout);
      slider.on("updated", nextTimeout);
    },
  ]);

  return (
    <Section className="navigation-wrapper noPadding">
      {data?.Titre && <h2>{data?.Titre }</h2>}
      {data?.Corps  && <p>{data?.Corps }</p>}
    <div ref={sliderRef} className="keen-slider">
      {data &&
        data?.Data?.map((item, i) => (
          <Keen key={i} className="keen-slider__slide">
            <Bloc href={item?.slug}>
              {item.Titre && item.Titre !== "WebComposant" ? (
                <Txt>
                  {item.Titre && item.Titre !== "WebComposant" ? (
                    <h3 className="center">{item.Titre}</h3>
                  ) : null}
                  <p className="center">{ReactHtmlParser(item.Corps)}</p>
                </Txt>
              ) : null}
              {item.image && (
                <ImgDiv
                  src={item?.image?.url}
                  alt={item.image?.ImgAlt}
                />
              )}
            </Bloc>
            {item?.BTNLib && (
              <Link>
                <BoutonFlecheST lib={item?.BTNLib} href={item?.BTNUrl} />
              </Link>
            )}
          </Keen>
        ))}
    </div>
    {loaded && <div className="dots">
        <button title="Précédent" className={"dot"}
        onClick={(e) =>
          e.stopPropagation() || instanceRef?.current?.prev()
        }
        // disabled={currentSlide === 0}
      />
      <button title="Active"  className={"dot active"}
      ></button>
      <button title="Suivant" className={"dot"}
        onClick={(e) =>
          e.stopPropagation() || instanceRef?.current?.next()
        }
        disabled={
          currentSlide ===
          instanceRef?.current?.track?.details?.slides?.length - 1
        }
      />
    </div>}
  </Section>
  );
}