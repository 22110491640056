import React from "react";
import styled from "styled-components";
import PanierST from "./Panier";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;


export default function PanierAccueilST({className}) {

    return (
        <Wrap>
            <PanierST className={className}/>
        </Wrap>
  );
}
