import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const Btn= styled.a`
display: ${props => props.display || "inline-block"};
align-items: center; 
justify-content: center;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "auto"};
position: relative;
cursor: pointer;
color: ${props => props.theme.blackST}; 
font-size: 17px;
transition: all .2s ease-out;
  &:hover {
    color: ${props => props.theme.blackST}; 
    text-decoration: underline ${props => props.theme.blackST} 2px;
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function LienNoirST(props) {


    return <Btn href={props.href} display={props.display} className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob}>{props.lib}</Btn>   


}