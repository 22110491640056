import styled from 'styled-components';
import  {Form,FormGroup ,Label, Input, Button} from "reactstrap";
import Fade from 'reactstrap/lib/Fade';

export const FormConnect = styled(FormGroup)`
position: relative;
width: 100%;
max-width: 660px;
margin: 10px auto !important;
&.bordered {
  border: #000 1px solid;
}

&.mobileForm {
  display: none;
  max-width: 100%;
  &>input {
    padding: 0.875rem .75rem;
    font-size: 15.5px;
  }
}
@media only screen and (max-width: 1199px) {
&.mobileForm {
  display: flex;
}
}  

`

export const FormGroupStyled2 = styled(FormGroup)`
position: relative;
width: 100%;
max-width: 660px;
margin: 10px auto !important;
&.bordered {
  border: #000 1px solid;
}
`

export const InputStyled = styled(Input)`
border: none;
border-radius: 0;
background-color: transparent;
color:${(props) => props.theme.grey05ST} !important;
@media only screen and (max-width: 768px) {
width: 100%;
}  
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}

&:focus {
    background-color: transparent;
    border-color:${(props) => props.theme.redST};
    box-shadow: 0 0 0 0.25rem #df5a4e21;
    /* color:#1c27538c; */
}
&:focus::placeholder {
  color:${(props) => props.theme.grey05ST};
}
`

export const TxtInfo = styled.div`
border-radius: 5px;
border: 1px solid ${(props) => props.theme.grey05ST};
box-shadow: 0 0 10px rgba(0,0,0,0.1);
padding: 20px;
&>* {
  text-align: center;
}
&>a:hover {
  color: ${(props) => props.theme.grey05ST};
}
&.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
`
export const LinkStyledSocial = styled.a`
&>svg {
  width: 20px;
  height: 20px;
}
  color: ${(props) => props.theme.grey04ST};
  &:hover {
    color: ${(props) => props.theme.redST};
  }
  `

export const Socials = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 20px;
`
