import styled from 'styled-components';
import {
    Row,
    Col,
  } from "reactstrap";

export const ContainerCookies=styled.div`
position: fixed;
padding:20px;
bottom: 0;
left: 0;
width: 100vw;
height: auto;
background: #ffffffe8; 
text-align: center;
box-shadow: 0 0 21px 0 rgba(0,0,0,0.5);
z-index:9999999;
visibility: visible;
opacity: 1;
transition: visibility .3s, opacity .3s linear;
&.cookieOff {
    visibility: hidden;
    opacity: 0;
}
&.cookieOn {
    visibility: visible;
    opacity: 1;
}
  `
 export const RowCookies=styled(Row)`
  padding:10px;

`   

export const RowButtons=styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
gap: 20px;
`
export const ColTxt=styled(Col)`
  padding-bottom:20px;
@media only screen and (max-width: 768px) {
  &>p{
    font-size: 14px;
  }
}
`

export const CookiesButton=styled.button`
min-width: 150px;
border : none;
margin: 5px;
display: inline-block;
padding: 20px;
cursor: pointer;
background: #fff;
text-align: center;
font-size: 17px;
font-weight: 600;
transition: all .2s ease-out;
letter-spacing: 0.06rem;
font-family: 'Assistant', sans-serif;
&.no {
    background: ${props => props.theme.redST};
    color: #fff;
}
&.yes {
    background: ${props => props.theme.greenST};
    color: #fff;
}
&:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 768px) {
  width: 100%;
  padding: 15px;
  margin: auto;
}
`
export const Icon=styled.div`
display: flex;
align-items: center;
justify-content: center;
align-items: center;
color: #6e6b5f;
background-color: #fff;
text-align: center;
cursor: pointer;
z-index: 1000;
&>svg{
  transition: all .2s ease-out; 
  width: 20px;
  height: 20px;
  fill: ${props => props.theme.greenST};
}
`

export const CookiesDiv=styled.div`
z-index: 15000;
position: fixed;
width: 50px;
bottom: 0;
left: 5%;
display: block !important;
padding: 8px;
border: 1px solid #d6d3ca;
border-bottom: none;
border-radius: 10px 10px 0 0;
background-color: #fff;
cursor: pointer;
z-index: 1000;
transition: width .5s;
&:hover {
  &>${Icon}>svg{
    fill: ${props => props.theme.orangeST};
  }
}
`
