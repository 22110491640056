import React, {useState,useRef,useEffect} from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled from 'styled-components';
import { Link  } from "@reach/router";
import SwiperCore, { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const BandeauWrapper = styled.div`
position: relative;
z-index: 2;
background-color: #000;
width: 100%;
color: #fff;
padding: 10px 0; 
transition: all 0.5s ease-in-out;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
@media (max-width: 768px) {
  padding: 25px 0;
}
`;

const StyledLink = styled.a`
width: 80%;
text-align: center; 
display: block;
text-align: center;
margin: auto;
cursor: pointer;
padding: 0;
color: #fff;
text-decoration: none;
&:hover {
  opacity: 0.8;
}
`;

const Close = styled.div`
position: absolute;
top: 5px;
right: 10px;
z-index: 2;
cursor: pointer;
&> svg {
  width: 25px;
  height: 35px;
  fill: ${(props) => props.theme.greenST};
}
`;

const Txt = styled.p`
width: 80%;
margin: auto;
`;



function BandeauInfoSliderSelf({data}) {
  const swiperRef = useRef(null);
  const [show, setShow] = useState(true);

  return (
    <>
        <>
        {data && data.Bandeau ? (
          <BandeauWrapper style={{ backgroundColor: data?.Bandeau && data?.Bandeau[0].CouleurFond }}>
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            loop={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{ delay: 4500, disableOnInteraction: false }}
            navigation={true}
            modules={[Navigation, Autoplay]}
          >
            {data.Bandeau.sort((a, b) => a.Ordre - b.Ordre).map((item, i) => (
              <SwiperSlide key={i}>
                <StyledLink href={item?.Url} style={{ color: data?.Bandeau && data?.Bandeau[0].CouleurTxt }}>{item.Texte}</StyledLink>
              </SwiperSlide>
            ))}
          </Swiper>
      </BandeauWrapper> ) : null}
    </>
      </>
  )
}


export default BandeauInfoSliderSelf;