import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import picDefault from "../../assets/images/photo.jpg";

const Socials = styled.div`
padding: 10px; 
display: flex;  
flex-direction: column;
align-items: center; 
justify-content: center;
flex-wrap: wrap;
`;

const Flex = styled.div`
padding: 10px;
display: flex;
gap: 22px;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

const Social = styled.div`
padding: 10px; 
display: flex;  
flex-direction: column;
gap: 22px; align-items: center; 
justify-content: center;
`;

const StyledLink = styled.a`
 position: relative; overflow: hidden;cursor: pointer;
  &>svg{
    color:${props => props.theme.blackST};
    width: 30px;
    height: 30px;
  }
  &:hover{
    &>svg{
      opacity: 0.8;
    }
  }

  &>img{
    max-width: 90px;
  }
`;



export default function SocialsBigST({data}) {
  return (
            <Socials>
            <h3 className="center bold">{data?.Titre ? data?.Titre : "Intéragisez avec nous"}</h3>
            {ReactHtmlParser(data?.Corps)}
            <Flex>
              {
                data && data?.Data?.map((item, i) => {
                  return (
                    <Social>
                      <StyledLink href={item?.BTNUrl} target={item?.BTNMode ? "_blank" : "_self"}>
                          <img src={item?.image?.url} alt={item?.ImgAlt}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = picDefault;
                            }} loading="lazy"/>
                          </StyledLink>
                          <p className="bold center">{item?.Titre}</p>
                      </Social>
                )})
              }
              </Flex>
              </Socials>
  );
}
