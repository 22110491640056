import React from "react"
import { Router} from "@reach/router"

//Components
import BlogFiche_ST from "./pages/BlogFiche"

export default function RouterAppReact() {
  
    return (
        <>
            <Router>
                <BlogFiche_ST path="/blogFiche" />
            </Router>
        </>
    )
}
