import styled from 'styled-components';
import  {Form,FormGroup ,Label, Input, Button} from "reactstrap";
import Fade from 'reactstrap/lib/Fade';

export const StyledFade = styled(Fade)`
    width: 100%;
    height: auto;
    &.fade:not(.show){
    max-height: 0px; 
    margin: -24px 0 0 0;
  }
&.fade {
  transition: all .15s linear;
}
`
export const Logged = styled.div`
position: absolute;
width: 19px;
height: 19px;
top: 3px;
right: -3px;
background: ${(props) => props.theme.greenST};
color: #fff;
border-radius: 50%;
font-size: 12px;
&>svg {
  font-size: 12px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 49%;
  left: 50%;
}
&.mob {
  width: 14px;
  height: 14px;
  top: 1px;
  right: -5px;
  &>svg {
  font-size: 8px;
}
}
`;

export const Number = styled.div`
position: absolute;
width: 19px;
height: 19px;
top: 3px;
right: -3px;
background: ${(props) => props.theme.redST};
color: #fff;
border-radius: 50%;
font-size: 12px;
&>span {
  font-size: 12px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 54%;
}
&.mob {
  width: 14px;
  height: 14px;
  top: 1px;
  right: -5px;
  &>span {
  font-size: 10px;
  top: 50%;
}
}
&.mob2 {
  width: 14px;
  height: 14px;
  top: 1px;
  right: -5px;
  &>span {
  font-size: 10px;
  top: 48%;
}
}
`;

export const Ville = styled.span`
position: absolute;
bottom: 26px;
right: -20px;
font-size: 10px;
background: #f05b32c9;
color: #fff;
border-radius: 5px;
padding: 1px 5px;
text-align: center;
&.mob {
  font-size: 8px;
  bottom: 12px;
}
`;
