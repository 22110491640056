import React from "react";
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import {PopUpContainer, PopUp,PopUpTitle, Wrap, ButFermer, ButtonPop} from "../DesignSystem/PopUpST";
import {IoCloseCircleOutline} from "react-icons/io5";


export default function PopErrorST({titre, message, onClick, className, classNameBut}) {


  return (
    <>
     {ReactDOM.createPortal(
                <PopUpContainer>
                  <PopUp className={className}>
                    <ButFermer onClick={onClick} className={classNameBut}><IoCloseCircleOutline/></ButFermer>
                      <PopUpTitle>{titre}</PopUpTitle>
                      <Wrap>
                         {ReactHtmlParser(message)}
                         <ButtonPop className="center" onClick={onClick}><IoCloseCircleOutline/>Fermer</ButtonPop>
                      </Wrap>
                    </PopUp>
                </PopUpContainer>,
            document.body
              )} 
 
    
    </>
  );
}
