import React from "react";
import styled from 'styled-components';
import { PiArrowBendDownRightLight } from "react-icons/pi";

const Txt = styled.span`
position: absolute;
left: 15%;
transform: translateY(-50%) rotate(-30deg);
top: 50%;
text-decoration: none;
font-family:  "Homemade Apple", sans-serif;
color: ${props => props.theme.greenST};
font-size: 23px;
transition: all .2s ease-out;
text-align: center;
display: flex;
flex-direction: column;
&> svg {
  height: 50px;
  width: 50px;
  align-self: center;
  transform: rotate(10deg);
}
@media (max-width: 768px) {
    display: none;
}
`;


export default function TxtFlottantST(props) {


    return <Txt className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} href={props.url}>{props.lib}<PiArrowBendDownRightLight/></Txt>   


}