import React from "react";
import styled from "styled-components";
import BoutonRouge2ST from "../DesignSystem/SelfTissus/BoutonRouge2";
import TxtFlottantST from "../DesignSystem/SelfTissus/TxtFlottant";
import bg from "../../assets/images/bg-tissus-fonce.jpg"
import ReactHtmlParser from 'react-html-parser';

const Wrap=styled.div`
position: relative;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
padding: 50px;
width: 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
z-index: 2;
`

export default function FranchiseST({data}) {
  return ( 
    <>
    <Wrap className="ignoreParent" style={{backgroundImage: `url(${data?.Data[0]?.image?.url})`}} aria-label={data?.Data[0]?.image?.alt}>
        <h3 className="white">{ReactHtmlParser(data?.Titre)}</h3>
        <p className="homemadeTitle green center fs20">{data?.Corps}</p>
        <BoutonRouge2ST lib={data?.BTNLib} href={data?.BTNUrl} target={data?.BTNMode ? "_blank" : "_self"}/>
        <TxtFlottantST lib={"Rejoignez-nous !"} />
    </Wrap>   
    
  </>
)
}
