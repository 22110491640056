// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    items: [],
    totalItems: 0,
};

// Create the reducer function
const favorisReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_FAVORIS":
          const newItemsAdd = [...state.items, action.payload];
            return {
                ...state,
                items: newItemsAdd,
                totalItems: newItemsAdd.length,
            };
        case "DELETE_FAVORIS":
          const newItemsDelete = state.items.filter((item) => item.ID !== action.payload);
          return {
              ...state,
              items: newItemsDelete,
              totalItems: newItemsDelete.length,
          };
        default:
            return state;
    }
};

export default favorisReducer;