import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import FormAdresseST from "../FormsST/FormAdresse";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import  {FormGroup ,Label, Input} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;


const DescriptionsStyled = styled(Descriptions)`
width: 100%;
font-family: 'Assistant', sans-serif;
&>.ant-descriptions-view  {
}
&>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #f05b320a !important;
  font-size: 17px !important;
}
&>.ant-descriptions-view > table > tbody > tr> td {
  font-size: 16px !important;
}
`;

const Mag = styled.div`
position: relative;
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
}
`;

export default function AdresseST({className, isTermsAccepted, handleCheckboxChange}) {
  const user = useSelector(state => state.user?.user?.Contact); 
  const mag = useSelector(state => state.magasin?.magasin);
  const relais = useSelector(state => state.relais?.relais);
  const [idMag, setIdMag] = useState(mag?.ID || 2);
  console.log("idMag", idMag);
  const [showForm , setShowForm] = useState(false);
  const [magasin, setMagasin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (idMag === 2) {
      return;
    }
    setIsLoading(true);
    axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
    .then((response) => {
      const filteredMagasins = response?.data?.Magasin.find(magasin => magasin.ID == idMag);
      setMagasin(filteredMagasins);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);
  const items = [
    {
      key: '1',
      label: 'Nom',
      children: user?.Nom,
      span: 3,
    },
    {
      key: '2',
      label: 'Prénom',
      children: user?.Prenom,
      span: 3,
    },
    {
      key: '3',
      label: 'Numéro de téléphone',
      children: user?.Tel,
      span: 3,
    },
    {
      key: '4',
      label: 'Adresse',
      children: user?.Adresse,
      span: 3,
    },
    {
      key: '5',
      label: 'Code postal',
      children: user?.CP,
      span: 3,
    },
    {
      key: '6',
      label: 'Ville',
      children: user?.Ville,
      span: 3,
    },
    {
      key: '7',
      label: 'Pays',
      children: user?.Pays,
      span: 3,
    },
  ];

    return (
        <Wrap className={className}>
          <h2 className="center homemadeTitle initial green">Votre adresse de livraison</h2>
          {(idMag === 2 && !relais) ? (
          <>
            {!showForm && (
              <DescriptionsStyled
                title=""
                bordered
                items={items}
                extra={<BoutonNoirST onClick={() => setShowForm(true)} lib={"Mettre à jour"} />}
              />
            )}
            {showForm && <FormAdresseST user={user} onClose={handleClose} />}
          </>
          ) : (idMag !== 2 && !relais) ? (
            <Mag>
            {isLoading && <SpinnerInlineST />}
            <p className="center orange bold fs20">Vous avez choisi le retrait en magasin</p>
            <p>{magasin?.Nom}</p>
            <p>{magasin?.Adresse}</p>
            <p>{magasin?.CP} {magasin?.Ville}</p>
            <p>{magasin?.Pays}</p>
            <LienNoirST lib={"Voir les détails du magasin"} href={magasin?.slug} target="_blank"/>
            </Mag>
          ) : (
            <Mag>
            {isLoading && <SpinnerInlineST />}
            <p className="center orange bold fs20">Vous avez choisi le retrait en point relais</p>
            <p>{relais?.Nom}</p>
            <p>{relais?.Adresse1}</p>
            <p>{relais?.Adresse2}</p>
            <p>{relais?.Adresse3}</p>
            <p>{relais?.CP} {relais?.Ville}</p>
            </Mag>
          )}
          <FormGroup check>
            <InputStyled type="checkbox" 
            checked={isTermsAccepted} onChange={handleCheckboxChange}
            />
            <Label check>
            J'accepte les <a href="/informations-legales/cgv">conditions générales de vente</a>
            </Label>
          </FormGroup>
          {!isTermsAccepted &&<Message>
             <p className='white bold center'>Vous devez accepter les conditions générales de vente pour continuer</p>
          </Message>}
        </Wrap>
  );
}
