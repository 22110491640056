import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Tag } from "antd";
import { FormGroup, Label } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import BoutonFavouriteST from "../DesignSystem/SelfTissus/BoutonFavourite";
import {Badge} from 'antd';
import {WrapFormPop} from "../DesignSystem/PopUpST";
import PopPanierST from "../PopupsST/PopPanier";
import PopLocalisationST from "../PopupsST/PopLocalisation";
import { FaRegHandPointRight } from "react-icons/fa6";
import picDefault from "../../assets/images/photo.jpg";
import FavoriHeartST from "../Favoris/FavoriHeart";
import ShareST from "../Share/ShareST";

const Wrap = styled.div`
display: flex;
align-items: flex-start;
gap: 50px;
@media screen and (max-width: 786px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
`;


const Prod = styled.a`
display: flex !important;
color: ${props => props.theme.greenST} !important;
align-items: center;
justify-content: flex-start;
gap: 10px;
font-size: 14px;
&:hover {
  color: ${props => props.theme.orangeST} !important;
}
`;

const ProdsSmall = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
gap: 10px;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const TagStyled = styled(Tag)`
font-size: 16px;
padding: 5px 10px;
`;

const Prices = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 18px;
gap: 20px;
`

const New = styled.span`
font-weight: 700;
font-size: 28px;
`
const TxtSmall = styled.div`
color: ${(props) => props.theme.grey09ST};
font-size: 14px;
`;

const Buts = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
justify-content: flex-start;
align-content: flex-start;
flex-wrap: wrap;
`;
const Quantity = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`;

const Prix = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
font-size: 20px;
font-weight: 700;
span {
  color: ${(props) => props.theme.redST};
  font-size: 24px;
}
`;

const Details = styled.div`
padding: 20px 0;
&>* {
  color: ${props => props.theme.grey06ST}; 
}
`;

const Img = styled.img`
max-width: 50%;
width: 50%;
margin-top: 20px;
height: auto;
object-fit: contain;
@media screen and (max-width: 786px) {
    max-width: 100%;
}
`;

const Cont = styled.div`
width: 50%;
@media screen and (max-width: 786px) {
    width: 100%;
}
`;


export default function AtelierFicheST({data}) {
  const dispatch = useDispatch();
  const basket = useSelector(state => state.basket);
  const favoris = useSelector(state => state.favoris);
  const [quantity, setQuantity] = useState(1);
  const unitPrice = data?.PUTTC; 
  const [selected, setSelected] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [changeMag, setChangeMag] = useState(false);
  const handleSelect = (id) => {
    setSelected(prev => prev === id ? null : id);
  };


const handleQuantityChange = (e) => {
  const newQuantity = parseInt(e.target.value, 10);
  setQuantity(newQuantity);
};

const getColorByDifficulte = (difficulte) => {
  switch (difficulte) {
    case 1:
      return '#13c2c2';
    case 2:
      return '#722ED1';
    case 3:
      return '#EB2F96';
    default:
      return '#000';
  }
};

function formatDuration(duration) {
  const [hours, minutes] = duration.split(':');
  return `${parseInt(hours)}h${parseInt(minutes)}min`;
}
const [isFav, setIsFav] = useState(false);
const totalPrice = (quantity * unitPrice).toFixed(2);
const existingItem = favoris.items.find(item => item.ID === data.ID);
const found = !!existingItem;

useEffect(() => {
  setIsFav(found);
}, [found]);

const handleFavouriteChange = (newFavouriteState,article) => {
  setIsFav(newFavouriteState);
  favorisAjout(article,newFavouriteState);
};
const favorisAjout = (article,isFavF) => {
  setIsFav(isFavF)
  if (!existingItem) {
    dispatch({
      type: "ADD_TO_FAVORIS",
      payload: { ...article, quantity: 1, mode: "atelier"},
    });
  }  else {
    dispatch({
      type: "DELETE_FAVORIS",
      payload: article.ID,
    });
  }
}

const panierAjout = (article) => {
  const existingItem = basket.items.find(item => item.ID === article.ID);
  if (existingItem) {
    dispatch({
      type: "UPDATE_BASKET",
      payload: {
        id: article.ID,
        quantity: existingItem.quantity + quantity
      }
    });
  } else {
    dispatch({
      type: "ADD_TO_BASKET",
      payload: { ...article, quantity: quantity, mode : "atelier" },
    });
  }

  setShowForm(true);
};

  return (
  <>
  <Badge.Ribbon text={"Difficulté : "+data?.Difficulte} color={getColorByDifficulte(data?.Difficulte)} placement={"start"}>
   <Wrap className="desc">
   <FavoriHeartST isFav={isFav} onClick={() => handleFavouriteChange(!isFav,data)}/>
   <ShareST shareUrl={data?.slug} title="" className={""} media={data?.img?.url}/>
    <Img src={data?.img?.url} alt={data?.img?.alt} onError={(e) => {
      e.target.onerror = null;
      e.target.src = picDefault;
      }} loading="lazy"/>
    <Cont>
    <h1 className="left">{data?.lib}</h1>
    <Prices>
    <New>{data?.PUTTC +" €"}</New>
    </Prices>
    <TxtSmall>Taxes incluses.</TxtSmall>
    <hr></hr>
    <TagStyled color="green">Durée : {formatDuration(data?.Duree)}</TagStyled>
    <Details>
      {ReactHtmlParser(data?.Description)}
      <ProdsSmall>
        {data?.Produits && <hr></hr>}
        {data?.Produits?.map((prod, i) => {
              return (
                <Prod href={prod?.slug}><FaRegHandPointRight/>{prod?.lib}</Prod>
              );
            }
          )}
        </ProdsSmall>
    </Details>
    <Buts>
      <Quantity>
        <FormGroup floating>
          <InputStyled
             id="prix"
             name="number"
             placeholder="Quantité"
             type="number"
             value={quantity}
             onChange={handleQuantityChange}
             min="1"
          />
            <Label for="prix">
            Quantité
            </Label>
          </FormGroup>
          <Prix>Prix total : <span>{totalPrice} €</span></Prix>
        </Quantity>
      <BoutonBlancBtnST lib="Ajouter au panier" width="100%"  onClick={() => panierAjout(data)}/>
      <BoutonFavouriteST isFav={isFav} lib="Pour un futur projet couture" width="100%" onClick={() => handleFavouriteChange(!isFav,data)}/>
    </Buts>
    </Cont>
    </Wrap>
    </Badge.Ribbon>
     {showForm === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form"><PopPanierST onClick={() => setShowForm(false)} data={data} quantity={quantity} totalPrice={totalPrice} mode="atelier"/></WrapFormPop>,
               document.body
     ) : ""}
     {changeMag === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form">
        <PopLocalisationST onClick={() => setChangeMag(false)}/>
        </WrapFormPop>,
        document.body
     ) : ""}
     </>
  );
}


