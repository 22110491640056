import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { Card } from 'antd';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import { FaTrashAlt,FaPen } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import FormRealisationST from "../FormsST/FormRealisation";
import {WrapFormPop} from "../DesignSystem/PopUpST";

const { Meta } = Card;

const Wrap = styled.div`
position: relative;
display: flex; 
flex-wrap: wrap;
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
`;

const Cards = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
gap: 30px;
`;

const Photos = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 10px;
`;

const Prods = styled.div`
margin-top: 30px;
display: flex;
flex-wrap: wrap;
width: 100%;
gap: 10px;
`;

const Titre = styled.span`
font-size: 16px;
color: ${(props) => props.theme.greenST};
`;

const LinkProd = styled.a`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 10px;
&:hover {
  color: ${(props) => props.theme.redST};
}

`;

const ImageProd = styled.img`
width: 50px;
height: 50px;
`;


const Img = styled.img`
width: 200px;
/* height: 100px; */
`;

const Btn = styled.div`
position: absolute;
top: 0;
right: 0;
`;

const CardStyled = styled(Card)`
width: 400px;
border-radius: 8px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
@media only screen and (max-width: 768px) {
  width: 100% !important;
}
`;




export default function MembreRealisationsST({className}) {
const [showForm, setShowForm] = useState(false);
const handleClose = (value) => {
  setShowForm(value);
}
    return (
        <Wrap className={className}>
          <h3 className="center homemadeTitle initial orange">Mes réalisations</h3>
          <BoutonNoirST lib="Ajouter une réalisation" onClick={() => { setShowForm(true); }} />
          {showForm === true ?
          ReactDOM.createPortal(
            <WrapFormPop className="form"><FormRealisationST onChangeOpen={handleClose}/></WrapFormPop>,
                    document.getElementById('root')
          ) : null}
          <Cards>
          <CardStyled
            style={{ width: 400 }}
            actions={[
              <FaPen key="edit" />,
              <FaTrashAlt key="setting" />,
              <BsThreeDots key="ellipsis" />,
            ]}
            cover={
              <img
                alt="example"
                src="https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg"
              />
            }
          >
            <Meta
              title="Titre de la réalisation"
              description="Description de la réalisation"
            />
            <Prods>
            <Titre className="prod">Tissus utilisés :</Titre>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
          </Prods>
            {/* <Photos>
            </Photos> */}
          </CardStyled>
          <CardStyled
            style={{ width: 400 }}
            actions={[
              <FaPen key="edit" />,
              <FaTrashAlt key="setting" />,
              <BsThreeDots key="ellipsis" />,
            ]}
            cover={
              <img
                alt="example"
                src="https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg"
              />
            }
          >
            <Meta
              title="Titre de la réalisation"
              description="Description de la réalisation"
            />
            <Prods>
            <Titre className="prod">Tissus utilisés :</Titre>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
          </Prods>
            {/* <Photos>
            </Photos> */}
          </CardStyled>
          <CardStyled
            style={{ width: 400 }}
            actions={[
              <FaPen key="edit" />,
              <FaTrashAlt key="setting" />,
              <BsThreeDots key="ellipsis" />,
            ]}
            cover={
              <img
                alt="example"
                src="https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg"
              />
            }
          >
            <Meta
              title="Titre de la réalisation"
              description="Description de la réalisation"
            />
            <Prods>
            <Titre className="prod">Tissus utilisés :</Titre>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
            <LinkProd>
              <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
              Tissu lycra mat Oeko-Tex uni
            </LinkProd>
          </Prods>
            {/* <Photos>
            </Photos> */}
          </CardStyled>
          </Cards>
        </Wrap>
  );
}
