import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const BtnBlanc = styled.button`
text-decoration: none;
border: none;
text-align: center;
background: #fff;
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
padding: 20px;
width: ${props => props.width || "auto"};
min-width: 180px;
margin: ${props => props.margin || "auto"};
position: relative;
cursor: pointer;
color: ${props => props.theme.blackST};
font-size: 17px;
box-shadow: inset 0 0 0 1px ${props => props.theme.blackST};
transition: all .2s ease-out;
&.disabled {
    pointer-events: none;
    opacity: 0.5;
    box-shadow: inset 0 0 0 1px ${props => props.theme.grey01ST};
    color: ${props => props.theme.grey03ST};
}
&:hover {
box-shadow: inset 0 0 0 2px ${props => props.theme.blackST}, 0 4px 8px rgba(0,0,0,0.3);
color: ${props => props.theme.blackST};
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonBlancBtnST(props) 
{ 

    return <BtnBlanc className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} onClick={props.onClick}>{props.lib}</BtnBlanc>   


}