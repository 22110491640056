import React, {useState} from "react";
import styled from "styled-components";
import { FormConnect,InputStyled } from "../DesignSystem/SelfTissus/Forms";
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import PopErrorST from "../PopupsST/PopError";
import { AiOutlineSearch } from "react-icons/ai";


const IconStyled = styled(AiOutlineSearch)`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  color: #aaa;
  transition:   fill 0.3s ease;
  fill: ${(props) => props.theme.grey05ST};
  height: 30px;
  width: 30px;
`;

export default function FormSearchFaqSelfST({className,placeholder, margin, value, onChange}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState('');
        return(
        <>
        {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Recherche"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        <FormConnect className={[className]} margin={margin}>
            <IconStyled/>
            <InputStyled
            style={{paddingLeft: "40px"}}
            id="exampleSearch"
            name="search"
            placeholder={placeholder && placeholder !== "" ? placeholder : "Rechercher un produit, un tuto, un conseil..."}
            type="search"
            value={value} onChange={onChange} 
            ></InputStyled>
        </FormConnect>
        
        </>
    )
}

