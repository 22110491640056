import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoArrowForwardOutline } from "react-icons/io5";

const Btn = styled.a`
text-decoration: none;
background: transparent;
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
padding: ${props => props.padding || "20px"};
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0 auto"};
position: relative;
cursor: pointer;
color: #000;
font-size: 18px;
font-weight: 400;
transition: all .2s ease-out;
&>svg {
    margin-left: 10px;
    font-size: 20px;
}
  &:hover {
    color: #000;
    transform: scale(1.1);
    text-decoration: none;
    }

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonFlecheST(props) {

    return <Btn className={props.className} margin={props.margin} padding={props.padding} width={props.width} widthMob={props.widthMob} href={props.href}>{props.lib}<IoArrowForwardOutline/></Btn>   


}