import React, {useState, useEffect} from "react";
import styled from "styled-components";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { Badge, Descriptions } from 'antd';
import {Wrap} from "./ElementsMembre";


const Produit = styled.div`
display: flex;
gap: 20px;
align-items: center;
justify-content: flex-start;
& img {
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  border-radius: 8px;
}

@media screen and (max-width: 576px) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
`;

const DescriptionsStyled = styled(Descriptions)`
&>.ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-label:last-child, .ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-content:last-child {
  border-inline-end: 1px solid #A29995 !important;
}
width: 100%;
font-family: 'Assistant', sans-serif;

&>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #f05b320a !important;
  font-size: 16px !important;
}
&>.ant-descriptions-view > table > tbody > tr> td {
  font-size: 15px !important;
  border-inline-end: 1px solid #A29995 !important;
}
`;

const BadgeStyled = styled(Badge)`
&.ant-badge > .ant-badge-status-text {
  font-size: 16px !important;
}
`;

const DescriptionsStyledLignes = styled(Descriptions)`
&>.ant-descriptions-view > table > tbody > tr> th {
background-color: #79b69f1c !important;
}

`;

export default function MembreFacturesST({className, data}) {
 console.log(data);

    return (
      <div>
      <h2 className="center homemadeTitle initial green">Vos factures</h2>
        {
          data && data.map((item, index) => (
          <Wrap className={className}>
          <h3>Facture n° {item?.NumDoc}</h3>
          <DescriptionsStyled key={index} title="" bordered>
            <DescriptionsStyled.Item span={3} label="Date">{item.Date}</DescriptionsStyled.Item>
            <DescriptionsStyled.Item span={3} label="Produits">
              {
                item.Lignes && item.Lignes.map((ligne, index) => {
                  return (
                    <>
                    <DescriptionsStyledLignes key={index} title="" bordered>
                      <DescriptionsStyled.Item span={3} label="Produit">{item?.Lib}</DescriptionsStyled.Item>
                      <DescriptionsStyled.Item label="MontantTTC">{ligne.MontantTTC} €</DescriptionsStyled.Item>
                      <DescriptionsStyled.Item label="PUTTC">{ligne.PUTTC} €</DescriptionsStyled.Item>
                      <DescriptionsStyled.Item label="Quantité">{ligne.Quantite}</DescriptionsStyled.Item>
                    </DescriptionsStyledLignes>
                    <br></br>
                    </>
                  )
                })
              }
            </DescriptionsStyled.Item>
            <DescriptionsStyled.Item span={3} label="MontantTTC">{item.MontantTTC} €</DescriptionsStyled.Item>
            <DescriptionsStyled.Item span={3}>
                <LienNoirST lib={`Télécharger la Facture ${item?.NumDoc}`} href={item?.TokenDL}/>
            </DescriptionsStyled.Item>
        </DescriptionsStyled>
        </Wrap>
          ))
        }
        </div>
  );
}
