import React, {useState,useRef} from "react";
import styled from 'styled-components';
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import BoutonVertST from "../DesignSystem/SelfTissus/BoutonVertLien";
import BoutonVertBtnST from "../DesignSystem/SelfTissus/BoutonVertBtn";
import { BoutonVertSearch } from "../DesignSystem/Commons/Buttons";
import { FadeStyled } from "../DesignSystem/Commons/Containers";
import { AiOutlineSearch } from "react-icons/ai";
import FormSearchST from "../FormsST/FormSearchSelf";

const BandeauWrapper = styled.div`
background-color: ${(props) => props.theme.greenST};
display: flex;
justify-content: center;
flex-wrap: wrap;
`;

const Txt = styled.div`
text-align: center; 
display: block;
margin: auto;
padding: 10px 30px;
`;


export default function NavBlogsST() {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  

  return (
    <div>
      <BandeauWrapper className="ignoreParent">
       <BoutonVertST lib="Tutos" href="/communaute/decouvrez-nos-tutos/" />
       <BoutonVertST lib="Tendances du moment" href="/communaute/tendances/" />
       <BoutonVertBtnST className="search" onClick={handleOpen}><AiOutlineSearch/></BoutonVertBtnST>
       <FadeStyled
        padding="10px 0 10px 0"
        in={open} handleClose={handleClose} >
        <div>
          <FormSearchST placeholder={"Recherche"}/>
        </div>
      </FadeStyled>
      </BandeauWrapper>
     
    </div>
  )
}

