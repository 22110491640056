import React, {useState, useEffect} from "react";
import styled from "styled-components";
import  {FormGroup ,Label, Button,ButtonGroup,Input} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import { TbNeedleThread } from "react-icons/tb";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
const ButtonGroupStyled = styled(ButtonGroup)`
gap: 20px;
`
const Buts = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
max-width: 600px;
gap: 20px;
padding-bottom: 15px;
&>p {
  margin: 0;
  font-size: 15px;
}
&>p>svg {
  height: 28px;
  width: 28px;
  margin-right: 5px;
  color: ${props => props.theme.greenST};
}
`

const ButtonStyled = styled(Button)`
background-color: ${props => props.theme.grey01ST};
color: #fff;
border: none;
border-radius: 5px !important;
max-width: 50px;
padding: 10px;
&.active {
  background-color: ${props => props.theme.redST};

}
`

export default function FormLevelST({data,titre}) {
  const [selected, setSelected] = useState({});


  const saveFormData = (key, value) => {
    console.log("selected",key, value);
  };
    return (
        <Wrap>
            <Txt> 
              <h4 className="initial">{titre}</h4>
              </Txt>
                <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="text"
                        placeholder="Prénom"
                        type="text"
                    />
                    <Label for="exampleEmail">Prénom</Label>
                </FormStyled>
                <FormStyled floating>
                    <InputStyled
                        id="exampleEmail"
                        name="email"
                        placeholder="E-mail"
                        type="email"
                    />
                    <Label for="exampleEmail">E-mail</Label>
                </FormStyled>
                  {data && data.map((item, index) => (
                      <Buts key={index}>
                          <p><TbNeedleThread />{item.Titre}</p>
                          <ButtonGroupStyled>
                            <ButtonStyled
                                outline
                                onClick={() => { setSelected({...selected, [item.ID]: 1}); saveFormData(item.ID, 1); }}
                                active={selected[item.ID] === 1}
                            >
                                OUI
                            </ButtonStyled>
                            <ButtonStyled
                                outline
                                onClick={() => { setSelected({...selected, [item.ID]: 2}); saveFormData(item.ID, 2); }}
                                active={selected[item.ID] === 2}
                            >
                                NON
                            </ButtonStyled>
                            <ButtonStyled
                                outline
                                onClick={() => { setSelected({...selected, [item.ID]: 3}); saveFormData(item.ID, 3); }}
                                active={selected[item.ID] === 3}
                            >
                                NSP
                            </ButtonStyled>
                        </ButtonGroupStyled>
                      </Buts>
                  ))}
               <BoutonNoirST width={"600px"} lib="Soumettre" />
          </Wrap>
  );
}
