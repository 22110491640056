import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import firstSliceReducer from './reducers/firstSliceReducer';
import userReducer from "./reducers/userReducer";
import basketReducer from "./reducers/basketReducer";
import favorisReducer from "./reducers/favorisReducer";
import magasinReducer from "./reducers/magasinsReducer";
import relaisReducer from "./reducers/relaisReducer";


const reducers = combineReducers({

    firstSlice: firstSliceReducer,
    user: userReducer,
    basket: basketReducer,
    favoris: favorisReducer,
    magasin: magasinReducer,
    relais: relaisReducer,
 
});
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['firstSlice', 'user', 'basket', 'favoris', 'magasin', 'relais'],
    blacklist: [],
};
 
const persistedReducer = persistReducer(persistConfig, reducers);
 
 
 
export default configureStore({
    reducer: persistedReducer,
    // middleware: [thunk],
    // devTools: process.env.NODE_ENV !== 'production',
 
});