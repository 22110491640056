import React, {useState,useEffect} from "react";
import axios from "axios";
import styled from 'styled-components';
import { Section, WrapperProds } from "../DesignSystem/SelfTissus/ContainersST";
import { InputStyled,LabelStyled, FormStyled } from "../DesignSystem/SelfTissus/Forms";
import { Card,Badge,Space } from 'antd';
import { FaRegHandPointRight } from "react-icons/fa6";

const CardStyled = styled(Card)`
width: 400px;
/* max-height: 400px; */
min-height: 400px;
@media screen and (max-width: 992px) {
  width: 300px;
}
@media screen and (max-width: 768px) {
  width: 100%;
  min-width: 300px;
  margin: 0 auto;
  max-height: unset;
  min-height: unset;
}
&>.ant-card-body > h2 {
  font-size: 18px !important;
  margin: 0 auto 20px auto;
}
&>.ant-card-cover {
  height: 360px;
}
`;

const SpaceStyled = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
justify-content: center;
align-items: flex-start;
flex-wrap: wrap;

`

const Prod = styled.a`
display: flex !important;
color: ${props => props.theme.greenST} !important;
align-items: center;
justify-content: flex-start;
gap: 10px;
font-size: 14px;
&:hover {
  color: ${props => props.theme.orangeST} !important;
}
`;

const Prods = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
width: 70%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const ProdsSmall = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
gap: 10px;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const FilterProds = styled.div`
width: 20%;
@media screen and (max-width: 768px) {
  display: none;
}
`;
const FilterProds2 = styled.div`
display: flex;
align-items: center;
width: 100%;
gap: 50px;
justify-content: flex-end;
@media screen and (max-width: 768px) {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Image = styled.div`
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`;

const Result = styled.p`
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
`

export default function AteliersListeST() {
const [isLoading, setIsLoading] = useState(true); 
const [dataAteliers, setDataAteliers] = useState([]);
const [dataAteliersNbr, setDataAteliersNbr] = useState(0);
const [filterOption, setFilterOption] = useState('');

const handleFilterChange = (option) => {
  if (typeof option === 'string') {
    setFilterOption(option);
  } else {
    setFilterOption(option.target.value);
  }
};
const getFilteredData = () => {
  let filteredData = [...dataAteliers];
  switch (filterOption) {
    case 'PrixC':
      filteredData.sort((a, b) => a.PUTTC - b.PUTTC);
      break;
    case 'PrixD':
      filteredData.sort((a, b) => b.PUTTC - a.PUTTC);
      break;
      case 'a':
        filteredData?.sort((a, b) => {
          if (!a?.lib) return 1;
          if (!b?.lib) return -1;
          return a.lib.localeCompare(b.lib);
        });
        break;
      case 'z':
        filteredData?.sort((a, b) => {
          if (!a?.lib) return 1;
          if (!b?.lib) return -1;
          return b.lib.localeCompare(a.lib);
        });
      break;
    default:
      break;
  }
  return filteredData;
};

function formatDuration(duration) {
  const [hours, minutes] = duration.split(':');
  return `${parseInt(hours)}h${parseInt(minutes)}min`;
}

useEffect(() => {

  setIsLoading(true);
  axios.post(`/users/atelierliste`,{Limite : 30, OFFSET : 0 })
  .then((response) => {
    setDataAteliers(response?.data?.Ateliers);
    setDataAteliersNbr(response?.data?.Ateliers?.length);
    setIsLoading(false);
  })
  .catch((err) => {
    console.log("err", err);
  });
}, []);

const getColorByDifficulte = (difficulte) => {
  switch (difficulte) {
    case 1:
      return '#13c2c2';
    case 2:
      return '#722ED1';
    case 3:
      return '#EB2F96';
    default:
      return '#000';
  }
};

  return (
      <Section>
         <WrapperProds>
          <FilterProds>
          <ul className="none">
            <Tri>Trier par</Tri>
            <li><Link onClick={() => handleFilterChange('PrixC')}>Prix croissant</Link></li>
            <li><Link onClick={() => handleFilterChange('PrixD')}>Prix décroissant</Link></li>
            <li><Link onClick={() => handleFilterChange('a')}>De A à Z</Link></li>
            <li><Link onClick={() => handleFilterChange('z')}>Z à A</Link></li>
          </ul>
          </FilterProds>
          <Prods>
            <FilterProds2>
            <FormStyled>
                <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={handleFilterChange}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC">Prix croissant</option>
                  <option value="PrixD">Prix décroissant</option>
                  <option value="a">De A à Z</option>
                  <option value="z">Z à A</option>
                </InputStyled>
              </FormStyled>
              <Result>{dataAteliersNbr} atelier(s)</Result>
            </FilterProds2>
            <SpaceStyled>
          {
            dataAteliers && getFilteredData().map((item, i) => {
              let urlProd = item && item?.Produits && item?.Produits[0]?.slug ? item?.Produits[0]?.slug : "";
              return (
                <Badge.Ribbon text={"Difficulté :"+ " "+item?.Difficulte} color={getColorByDifficulte(item?.Difficulte)}>
                <CardStyled
                  onClick={() => window.location.href = item?.slug}
                  key={i}
                  hoverable
                  cover={<Image alt={item?.img?.alt} style={{backgroundImage: `url(https://selftissus.yogan.pro/${item?.img?.url})`}}></Image>}
                >
                  <h2 className="grey center fs23">{item?.lib}</h2>
                  {/* <p className="grey">{item?.Description}</p> */}
                  <p className="uppercase"><span className="gras">Durée:</span>  <span className="uppercase orange fw600">{formatDuration(item?.Duree)}</span></p>
                  <p className="uppercase"><span className="gras">Prix PUHT :</span>  <span className="uppercase orange fw600">{item?.PUHT} €</span></p>
                  <p className="uppercase"><span className="gras ">Prix PUTTC :</span> <span className="uppercase orange fw600">{item?.PUTTC} €</span></p>
                  <ProdsSmall>
                  {item?.Produits && <hr></hr>}
                  {item?.Produits?.map((prod, i) => {
                        return (
                          <Prod href={prod?.slug}><FaRegHandPointRight/>{prod?.lib}</Prod>
                        );
                      }
                    )}
                  </ProdsSmall>
                </CardStyled>
                </Badge.Ribbon>
              );
            })
          }
          </SpaceStyled>
          </Prods>  
        </WrapperProds>
    
      </Section>
  );
}