import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Card } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { InputStyled, FormGroupStyled, LabelStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";

const CardGridStyled = styled(Card.Grid)`
&.nom {
    width: 60%;
    &.mob {
       width: 100%; 
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}

&.quantite{
    width: 25%;
    &.mob {
       width: 70%; 
    }
    @media only screen and (max-width: 786px) {
        width: 70%;
    }
}
&.prix{
    width: 15%;
    &.mob {
       width: 30%; 
    }
    @media only screen and (max-width: 786px) {
        width: 30%;
    }
}
`;

const PanierWrap = styled.div`
width: 100%;
&>.ant-card {
   background: #ffffff75;
   padding: 20px;
}
&>.ant-card > div.ant-card-body > .ant-card-grid {
    display: flex;
    align-items: center;
}
&.mob {
    &>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    display: none;
}
}
&>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    @media only screen and (max-width: 786px) {
        display: none;
    }
}
`;

const Produit = styled.div`
display: flex;
align-items: center;
gap: 30px;`
const Img = styled.img`
width: 100px;
height: 100px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`
const Txt = styled.div`
display: flex;
align-items: center;
gap: 20px;
flex-wrap: wrap;
&>span {
    padding: 0 15px;
    font-size: 20px; 
    color: ${props => props.theme.grey02ST};
    @media only screen and (max-width: 786px) {
        padding: 10px;
    }
}
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
@media only screen and (max-width: 786px) {
    text-align: center;
}
`

const Quantity = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: space-between;
&>.trash {
    font-size: 20px;
    color: ${props => props.theme.grey02ST};
    &:hover {
        color: ${props => props.theme.redST};
        cursor: pointer;
    }
}
` 

const Promo = styled.div`
display: flex;
align-items: center;
gap: 20px;
justify-content: flex-end;
width: 100%;
margin-top: 20px;
`

const Livr = styled.div`
position: relative;
width: 100%;
display: flex;
gap: 5px;
padding:  20px 0;
flex-direction: column;
justify-content: flex-start;
`

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`
const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;




export default function PanierRecapST({className}) {
const basket = useSelector(state => state.basket);
const [quantities, setQuantities] = useState(basket?.items?.map(item => item.quantity));
const [isLoading, setIsLoading] = useState(false);
const [txt, setTxt] = useState("");
const user = useSelector(state => state.user?.user?.Contact); 

console.log("basket", basket);
const totalPrice = basket?.items?.reduce((total, item, index) => {
    const price = item.Prix ?? item?.PUTTC;
        return total + (quantities[index] * price);
    }, 0).toFixed(2);

    return (
            <PanierWrap className={className}>
                <h2 className="center homemadeTitle initial green">Récapitulatif de votre commande</h2>
                <Card bordered={false}>
                <Card.Grid className="pc" hoverable={false} style={{width:"60%"}}>
                <p className="mb0 uppercase">Produit</p>
                </Card.Grid>

                <Card.Grid className="pc"  hoverable={false} style={{width:"25%"}}>
                <p className="mb0 uppercase">Quantité</p>
                </Card.Grid>

                <Card.Grid className="pc" hoverable={false} style={{width:"15%",textAlign:"right"}}>
                <p className="mb0 uppercase right">Total</p>
                </Card.Grid>

                {
                    basket.items.map((item, i) => (
                        <>
                            {item?.mode === "article" ? (
                                <>
                                    <CardGridStyled className={`nom ${className}`}>
                                        <Produit>
                                            <Img src={item?.Photos[0]?.image?.url ? `https://selftissus.yogan.pro/${item?.Photos[0]?.image?.url}` : ""} alt={item?.Photos[0]?.image?.alt || "Product Image"} />
                                            <Txt>
                                                <LienNoirST lib={item?.libArticle || "Nom du produit1"} href={item?.slug || "#"} />
                                                <span>€{item?.Prix || "30,01"}</span>
                                            </Txt>
                                        </Produit>
                                    </CardGridStyled>

                                    <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                        <Quantity>
                                            <FormGroupStyled>
                                                <InputStyled
                                                    id={`quantity-${i}`}
                                                    name="number"
                                                    type="number"
                                                    value={quantities[i]}
                                                    readonly
                                                    disabled
                                                />
                                            </FormGroupStyled>
                                        </Quantity>
                                    </CardGridStyled>

                                    <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                        <Prix className="fs20">€{(quantities[i] * (item?.Prix || 30.01)).toFixed(2)}</Prix>
                                    </CardGridStyled>
                                </>
                            ) : item?.mode === "atelier" ? (
                                <>
                                <CardGridStyled className={`nom ${className}`}>
                                    <Produit>
                                        <Img src={"https://selftissus.yogan.pro"+item?.img?.url} alt={item?.img?.alt || "Product Image"} />
                                        <Txt>
                                            <LienNoirST lib={item?.lib} href={item?.slug} />
                                            <span>€{item?.PUTTC}</span>
                                        </Txt>
                                    </Produit>
                                </CardGridStyled>

                                <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                    <Quantity>
                                        <FormGroupStyled>
                                            <InputStyled
                                                id={`quantity-${i}`}
                                                name="number"
                                                type="number"
                                                value={quantities[i]}
                                                readonly
                                                disabled
                                            />
                                        </FormGroupStyled>
                                    </Quantity>
                                </CardGridStyled>

                                <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                    <Prix className="fs20">€{(quantities[i] * (item?.PUTTC || 30.01)).toFixed(2)}</Prix>
                                </CardGridStyled>
                                </>
                            ) : null}
                        </>
                    ))
                }
                
                <Promo>
                <FormGroupStyled floating>
                    <InputStyled
                        id="exampleNumber"
                        name="number"
                        placeholder="Saissir un code"
                        type="text"
                    />
                    <LabelStyled for="exampleNumber">Code promo ?</LabelStyled>
                    </FormGroupStyled>
                    <BoutonST lib={"Appliquer"}
                            background={props => props.theme.orangeST}
                            width={"300px"}
                            margin={"0"}
                            />
                </Promo>
                <Total className={className}>
                    <Prix className="fs16">Total : &nbsp;<span> €{totalPrice} EUR</span></Prix>
                    <Prix className="fs16">Taxe incluse, frais d’expédition et réductions calculés à l’étape du paiement</Prix>
                </Total>
            </Card>
               
            </PanierWrap>
  );
}
