import React, {useRef,useEffect,useState} from "react";
import styled from 'styled-components';
import { Section } from "../DesignSystem/SelfTissus/ContainersST";
import img from "../../assets/images/img.jpg";
import BoutonTransparentST from "../DesignSystem/SelfTissus/BoutonTransparent";
import picDefault from "../../assets/images/photo.jpg";
import ReactHtmlParser from 'react-html-parser';
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

const Img = styled.div`
flex-basis: 50%;
width: 100%;
max-width: 100%;
height: 100%;
order: 0;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    max-height: 400px;
    height: 400px;
}
`;
const Img22 = styled.img`
@media screen and (max-width: 786px) {
    float: none !important;
    margin: 0 !important;
    max-width: 100% !important;
}
`;

const Wrap = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap: 0;
justify-content: center;
&>div:nth-child(even) {
    &>div.img {
        order: 1;
    }
}
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
    gap: 50px;
    &>div:nth-child(even) {
    &>div.img {
        order: 0;
    }
    }
}
`;

const Txt = styled.div`
position: relative;
padding: 50px;
height: 500px;
overflow: hidden;
&>h2 {
margin: 0 0 20px 0;
}
@media screen and (max-width: 786px) {
    padding: 20px;
    height: auto;
}
`;

const Txt2 = styled.div`
max-height: ${({ expanded, maxHeight }) => (expanded ? 'none' : `${maxHeight}px`)};
overflow: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
position: relative;
&>h2 {
margin: 0 0 20px 0;
}
@media screen and (max-width: 786px) {
    /* max-height: 280px; */
}  
`;

const Inside = styled.div`
flex-basis: 50%;
max-width: 50%;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
gap: 30px;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
}
`;
const Inside2 = styled.div`

`;

const Img2 = styled.img`
flex-basis: 50%;
width: 100%;
max-width: 100%;
order: 0;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    max-height: 400px;
}
`;

const Bloc = styled.div`
width: 100%;
min-height: 500px;
height: 600px;
overflow: auto;
display: flex;
align-items: center;
gap: 0;
justify-content: center;
@media screen and (max-width: 786px) {
    height: auto;
}
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;
const Bloc2 = styled.div`
width: 100%;
margin-bottom: 50px;
&.float-left img {
    float: left;
    margin: 0 30px 30px 0;
    max-width: 35%;
}

&.float-right img {
    float: right;
    margin: 0 0 30px 30px;
    max-width: 35%;
}
`;
const SeeMoreButton = styled.button`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, ${props => props.theme.grey02ST}, ${props => props.theme.greenST});
  border: none;
  padding: 10px;
  text-align: center;
  color: #fff;
  &>.caret-icon {
    animation: bounce 2s infinite;
    font-size: 1.5em;
    margin-left: 10px;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

const SeeMoreButton2 = styled.button`
display: block;
/* position: absolute;
top: 91%; */
width: 50%;
background: linear-gradient(to top, ${props => props.theme.grey02ST}, ${props => props.theme.greenST});
border: none;
padding: 10px;
text-align: center;
color: #fff;
&>.caret-icon {
animation: bounce 2s infinite;
font-size: 1.5em;
margin-left: 10px;
}  
&.retour {
    background: linear-gradient(to top, ${props => props.theme.grey02ST}, ${props => props.theme.grey03ST});   
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
@media screen and (max-width: 786px) {
    display: none;
}
`;


export default function BlocsImgTxtST({data}) {
const [expanded, setExpanded] = useState({});
const contentRefs = useRef([]);
const [showButton, setShowButton] = useState({});
const imageRefs = useRef([]);
const [maxHeight, setMaxHeight] = useState(600);
const txtRef = useRef(null);
const [buttonText, setButtonText] = useState({});
const [buttonClass, setButtonClass] = useState({});

const handleScroll = (index) => {
    const ref = contentRefs.current[index];
    if (ref) {
      if (buttonText[index] === 'Lire la suite') {
        ref.scrollBy({ top: 100, behavior: 'smooth' });
        if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
            setButtonText((prev) => ({ ...prev, [index]: 'Retour' }));
            setButtonClass((prev) => ({ ...prev, [index]: 'retour' }));
        }
      } else {
        ref.scrollTo({ top: 0, behavior: 'smooth' });
        setButtonText((prev) => ({ ...prev, [index]: 'Lire la suite' }));
        setButtonClass((prev) => ({ ...prev, [index]: '' }));
      }
    }
  };

useEffect(() => {
    const newShowButton = {};
    const newButtonText = {};
    const newButtonClass = {};
    contentRefs.current.forEach((ref, index) => {
      if (ref && ref.scrollHeight > 600) { // Compare with fixed height
        newShowButton[index] = true;
        newButtonText[index] = 'Lire la suite';
        newButtonClass[index] = '';
      }
    });
    setShowButton(newShowButton);
    setButtonText(newButtonText);
    setButtonClass(newButtonClass);
  }, [data]);

    return (
        <>
        <Section>
            <Wrap>
                {
                    data && data.map((item, index) => {
                        return (
                            <Bloc key={index}>
                                <Img className="img"
                                    style={{
                                    backgroundImage: `url(${item?.image?.url ? "https://selftissus.yogan.pro/"+item?.image?.url : picDefault})`,
                                    }}
                                    aria-label={item?.image?.alt}
                                />
                                <Inside>
                                    <Txt ref={(el) => (contentRefs.current[index] = el)}>
                                        <h2>{item.Titre}</h2>
                                        <p>{ReactHtmlParser(item.Corps)}</p>
                                        <BoutonTransparentST lib={item?.BTNLib} href={item?.BTNUrl} target={data?.BTNMode ? "_blank" : "_self"}/>
                                    </Txt>
                                    {showButton[index] && (
                                        <SeeMoreButton2 onClick={() => handleScroll(index)} className={buttonClass[index]}>
                                        {buttonText[index] || 'Lire la suite'}
                                        {buttonText[index] === 'Lire la suite' ? (
                                            <FaCaretDown className="caret-icon" />
                                        ) : (
                                            <FaCaretUp className="caret-icon" />
                                        )}
                                        </SeeMoreButton2>
                                    )}
                                </Inside>
                            </Bloc>
                        )
                    })
                }
            </Wrap>
            {/* <Wrap>
            {
                    data && data.map((item, index) => {
                        const floatClass = index % 2 === 0 ? 'float-right' : 'float-left';
                        return (
                            <Bloc2 key={index} className={`clearfix ${floatClass}`}>
                                <Img22
                                    src={"https://selftissus.yogan.pro/"+item?.image?.url}
                                    alt={item?.image?.alt}
                                    ref={(el) => (imageRefs.current[index] = el)}
                                />
                                <Inside2>
                                    <Txt2  
                                    ref={(el) => (contentRefs.current[index] = el)}
                                    expanded={expanded[index]}
                                    maxHeight={maxHeight[index]}>
                                    <h2>{item.Titre}</h2>
                                    <p>{ReactHtmlParser(item.Corps)}</p>
                                    <BoutonTransparentST lib={item?.BTNLib} href={item?.BTNUrl} target={data?.BTNMode ? "_blank" : "_self"}/>
                                    <SeeMoreButton
                                        show={showButton[index] && !expanded[index]}
                                        onClick={() => toggleExpand(index)}
                                        >
                                        Lire la suite<FaCaretDown  className="caret-icon"/>
                                    </SeeMoreButton>
                                    </Txt2>
                                   
                                </Inside2>
                            </Bloc2>
                        )
                    })
                }
            </Wrap> */}
        </Section>
        </>
    )
}