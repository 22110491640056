import React, {lazy, useState} from "react";
import styled, { keyframes } from "styled-components";
import img from "../../assets/images/404.jpg"
import { Section } from "../DesignSystem/SelfTissus/ContainersST";

const Wrapper = styled.div`
width: 100%;
position: relative;
`
const In = styled.div`
width: 100%;
height: 154%;
position: absolute;
top: 0;
left: 0;
background-image: url(${img});
background-position: bottom;
background-size: auto;
background-repeat: no-repeat;
opacity: 0.25;
z-index: -1;
@media only screen and (max-width: 768px) {
  height: 100%;
  background-size: cover;
  background-position: center;
}
`

export default function NotFoundST () {
  return (
   <Section className="ignoreParent rel404" style={{zIndex:1}}>
    <In></In>
    <h1>Ooups ...</h1>
    <h2 className="center">Erreur 404</h2>
    <div>
      <p className="center black">La page que vous cherchez n'existe pas.</p>
      <p className="center black">Vous pouvez retourner à la <a href="/">page d'accueil</a> ou nous contacter si vous avez besoin d'aide.</p>
    </div>
    
    </Section>
  )
}

