import React, {useState} from "react";
import styled from "styled-components";
import { FormGroupStyled2 } from "../DesignSystem/SelfTissus/ElementsST";
import { Buts } from "../DesignSystem/SelfTissus/ContainersST";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import RatingStarsST from "../RatingST/RatingStars";
import  {Label} from "reactstrap";
import { InputStyled,DraggerStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonJauneST from "../DesignSystem/SelfTissus/BoutonJaune";
import BoutonJauneInverseST from "../DesignSystem/SelfTissus/BoutonJauneInverse";
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FaCloudUploadAlt } from "react-icons/fa";
import { ButFermer } from "../DesignSystem/PopUpST";
import { IoCloseCircleOutline } from "react-icons/io5";
const { Dragger } = Upload;

const Note = styled.div`
padding: 20px 0;
`

export default function FormRealisationST({className,placeholder,mode,onChangeOpen}) {
  const props  = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
    const [dataSearch, setDataSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState('');
    const fetchData = () => {
        // setIsLoading(true);
        // axios.post(`/users/recherche`, { Recherche: inputValue })
        //   .then((response) => {
        //     setDataSearch(response.data);
        //     setIsLoading(false);
        //     navigate("/recherche",{ state: { data: response.data,inputValue: inputValue, mode: mode } });
        //   })
        //   .catch((error) => {
        //     setIsLoading(false);
        //     setDataSearch([]);
        //     setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur  : "Une erreur s'est produite. Veuillez réessayer ultérieurement.");
        //     setIsErrorVisible(true);
        //   });
      };
      

    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
      setInputValue(event.target.value);
    };
    return(
      <div className="form" style={{width:"100%",position:"relative"}}>
        <ButFermer onClick={() => onChangeOpen(false)}><IoCloseCircleOutline/></ButFermer>
        {isErrorVisible && <PopErrorST message={txt} titre={"Recherche"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        <DraggerStyled {...props}>
          <p className="ant-upload-drag-icon">
            <FaCloudUploadAlt />
          </p>
          <p className="ant-upload-text">Cliquez ici ou déposez un fichier dans cette zone pour l'envoyer</p>
          <p className="ant-upload-hint">
            Vous pouvez envoyer plusieurs fichiers en même temps.<br></br>
            Ne pas envoyer d'informations confidentielles.
          </p>
        </DraggerStyled>
        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="prenom"
            name="search"
            placeholder={"Prénom"}
            type="text"
            autoComplete
            value={inputValue} onChange={handleChange} 
            />
            <Label for="prenom">Prénom</Label>
        </FormGroupStyled2>
        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="email"
            name="search"
            placeholder={"Email"}
            type="email"
            />
            <Label for="email">Email</Label>
        </FormGroupStyled2>

        <FormGroupStyled2 floating className={className}>
            <InputStyled
            id="realisation"
            name="search"
            placeholder={"Réalisation"}
            type="textarea"
            />
            <Label for="realisation">Réalisation</Label>
        </FormGroupStyled2>
       
        
      <p className="center mt50">En soumettant votre réalisation, vous acceptez les <a href="" target="_blank" rel="nofollow noopener">termes et conditions .........</a></p>
      <Buts>
        <BoutonJauneST lib={"Annuler"} onClick={() => onChangeOpen(false)}/>
        <BoutonJauneInverseST lib={"Soumettre la Réalisation"} onClick={() => {}}/>
      </Buts>
      

    </div>
    )
}

