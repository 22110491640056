import React, {useState, useEffect} from "react";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import CheckoutST from "./Checkout";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
@media only screen and (max-width: 786px) {
    text-align: center;
}
`;

export default function PaiementST({className}) {

  const initialOptions = {
      clientId: "test",
      currency: "USD",
      intent: "capture",
  };
  const styles = {
      disableMaxWidth: false,
  };  

    return (
      <>
      <h2 className="center homemadeTitle initial green">Paiement</h2>
        <Total className={className}>
          <Prix className="fs18">Frais de livraison : <span> €20,00 EUR</span></Prix>
          <Prix className="fs18">Total TTC : <span> €120,00 EUR</span></Prix>
          <Prix className="fs20">Total : <span className="red fw600"> €140,00 EUR</span></Prix>
          <PayPalScriptProvider options={initialOptions} className="paypal">
          <PayPalButtons style={styles} />
      </PayPalScriptProvider>
      </Total>
      </>
  );
}
