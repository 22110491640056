import styled from 'styled-components';
//aussi pour ST
export const PopUpContainer = styled.div`
position: fixed;
z-index: 99999999999;
top: 0px;
left: 0;
background: rgba(0,0,0,0.4);
width: 100%;
height: 100%;
font-size: 100%;
`;

export const PopUp = styled.div`
position: ${props => props.position || "absolute"};
transform: translate(-50%,-50%);
top: 50%;
left: 50%;
overflow: auto;
z-index: 1000;
width: auto;
max-width: 600px;
border-radius: 7px;
/* margin: 100px auto 0; */
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
gap:${props => props.gap};
box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
&.form {
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
}
@media screen and (max-width: 992px) {
  width: 80%;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
    width: 95%;
    height: 80vh;
  }
`;

export const PopUpBottom = styled.div`
position: fixed;
bottom: 0;
right: 0;
overflow: auto;
z-index: 999999;
width: 500px;
z-index: ${props => props.zIndex};
border-radius: 7px;
margin: 100px auto 0;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
gap:${props => props.gap};
box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
@media screen and (max-width: 992px) {
  width: 98%;
  margin: 0 auto;
  max-width: 100%;
  bottom: 0;
  right: 1%;
}
`;

export const PopUpMessagerie = styled.div`
position: absolute;
min-width: 340px;
bottom : ${props => props.bottom};
left:${props => props.left};
z-index: ${props => props.zIndex};
transform: translate(-50%,-50%);
width: auto;
background: #fff;
padding: 0;
border-radius: 7px;
margin: auto;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid ${(props) => props.theme.bleuHP};
@media screen and (max-width: 992px) {
  width: 80%;
  max-width: 100%;
  bottom: 20%;
}
@media screen and (max-width: 768px) {
  width: 95%;
  max-width: 100%;
  bottom: 20%;
}
@media screen and (max-width: 310px) {
  bottom: 21%;
}
`;


export const PopUpTitle = styled.div`
/* line-height: unset !important; */
font: 600 20px 'Assistant', sans-serif;
background-color: ${(props) => props.theme.greenST};
text-decoration: none;
border: none;
width: 100%;
padding: 8px 40px;
color: #fff; 
text-align: center;
text-transform: uppercase;
border-top-left-radius: 2px;
border-top-right-radius: 2px;

`;

export const ButtonPop=styled.a`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
text-decoration: none;
border-radius: 5px; 
border: none; 
background-color: transparent;
padding: 5px 20px;
cursor: pointer;
color: ${props => props.theme.greenST}; 
text-align: left; 
font: 500 17px 'Assistant', sans-serif;
transition: all .2s ease-out;  
&.center {
  justify-content: center;
}
&>svg{
  color: ${props => props.theme.greenST};
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
&:hover {
  text-decoration: none;
  border-color: ${(props) => props.theme.redST};
  color: ${(props) => props.theme.redST};
  &>svg{
    color: ${props => props.theme.redST};
  }
}
&.yellowHover:hover {
  color: ${props => props.theme.jaune};
&>svg{
  color: ${props => props.theme.jaune};
}
}
`

export const ButFermer=styled.button`
position: absolute;
z-index: 1000;
width: auto;
right: 0;
top: -2px;
text-decoration: none;
border: none; 
background-color: transparent;
cursor: pointer;
color: ${props => props.theme.orangeST};
text-align: center; 
font-size: 24px;
transition: all .2s ease-out;  
&.white {
  color: #fff;
  &:hover {
  color: ${(props) => props.theme.orangeST};
}
}
&>svg{
  height: 25px;
  width: 25px;
}
&:hover {
  color: ${(props) => props.theme.greenST};
}

@media screen and (max-width: 576px) {
right: 0px;
top: 5px;
}
`;

export const PopUpContent = styled.div`
color: ${(props) => props.theme.bleuHP};
padding: 0 20px;
`
export const Buttons = styled.div`
display: flex;
padding-bottom: 20px;
`

export const Wrap = styled.div`
/* max-height: calc(100vh - 150px); */
box-sizing: border-box;
overflow: auto;
width: 100%;
display: flex;
flex-direction: column;
gap: 20px;
padding: 15px;
background: rgb(255 255 255);
`

export const WrapFormPop = styled.div`
position: fixed;
z-index: 9999999999;
background: rgba(0, 0, 0, 0.75);
top: 0;
left: 0;
overflow: auto;
z-index: 1000;
width: 100%;
height: 100%;
&>.form {
  max-width: 600px;
  margin: 100px auto 0;
  border-radius: 7px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:${props => props.gap};
  background-color: ${(props) => props.theme.beige02ST};
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}
`