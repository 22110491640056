import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {FaCookieBite} from 'react-icons/fa';
import {ContainerCookies,RowCookies,RowButtons,ColTxt,CookiesButton,CookiesDiv,Icon} from '../DesignSystem/CookiesST'
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import BoutonNoirLnkST from '../DesignSystem/SelfTissus/BoutonNoirLnk';

export default function CookiesBoxST(){
    const [showCookiesBtn, setShowCookiesBtn] = useState(false);
    const [showContainerCookies, setShowContainerCookies] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        };

        const cookieTimestamp = getCookie('cookieTimestamp');
        const cookiesRefused = sessionStorage.getItem('cookiesRefused');

        if (cookieTimestamp || cookiesRefused) {
            setShowContainerCookies(false);
            setShowCookiesBtn(true);
        }
        setIsLoading(false);
    }, []);

    const handleClick = (accepted) => {
        if (accepted) {
            const currentTime = new Date().getTime();
            document.cookie = `cookieTimestamp=${currentTime}; path=/`; // Session cookie
        } else {
            sessionStorage.setItem('cookiesRefused', 'true');
        }
        setShowContainerCookies(false);
        setShowCookiesBtn(true);
        setIsLoading(false);
    };

    const handleCookiesBtnClick = () => {
        setShowContainerCookies(true);
        setShowCookiesBtn(false);
    };
    return(
        <>
         {isLoading && <SpinnerST/>}
         {ReactDOM.createPortal(<ContainerCookies className={showContainerCookies ? 'cookieOn' : 'cookieOff'}>
            <RowCookies>
                <ColTxt lg="12" sm="12">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et tincidunt magna, et tristique nisl. Donec fringilla, turpis sed venenatis convallis, risus erat vulputate risus, sed blandit purus diam condimentum augue. Integer in tempus leo. Praesent vestibulum sem turpis, eget lobortis nisi cursus eu. Pellentesque sollicitudin maximus neque, non dictum sapien posuere scelerisque. Integer finibus neque vel dolor ultricies fermentum. Cras pellentesque purus quis ligula luctus pellentesque.</p>
                    <BoutonNoirLnkST href="/informations-legales/mentions-legales" lib="En savoir plus..."/>
                </ColTxt>
            <RowButtons>
            <CookiesButton className='no' onClick={() => handleClick(false)}>Refuser</CookiesButton>
            <CookiesButton className='yes' onClick={() => handleClick(true)}>Accepter</CookiesButton>
            </RowButtons>
            </RowCookies>
        </ContainerCookies>,
        document.body
        )}
        

        {showCookiesBtn && ReactDOM.createPortal(
            <CookiesDiv onClick={handleCookiesBtnClick}>
                <Icon>
                    <FaCookieBite />
                </Icon>
            </CookiesDiv>,
            document.body
        )}
        </>
        
    )
}

