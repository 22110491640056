import React, {useEffect,useState} from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { FloatButton } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import { MdMenu } from "react-icons/md";
import DrawerMenu from "./DrawerMenuMobST";
import styled from 'styled-components';
import { FaCrown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";
import { AiOutlineArrowUp } from "react-icons/ai";
import DrawerInfoST from '../DrawerInfo/DrawerInfo';
import DrawerElems from './DrawerElemMobST';

const FloatButtonGroupStyled  = styled(FloatButton.Group)`

&>button {
background-color: transparent;
/* width: 300px; */
border-radius: 50px;
margin-left: -260px;
box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
transition: all 0.2s ease-in-out;
  @media only screen and (max-width: 768px) {
  margin-left: -160px;
    }
  &>.ant-float-btn-body {
    background-color: #000;
    width: 300px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    @media only screen and (max-width: 768px) {
      width: 200px;
    }
    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
      background-color: #000;
    }
    &>.ant-float-btn-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    @media only screen and (max-width: 768px) {
      gap:5px;
    }
    }
    &>div>.ant-float-btn-icon  {
    width: 25px !important;
    font-size: unset !important;
    }
    &>div>.ant-float-btn-icon > svg {
    color: #fff;
    font-size: 25px;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
    }
    &>.ant-float-btn-content >.ant-float-btn-description {
    display: flex;
    gap: 10px;
    font-size: 20px;
    color: #fff;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }
    }
  }
}
`

const FloatButtonGroupStyled2  = styled(FloatButton.Group)`
left: 25px;
&>button {
  box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
  transition: all 0.2s ease-in-out;
  &>.ant-float-btn-body {
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.theme.redST};
    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
      background-color: ${props => props.theme.redST};
    }
    &>div>.ant-float-btn-icon  {
    width: 25px !important;
    font-size: unset !important;
    }
    &>div>.ant-float-btn-icon > svg {
    color: #fff;
    font-size: 25px;
    }
  }
  
}
`

const ButtonsVolants_ST = ({showBtnMenu, scrollValue, data}) => {
const [visible, setVisible] = useState(false);
const [show, setShow] = useState(false);

  const toggleDrawer = () => {
    setVisible(!visible);
  };
  // useEffect(() => {
  //   axios.get(`https://hpv6.yogan.pro/menuhaut?{"CleCtrl":"TaTaYoYo","act":"MenuHaut"}`).then(res => {
   
  //     setDataMenuHaut(res.data?.MenuHaut ? res.data.MenuHaut : []);
  //   }
  //   ).catch(err => {
  //     console.log(err);
  //   }
  //   );
  // }
  // ,[]);

    return (
      <>
     <FloatButtonGroupStyled shape="circle">
      <FloatButton
        id="txt"
        icon={<FaCrown  />}
        description="Votre fidélité récompensée"
        onClick={() => {
          setShow(true);
        }}
      />
    </FloatButtonGroupStyled>

    {/* <DrawerMenu dataMenu={dataMenu} visible={visible} onClose={toggleDrawer} /> */}
     <FloatButtonGroupStyled2 shape="circle">
     {showBtnMenu && <FloatButton
        icon = {<MdMenu  />}
        onClick={toggleDrawer}
      />}
      <FloatButton.BackTop 
      icon={<AiOutlineArrowUp />}
      visibilityHeight={100} />
    </FloatButtonGroupStyled2>
    {/* <DrawerMenu dataMenu={dataMenu} onClose={toggleDrawer} /> */}
    <DrawerElems visible={visible} onClose={toggleDrawer} data={data} elem='menu'/>
    <DrawerInfoST visible={show} onClose={() => setShow(false)} />
  </> 
       
    );
};

export default ButtonsVolants_ST;