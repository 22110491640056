import React, { lazy, Suspense } from "react";
import PageLoading from "../components/DesignSystem/PageLoading";
import { Container } from "../components/DesignSystem/SelfTissus/ContainersST";
import FormConnexionST from "../components/FormsST/FormConnexion";
import EspaceMembreST from "../components/Membre/EspaceMembre";
import { useSelector } from "react-redux";

const Footer1ST = lazy(() => import("../components/FooterST/Footer1_ST"));
const Footer2ST = lazy(() => import("../components/FooterST/Footer2_ST"));
const Footer3ST = lazy(() => import("../components/FooterST/Footer3_ST"));
const Footer4ST = lazy(() => import("../components/FooterST/Footer4_ST"));

export default function MembreST() {
  const user = useSelector(state => state.user?.user?.Contact);
  console.log("userPgMembre", user);
  return (
  
    <Suspense fallback={<PageLoading/>}>
    <Container>
      <FormConnexionST/>
      {user && <EspaceMembreST/>}
    </Container>
    <Footer1ST />
    <Footer2ST />
    <Footer3ST />
    <Footer4ST />
    </Suspense>
    
  );
}

