import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { AiOutlinePoweroff } from "react-icons/ai";

const Btn = styled.button`
text-decoration: none;
border: none;
background: transparent;
display: inline-block; 
padding: 8px;
width: ${props => props.width || "240px"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;
color: ${props => props.theme.grey03ST};
font-size: 19px;
font-weight: 600;
text-transform: uppercase;
transition: all .2s ease-out;
&.flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
&> span {
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 15px; 
}
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.redST};
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonDeconnexiontST(props) {

    return <Btn className={props.className} 
    margin={props.margin} width={props.width} 
    widthMob={props.widthMob} onClick={props.onClick}
    >
    <span><AiOutlinePoweroff/>{props.lib}</span></Btn>   


}