// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    relais: null,
};

const relaisReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_RELAIS":
            return {
                ...state,
                relais: {
                    ID: action.payload.ID,
                    Nom: action.payload.Nom,
                    Adresse1: action.payload.Adresse,
                    Adresse2: action.payload.Adresse2,
                    Adresse3: action.payload.Adresse3,
                    CP: action.payload.CP,
                    Ville: action.payload.Ville,
                }
            };
        case "DELETE_RELAIS":
            return {
                ...state,
                relais: null
            };
        default:
            return state;
    }
};

export default relaisReducer;