import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { AiOutlineShoppingCart} from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { Card } from 'antd';
import { TxtInfo } from "../DesignSystem/SelfTissus/ElementsST";
import picDefault from "../../assets/images/photo.jpg";
import {Badge} from 'antd';

const Wrap = styled.div`
display: flex; 
flex-wrap: wrap;
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
&.mob {
    flex-direction: column; 
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const BadgeStyled = styled(Badge)`
  .ant-badge-status-dot {
    width: 17px !important;
    height: 17px !important;
    margin-right: 5px;
  }
  `;

const Img = styled.img`
width: 150px;
height: 150px;
display: block;
margin: auto;
`;

const Act = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
font-size: 13px;
&.black {
    color: ${(props) => props.theme.blackST};
}
&.red {
    color: ${(props) => props.theme.redST};
}
&.disabled {
    pointer-events: none;
    color: ${(props) => props.theme.grey09ST};
    cursor: default !important;

    & > * {
      cursor: default !important;
    }
  }
&:hover {
    opacity: 0.8;
}
`;

const CardStyled = styled(Card)`
&>.ant-card-body {
    cursor: default;
}
&.disabled-parent {
    &>.ant-card-actions > li:nth-child(1) > span {
        cursor: default !important;
    }
    &>.ant-card-actions > li:nth-child(1) {
        cursor: default !important;
    }
}
`;

const Flex = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
margin: auto;
padding: 20px;
`;

const Stock = styled.div``;

export default function FavorisST({className}) {
const dispatch = useDispatch();
const user = useSelector(state => state.user?.user?.Contact); 
const basket = useSelector(state => state.basket);
const favoris = useSelector(state => state.favoris);
const mag = useSelector(state => state.magasin);
const [data, setData] = useState();
const [stockData, setStockData] = useState({});
const [dataMagNom, setDataMagNom] = useState();
const [isLoading, setIsLoading] = useState(false);
const [changeMag, setChangeMag] = useState(false);
const idMagasinLS = mag?.magasin?.ID;

  useEffect(() => {
    const fetchStockData = async () => {
      setIsLoading(true);
      const newStockData = {};
      const requests = favoris?.items?.map((item) => {
        if (item?.mode === "article") {
          return axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: idMagasinLS })
            .then((response) => {
              newStockData[item.ID] = {
                EnStock: response.data?.EnStock,
                Magasin: response.data?.IDMagasin === "2" || response.data?.IDMagasin === "1" ? '' : response.data?.Magasin,
              };
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
        return null;
      });

      await Promise.all(requests);
      setStockData(newStockData);
      setIsLoading(false);
    };

    fetchStockData();
  }, [favoris, idMagasinLS, changeMag]);

const ajoutPanier = (article, mode) => {
    const existingItem = basket.items.find(item => item.ID === article.ID);
    if (existingItem) {
      dispatch({
        type: "UPDATE_BASKET",
        payload: {
          id: article.ID,
          quantity: existingItem.quantity + 1
        }
      });
    } else {
      dispatch({
        type: "ADD_TO_BASKET",
        payload: { ...article, quantity: 1,mode : mode },
      });
    }
    dispatch({
    type: "DELETE_FAVORIS",
    payload: article.ID,
    });
    window.location.href = "/panier";
}

const deleteFavoris = (article, mode) => {
    dispatch({
    type: "DELETE_FAVORIS",
    payload: article.ID,
    });
}

    return (
        <Wrap className={className}>
            {
                className !== "mob" &&
                <Flex>
                {!user && <TxtInfo className="column">
                    <p>Votre projet est enregistré sur votre navigateur. Connectez-vous ou créer votre compte pour retrouver votre projet couture ultérieurement.</p>
                    <LienNoirST lib={"Connectez-vous"} href="/membre"/>
                    <LienNoirST lib={"Créez mon compte"} href="/membre"/>
                </TxtInfo> }
               
                <h2 className="center homemadeTitle initial green">Mon projet couture</h2>
                </Flex>
            }
            {
                favoris?.items?.map((item, i) => (
                    item?.mode === "article" ? (
                        <CardStyled
                            key={item.ID}
                            hoverable
                            cover={
                                <img 
                                    alt={item?.Photos[0]?.image?.alt || ""} 
                                    src={item?.Photos[0]?.image?.url || ""} 
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = picDefault;
                                    }}
                                    loading="lazy"
                                    onClick={() => window.location.href = item?.slug}
                                />
                            }
                            style={{ width: 350 }}
                            className={stockData[item.ID]?.EnStock > 0 ? '' : 'disabled-parent'}
                            actions={[
                                <Act className={`black ${stockData[item.ID]?.EnStock > 0 ? '' : 'disabled'}`} onClick={() => ajoutPanier(item,"article")}><AiOutlineShoppingCart key="cart" title="add"/>Ajouter au panier</Act>,
                                <Act className="red" onClick={() => deleteFavoris(item,"article")}><FaTrashAlt key="delete" title="delete"/>Supprimer</Act>,
                            ]}
                        >
                            {stockData[item.ID]?.EnStock > 0 ? (
                                <Stock><BadgeStyled status="success" />En stock</Stock>
                                ) : (
                                <Stock><BadgeStyled status="error" />En rupture</Stock>
                            )}
                            <h2 className="small initial left">{item?.libArticle}</h2>
                            <p>€{item?.Prix}</p>
                        </CardStyled>
                    ) : item?.mode === "atelier" ? (
                        <CardStyled
                        key={item.ID}
                        hoverable
                        cover={
                            <img 
                                alt={item?.img.alt || ""} 
                                src={item?.img?.url || ""} 
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = picDefault;
                                }}
                                loading="lazy"
                                onClick={() => window.location.href = item?.slug}
                            />
                        }
                        style={{ width: 350 }}
                        actions={[
                            <Act className="black" onClick={() => ajoutPanier(item,"atelier")}><AiOutlineShoppingCart key="cart" title="add"/>Ajouter au panier</Act>,
                            <Act className="red" onClick={() => deleteFavoris(item,"atelier")}><FaTrashAlt key="delete" title="delete"/>Supprimer</Act>,
                        ]}
                        >
                        <h2 className="small initial left">{item?.lib}</h2>
                        <p>€{item?.PUTTC}</p>
                        </CardStyled>
                    ) : null
                ))
            }
        </Wrap>
  );
}
