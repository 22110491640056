import React, {useState, useEffect} from "react";
import styled from "styled-components";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { Badge, Descriptions } from 'antd';
import {Wrap} from "./ElementsMembre";


const Produit = styled.div`
display: flex;
gap: 20px;
align-items: center;
justify-content: flex-start;
& img {
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  border-radius: 8px;
}

@media screen and (max-width: 576px) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
`;

const DescriptionsStyled = styled(Descriptions)`
&>.ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-label:last-child,.ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-content:last-child {
  border-inline-end: 1px solid #A29995;
}
&.item {
  width: 95%;
  &>.ant-descriptions-view  {
}
  &>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #79b69f1c !important;
  }
}
width: 100%;
font-family: 'Assistant', sans-serif;
&>.ant-descriptions-view  {
}
&>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #f05b320a !important;
  font-size: 17px !important;
}
&>.ant-descriptions-view > table > tbody > tr> td {
  font-size: 16px !important;
}
`;

const BadgeStyled = styled(Badge)`
&.ant-badge > .ant-badge-status-text {
  font-size: 16px !important;
}
`;

const items = [
  {
    key: '1',
    label: 'Produit',
    span: 3,
    children: (
      <Produit>
         <img
            width={120}
            alt="logo"
            src="https://selftissus.myshopify.com/cdn/shop/files/atelier-vanity.jpg?v=1698416275"
          />
        <LienNoirST lib={"Nom du produit"} margin={"0"}></LienNoirST>
      </Produit>
    ),
  },
  {
    key: '7',
    label: 'Prix',
    children: '€30.00',
  },
  {
    key: '8',
    label: 'Réduction',
    children: '€2.00',
  },
  {
    key: '9',
    label: 'Total',
    children: '€28.00',
  },
];
const items2 = [
  {
    key: '1',
    label: 'Produit',
    span: 3,
    children: (
      <Produit>
         <img
            width={120}
            alt="logo"
            src="https://selftissus.myshopify.com/cdn/shop/files/atelier-vanity.jpg?v=1698416275"
          />
        <LienNoirST lib={"Nom du produit"} margin={"0"}></LienNoirST>
      </Produit>
    ),
  },
  {
    key: '7',
    label: 'Prix',
    children: '€30.00',
  },
  {
    key: '8',
    label: 'Réduction',
    children: '€2.00',
  },
  {
    key: '9',
    label: 'Total',
    children: '€28.00',
  },
];

const total = [
  {
    key: '4',
    label: 'Date de la commande',
    children: '2018-04-24 18:00:00',
    span: 3,
  },
  
  {
    key: '7',
    label: 'Prix',
    children: '€30.00',
  },
  {
    key: '8',
    label: 'Réduction',
    children: '€2.00',
  },
  {
    key: '9',
    label: 'Total',
    children: '€28.00',
  },
  {
    key: '2',
    label: 'Mode de paiement',
    children: 'PayPal',
    span: 3,
  },
  {
    key: '6',
    label: 'Statut',
    children: <BadgeStyled status="success" text="Livré" />,
    span: 3,
  },
  {
    key: '10',
    label: 'Adresse de livraison',
    span: 3,
    children: (
      <>
        Nom + Prénom
        <br />
        1455 Boulevard de l'Université, Sherbrooke, QC, J1K 2R1
        <br />
        58850, New York
      </>
    ),
  },
  {
    key: '10',
    label: 'Facture',
    span: 3,
    children: (
      <LienNoirST lib={"Télécharger la Facture n° 0"}>
      </LienNoirST>
    ),
  },
];



export default function CommandeFicheST({className}) {
 

    return (
      <>
      <h2 className="center homemadeTitle initial green">Vos commandes</h2>
      
        <Wrap className={className}>
          <h3>Commande n° 0</h3>
          <DescriptionsStyled title="" bordered items={total} />
          <DescriptionsStyled className="item" title="" bordered items={items} />
          <DescriptionsStyled className="item" title="" bordered items={items2} />
          
        </Wrap>
        </>
  );
}
