import React from "react";
import { Router } from "@reach/router";
import Dispatcher from '../src/pages/dispatcher'; 
import Panier from "./pages/Panier"
import Membre from "./pages/Membre"
import Niveau from "./pages/Niveau"
import Favoris from "./pages/Favoris"
import Commande_FicheST from "./pages/CommandeFiche"
import CommandeST from "./pages/Commande"
import Maintenance from "./pages/Maintenance"
import RechercheST from "./pages/Recherche";

export default function RouterDynamique() {
  if (typeof window !== 'undefined') {
    return (
      <Router>
        <CommandeST path="/commande" />
        <Commande_FicheST path="/commandeFiche" />
        <RechercheST path="/recherche" />
        <Panier path="/panier" />
        <Membre path="/membre" />
        <Niveau path="/niveau" />
        <Favoris path="/favoris" />
        <Maintenance path="/maintenance" />
        <Dispatcher  path="*"  key={Math.random}/>
       
      </Router>
    );
  } else {
    return null;
  }
}