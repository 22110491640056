import React from "react";
import styled from "styled-components";
import picDefault from "../../assets/images/photo.jpg";

const Wrapper = styled.div`
width: 70%;
display: block;
margin: 0 auto;
border: none !important;

&>img {
  width: auto;
  display: block;
  margin: auto;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
}

@media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export default function ImageST({data}) {
    return (
        <Wrapper>
            <img onError={(e) => {
            e.target.onerror = null;
            e.target.src = picDefault;
            }} loading="lazy" src={data && data?.Data && data?.Data[0].image?.Data64 ? data?.Data[0].image?.Data64 : picDefault} alt={data && data?.Data && data?.Data[0]?.ImgAlt ? data?.Data[0]?.ImgAlt : ""}/>
        </Wrapper>
      
    )
}
