import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineHeart,AiFillHeart  } from "react-icons/ai";


const beatingHeart = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1.1);
  }
`;

const In = styled.button`
position: absolute;
z-index: 999999;
border: none;
background-color: transparent;
width: 40px;
height: 25px;
right: -90px;
top: 10px;
&>svg{
    font-size: 30px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    fill: ${(props) => props.theme.redST};
    &.animated {
        animation: ${beatingHeart} 1s infinite;
    }
    
    &:hover {
        fill: ${(props) => props.theme.redST};
        transform: scale(1.1);
        transition: all 0.3s;
    }
}
@media screen and (max-width: 786px) {
  right: -5px;
  top: 20px;
}

`;


export default function FavoriHeartST({isFav, onClick}) {
  const [isFavourite, setIsFavourite] = useState(isFav);
  useEffect(() => {
    setIsFavourite(isFav);
  }, [isFav]);
  const handleClick = () => {
    setIsFavourite(!isFavourite);
    if (onClick) {
      onClick();
    }
  };
  return (
  
   <In onClick={handleClick}>
    {isFavourite ? (
        <AiFillHeart />
      ) : (
        <AiOutlineHeart  className="animated"/>
      )}
   </In>
    
  );
}

