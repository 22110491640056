import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Button, Steps } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { AiOutlineEnvironment,AiOutlineUser,AiOutlineEuro,AiOutlineSolution } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import PanierRecapST from "./PanierRecap";
import Paiement from "./Paiement";
import FormConnexionST from '../FormsST/FormConnexion';
import Adresse from "./Adresse";
import { FaArrowLeft,FaArrowRight  } from "react-icons/fa";
import LivraisonST from './Livraison';
import { useSelector, useDispatch } from "react-redux";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const StepsStyled = styled(Steps)`
&.user {
  width: 100%;
}
width: 40%;
@media only screen and (max-width: 768px) {
  width: 100%;
}
font-family: 'Assistant', sans-serif;
flex-wrap: wrap;
@media only screen and (max-width: 768px) {
  align-items: flex-start;
}
&.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item-wait > div > div.ant-steps-item-icon >span > svg {
  fill: ${props => props.theme.grey01ST} !important;
}
&.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: ${props => props.theme.orangeST} !important;
}
&.ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  padding: 35px 0 0px;
}
&.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: ${props => props.theme.orangeST} !important;
}
&>.ant-steps-item {
}
&>.ant-steps-item> div {
    display: flex;
    align-items: center;
    min-width: 130px;
    @media only screen and (max-width: 768px) {
        min-width: unset;
        align-items: stretch;
    }
    &:hover { 
        &>div >.ant-steps-item-title {
            color: ${props => props.theme.blackST} !important;
        }
        
    }
    &>.ant-steps-item-icon > span {
        color: ${props => props.theme.greenST} !important;
    }
}
`;

const WrapStep = styled.div`
width: 100%;
margin-top: 20px;
`;

const ButtonStyled = styled(Button)`
background-color: ${props => props.theme.greenST};
border: none;
color: #fff;
&>svg {
    margin-bottom: 3px;
}
&:hover {
    opacity: 0.8;
    color: ${props => props.theme.redST} !important;
}
`;




export default function CheckoutST({className}) {
    const [current, setCurrent] = useState(0);
    const user = useSelector(state => state.user?.user?.Contact);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [blockSteps, setBlockSteps] = useState(false);
console.log("isTermsAccepted",isTermsAccepted);
    const handleCheckboxChange = (e) => {
      setIsTermsAccepted(e.target.checked);
    };

    const handleNotInStockChange = (notInStock) => {
      setBlockSteps(notInStock);
    };
    const alwaysVisibleSteps = [
      {
        title: 'Récapitulatif',
        content: <PanierRecapST/>,
        icon: <AiOutlineSolution />,
      },
    ];
    
    const conditionalSteps = user ? [
      {
        title: 'Livraison',
        content: <LivraisonST onNotInStockChange={handleNotInStockChange}/>,
        icon: <MdOutlineLocalShipping />,
      },
      {
        title: 'Adresse',
        content: <Adresse 
          isTermsAccepted={isTermsAccepted}
          handleCheckboxChange={handleCheckboxChange}
        />,
        icon: <AiOutlineEnvironment />,
        disabled: !blockSteps,
      },
      {
        title: 'Paiement',
        content: <Paiement />,
        icon: <AiOutlineEuro />,
        disabled: !isTermsAccepted,
      },
    ] : [
      {
        title: 'Connexion',
        content: <FormConnexionST />,
        icon: <AiOutlineUser />,
      },
    ];
    const next = () => {
      setCurrent(current + 1);
    };
  
    const prev = () => {
      setCurrent(current - 1);
    };

    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
      };
  
      const steps = [...alwaysVisibleSteps, ...conditionalSteps];

      const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon,disabled: item.disabled  }));

    return (
        <Wrap className={className}>
            {/* <LienNoirST className="right" width={"100%"} lib={"Continuer les achats"} /> */}
            <StepsStyled current={current} items={items} onChange={onChange} className={user ? "user" : ""}/>
                <WrapStep>{steps[current].content}</WrapStep>
                <div style={{ marginTop: 24 }}>
                {current > 0 && (
                    <ButtonStyled style={{ margin: '0 8px' }} onClick={() => prev()}>
                        <FaArrowLeft/>
                    </ButtonStyled>
                    )}
                    {current < steps.length - 1 && (
                    <ButtonStyled onClick={() => next()} 
                    disabled={
                      (current === steps.length - 2 && !isTermsAccepted) || 
                      ((current === steps.length - 3 || current === steps.length - 2) && !blockSteps)
                    }
                    >
                    <FaArrowRight/>
                    </ButtonStyled>
                    )}
                    {/* {current === steps.length - 1 && (
                    <ButtonStyled type="primary" onClick={() => message.success('Processing complete!')}>
                        <FaCheck/>
                    </ButtonStyled>
                    )} */}
                </div>
        </Wrap>
  );
}
