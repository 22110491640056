import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FormGroup, Label } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import BoutonFavouriteST from "../DesignSystem/SelfTissus/BoutonFavourite";
// import img from "../../assets/images/consigne-tri_213x.avif";
import {Badge} from 'antd';
import {WrapFormPop} from "../DesignSystem/PopUpST";
import PopPanierST from "../PopupsST/PopPanier";
import { AiOutlineEnvironment } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import LienNoirBtnUnderlineST from "../DesignSystem/SelfTissus/LienNoirBtnUnderline";
import PopLocalisationST from "../PopupsST/PopLocalisation";
import PopErrorST from "../PopupsST/PopError";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import FavoriHeartST from "../Favoris/FavoriHeart";
import ShareST from "../Share/ShareST";



const Wrap = styled.div`
padding-left: 40px;
@media screen and (max-width: 786px) {
    padding-left: 0;
}
`;

const StyledList = styled.ul`
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0;
    &>li{
      margin: 0 0px 0px 30px;
    }
`;

const Color = styled.div`
display: flex;
gap: 10px;
margin: 20px 0;
flex-wrap: wrap;
`;
const Small = styled.img`
cursor: pointer;
width: 30px;
height: 30px;
border-radius: 50%;
&.selected {
  border: 3px solid ${(props) => props.theme.orangeST};
}
`;

const Prices = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 18px;
gap: 20px;
`
const Old = styled.span`
text-decoration: line-through solid 2px ${(props) => props.theme.redST};
`
const New = styled.span`
font-weight: 700;
font-size: 28px;
`
const TxtSmall = styled.div`
color: ${(props) => props.theme.grey09ST};
font-size: 14px;
`;

const BadgeStyled = styled(Badge)`
  .ant-badge-status-dot {
    width: 17px !important;
    height: 17px !important;
    margin-right: 5px;
  }
  `;


const Buts = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
justify-content: flex-start;
align-content: flex-start;
flex-wrap: wrap;
`;
const Quantity = styled.div`
position: relative;
padding: 20px 0;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`;

const Prix = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
font-size: 20px;
font-weight: 700;
span {
  color: ${(props) => props.theme.redST};
  font-size: 24px;
}
`;

const Details = styled.div`
padding: 20px 0;
&>* {
  color: ${props => props.theme.grey06ST}; 
}
`;

const Livraison = styled.div`
display: flex;
gap: 40px;
flex-wrap: wrap;
`;
const Livr = styled.div`
position: relative;
display: flex;
flex-direction: column;
gap: 20px;
padding: 10px;
border: solid 1px ${(props) => props.theme.grey01ST};
box-shadow: 0 0 5px rgba(0,0,0,0.1);
min-width: 300px;
&>.in {
  display: flex;
  align-items: center;
  gap: 10px;
  &>svg {
    height: 25px;
    width: 25px;
  }
}
@media screen and (max-width: 786px) {
  min-width: 100%;
}
`;
const Stock = styled.div``;

const Message = styled.span`
position: absolute;
bottom: 10px;
color: ${(props) => props.theme.orangeST};
font-size: 14px;
`;

export default function ArticleDescST({data}) {
  const dispatch = useDispatch();
  const basket = useSelector(state => state.basket);
  const favoris = useSelector(state => state.favoris);
  const mag = useSelector(state => state.magasin);
  const [isFav, setIsFav] = useState(false);
  const idMagasinLS = mag?.magasin?.ID;
  const [quantity, setQuantity] = useState(data?.Unite === 1 ? 1 : data?.Unite === 2 ? 0.5 : 1);
  const unitPrice = data?.Prix; 
  const [selected, setSelected] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [changeMag, setChangeMag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataStock, setDataStock] = useState([]);
  const [dataStockWeb, setDataStockWeb] = useState([]);
  const [dataMagNom, setDataMagNom] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [showStockMessage, setShowStockMessage] = useState(false);
  const existingItem = favoris.items.find(item => item.ID === data.ID);
  const parsedQuantity = parseFloat(quantity);
  const found = !!existingItem;
  useEffect(() => {
    setIsFav(found);
  }, [found]);

  const handleSelect = (id) => {
    setSelected(prev => prev === id ? null : id);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    if (newQuantity > dataStock) {
      setShowStockMessage(true);
      return;
    }
    setShowStockMessage(false);
    setQuantity(newQuantity);
  };

  const totalPrice = (quantity * unitPrice).toFixed(2);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/stock`, { IDArticle: data?.ID, IDMagasin: idMagasinLS })
      .then((response) => {
        setIsLoading(false);
        setDataStock(response.data?.EnStock);
        setDataMagNom(response.data?.IDMagasin === "2" || response.data?.IDMagasin === "1" ? '' : response.data?.Magasin);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }, [changeMag,idMagasinLS]);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/stock`, { IDArticle: data?.ID, IDMagasin: 2 })
      .then((response) => {
        setIsLoading(false);
        setDataStockWeb(response.data?.EnStock);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }, []);

  const choixLivrDomicile = () => {
    dispatch({
      type: "SET_MAGASIN",
      payload: {
        ID: 2,
        Nom: "WEB"
      }
    });
    setTxt("Votre choix a bien été pris en compte");
    setIsErrorVisible(true);
  };

  const panierAjout = (article) => {
    const existingItem = basket.items.find(item => item.ID === article.ID);
    if (existingItem) {
      dispatch({
        type: "UPDATE_BASKET",
        payload: {
          id: article.ID,
          quantity: parseFloat(existingItem.quantity) + parsedQuantity
        }
      });
    } else {
      dispatch({
        type: "ADD_TO_BASKET",
        payload: { ...article, quantity: parsedQuantity, mode: "article" },
      });
    }

    setShowForm(true);
    setTriggerEffect(true);
  };

  const handleFavouriteChange = (newFavouriteState,article) => {
    setIsFav(newFavouriteState);
    favorisAjout(article,newFavouriteState);
  };

  const favorisAjout = (article,isFavF) => {
    setIsFav(isFavF)
    if (!existingItem) {
      dispatch({
        type: "ADD_TO_FAVORIS",
        payload: { ...article, quantity: 1, mode : "article" },
      });
    }  else {
      dispatch({
        type: "DELETE_FAVORIS",
        payload: article.ID,
      });
    }
  }

  return (
  <>
  {isErrorVisible && <PopErrorST message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
  <FavoriHeartST isFav={isFav} onClick={() => handleFavouriteChange(!isFav,data)}/>
  <ShareST shareUrl={data?.slug} title="" className={""} media={data?.Photos[0]?.image?.url}/>
   <Wrap className="desc">
    <h1 className="left">{data?.libArticle}</h1>
    {/* <Ref>Ref 123400</Ref> */}
    <StyledList>
      {data?.Laize>0 ? <li><b>Laize : </b>{data?.Laize} cm</li> : ""}
      {/* <li><b>Caractéristiques : </b> Respirant  </li> */}
      {/* <li><b>Composition : </b> 100% coton  </li> */}
      {data?.Grammage > 0 ? <li><b>Grammage : </b>{data?.Grammage ? data?.Grammage * 1000 : 0} g/m²</li> : ""}
    </StyledList>

    {data && data?.Articles?.length > 1 && (
      <Color>
        {data?.Articles.map((article, index) => {
          const firstPhoto = article?.Photos && article?.Photos?.length > 0 ? article?.Photos[0] : null;
          if (!firstPhoto) return null;

          return (
            <a href={article?.slug} title={article?.libArticle} key={index}>
              <Small
                id={article?.ID}
                onClick={() => handleSelect(article?.ID)}
                // className={selected === article?.ID ? "selected" : ""}
                className={article.slug.replace(/\/$/, '') === window.location.pathname.replace(/\/$/, '') ? 'selected' : ''}
                src={firstPhoto.image.url}
              />
            </a>
          );
        })}
      </Color>
    )}

    <Prices>
    {/* <Old>12,50€</Old> */}
    <New>{data?.Prix +"€"}</New>
   
    </Prices>
    <TxtSmall>Taxes incluses.</TxtSmall>
    <hr></hr>
    {data?.Descriptif && <Details>
      {ReactHtmlParser(data?.Descriptif)}
    </Details>}
    {/* <a href="https://quefairedemesdechets.ademe.fr/"><Img src={img} alt="Consigne de tri"/></a> */}
    <Buts>
      <Quantity>
        <FormGroup floating>
          <InputStyled
             id="prix"
             name="number"
             placeholder="Quantité"
             type="number"
             value={quantity}
             onChange={handleQuantityChange}
             min={data?.Unite === 1 ? "1" : data?.Unite === 2 ? "0.5" : "1"}
             step={data?.Unite === 1 ? "1" : data?.Unite === 2 ? "0.1" : "1"}
             className={showStockMessage ? 'info' : ''}
          />
            <Label for="prix">
            Quantité
            </Label>
          </FormGroup>
          {showStockMessage && <Message>Stock insuffisant</Message>}
          <Prix>Prix total : <span>{totalPrice} €</span></Prix>
        </Quantity>
        
        <Livraison>
          <Livr>
            {isLoading && <SpinnerInlineST/>}
            <div className="in red fs18"><AiOutlineEnvironment/>Click & Collect</div>
            {dataMagNom && <div className="in red fs16">{dataMagNom}</div>}
            {dataStock>0 ? <Stock><BadgeStyled status="success" />En stock</Stock> : <Stock><BadgeStyled status="error"/>En rupture</Stock>}
            <LienNoirBtnUnderlineST margin={"0"} lib="Changer de magasin"  onClick={() => { setChangeMag(true) }} />
          </Livr>
          <Livr>
            {isLoading && <SpinnerInlineST/>}
            <div className="in green fs18"><MdOutlineLocalShipping/>Livraison à domicile</div>
            {/* <Stock><BadgeStyled status="error"/>En rupture</Stock> */}
            {dataStockWeb>0 ? <Stock><BadgeStyled status="success" />En stock</Stock> : <Stock><BadgeStyled status="error"/>En rupture</Stock>}
            <div>
            Expédition en 3 jours ouvrés<br></br>
            Offerte dès 50€ d'achats
            </div>
            <LienNoirBtnUnderlineST margin={"0"} lib="Choisir ce mode de livraison" onClick={choixLivrDomicile}/>
          </Livr>
        </Livraison>
      <BoutonBlancBtnST className={dataStock === 0 ? "disabled" : ""} lib="Ajouter au panier" width="100%" onClick={() => panierAjout(data)}/>
      <BoutonFavouriteST isFav={isFav} lib="Pour un futur projet couture" width="100%"  onClick={() => handleFavouriteChange(!isFav,data)}/>
    </Buts>
      </Wrap>
     {showForm === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form"><PopPanierST onClick={() => setShowForm(false)} data={data} quantity={quantity} totalPrice={totalPrice}/></WrapFormPop>,
               document.body
     ) : ""}
     {changeMag === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form">
        <PopLocalisationST onClick={() => setChangeMag(false)}/>
        </WrapFormPop>,
        document.body
     ) : ""}
     </>
  );
}


