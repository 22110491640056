import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const Txt = styled.div`
   padding: 0 50px;
   @media screen and (max-width: 786px) {
    padding: 0 20px;
   }
`;



const Bloc = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 0;
justify-content: flex-start;
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;



export default function TextST({txt}) {
    return (
            <Bloc>
                <Txt>
                    <p>{ReactHtmlParser(txt)}</p>
                </Txt>
            </Bloc>
    )
}