import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Import Leaflet
import { FaMapMarkerAlt } from "react-icons/fa";
import ReactDOMServer from 'react-dom/server'; 
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import malo from "../../assets/images/mag/22.jpg"
import brest from "../../assets/images/mag/7.jpg"
import lannion from "../../assets/images/mag/13.jpg"
import lorient from "../../assets/images/mag/10.jpg"
import quimper from "../../assets/images/mag/6.jpg"
import rennes from "../../assets/images/mag/12.jpg"
import brieuc from "../../assets/images/mag/11.jpg"
import vannes from "../../assets/images/mag/9.jpg"
import redon from "../../assets/images/mag/25.jpg"
import nantes from "../../assets/images/mag/30.jpg"
import dinan from "../../assets/images/mag/37.jpg"
import internet from "../../assets/images/mag/17.jpg"
import picDefault from "../../assets/images/photo.jpg";

const StyledWrapper= styled.div`
position: relative;
width: 100%;
height: 500px;
margin: 20px auto;
padding: 20px;
display: flex;  
@media only screen and (max-width: 768px) {
  flex-direction: column;
  height: auto;
}
`

const StyledMapContainer = styled(MapContainer)`
  width: 50% !important;
  height: 500px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    margin-top: 20px;
  }
`;

const Liste = styled.ul`
width: 50%;
height: 100%;
gap: 50px;
display: flex;
flex-direction: column;
overflow-y: scroll;
@media only screen and (max-width: 768px) {
  width: 100%;
  height: 430px;
}
`;  
const Item = styled.div`
&>img {
  width: 260px;
  height: auto;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.5);
}
@media only screen and (max-width: 1250px) {
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 768px) {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
`;

const Flex = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 20px;
&>img {
  width: 350px;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.5);
}
`;

const Det = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
align-items: flex-start;
gap: 10px;
&>h2 {
  font-size: 18px;
  margin: 0;
}
&>p {
  margin: 0;
}
&> {
  display: block;
}
&>a.info {
  min-width: unset;
  padding: 10px;
  margin: 0;
  width: max-content;
}
`;

const customIcon = L.divIcon({
  html: ReactDOMServer.renderToString(<FaMapMarkerAlt style={{ fontSize: '30px', color: 'red' }} />),
  className: 'custom-div-icon'
});

const greenIcon = L.divIcon({
  html: ReactDOMServer.renderToString(<FaMapMarkerAlt style={{ fontSize: '30px', color: 'green' }} />),
  className: 'custom-div-icon'
});

export default function MapST() {
  const [isLoading, setIsLoading] = useState(false);
  const [magasins, setMagasins] = useState([]);

  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
    .then((response) => {
      setMagasins(response?.data?.Magasin);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
    });
  }, []);
  function generateGoogleMapsLink(position) {
    const [lat, lng] = position;
    return `https://www.google.com/maps/dir//${lat}+${lng}/@${lat},${lng},12z/data=!4m6!4m5!1m0!1m3!2m2!1d${lng}!2d${lat}?entry=ttu`;
  }
  const centerPosition = [47.9812583, -4.0395185];
  const markerPositions = [
    { position: [48.6245401, -1.9956291], name: 'Self Tissus Saint-Malo', icon: customIcon, img: malo },
    { position: [48.42792700, -4.46236400], name: 'Self Tissus Brest', icon: customIcon, img: brest },
    { position: [48.73228900, -3.43858800], name: 'Self Tissus Lannion', icon: customIcon, img: lannion },
    { position: [47.76469200, -3.39921800], name: 'Self Tissus Lorient', icon: customIcon, img: lorient },
    { position: [48.01723100, -4.08640500], name: 'Self Tissus Quimper', icon: customIcon, img: quimper },
    { position: [46.1238507, -0.7535018], name: 'Self Tissus Rennes', icon: customIcon, img: rennes },
    { position: [48.49851300, -2.8130911], name: 'Self Tissus Saint-Brieuc', icon: customIcon, img: brieuc },
    { position: [47.65468600, -2.72067200], name: 'Self Tissus Vannes', icon: customIcon, img: vannes },
    { position: [47.65106820, -2.08421430], name: 'Self Tissus Redon', icon: customIcon, img: redon },
    { position: [47.18426995, -1.46081174], name: 'Self Tissus Nantes Sud', icon: customIcon, img: nantes },
    { position: [48.44499553, -2.06685271], name: 'Self Tissus Dinan', icon: customIcon, img: dinan },
    { position: [47.9812583, -4.0395185], name: 'Site Internet', icon: greenIcon, img: internet },
  ].map(marker => ({
    ...marker,
    link: generateGoogleMapsLink(marker.position)
  }));

  return (
    <div  style={{ width: '100%', height: '100%' }}>
    <StyledWrapper>
     <Liste>
      {
        magasins.map((magasin, index) => (
          <Item key={index}>
            <h2>{magasin.NomCommercial}</h2>
            <Flex>
            <img src={magasin?.TabImage[0]?.url} alt={magasin?.TabImage[0]?.alt}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = picDefault;
              }} loading="lazy"/>
            <Det>
              <p>{magasin.Adresse1}<br></br>
              {magasin.Adresse2}<br></br>
              {magasin.Ville}</p>
              <a href={`mailto:${magasin.Mail}`}>{magasin.Mail}</a>
              <a href={`tel:${magasin.Tel}`}>{magasin.Tel}</a>
              <BoutonNoirLnkST className={"info"} href={magasin?.slug} lib="Infos et horaires" target="_blank"/>
              
            </Det>
            </Flex>
          </Item>
        ))
      }
            

     </Liste>
          <StyledMapContainer center={centerPosition} zoom={6} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markerPositions.map((marker, idx) => (
              <Marker key={idx} position={marker.position} icon={marker.icon}>
              <Popup>
                <img src={marker.img} alt={marker.name} style={{ width: '100px', height: 'auto' }} />
                <br />
                <FaMapMarkerAlt /> {marker.name} <br /> 
                <a href={marker.link} target="_blank" rel="noopener noreferrer">Itinéraire vers ce magasin</a>
              </Popup>
            </Marker>
            ))}
          </StyledMapContainer>
    </StyledWrapper>
    {/* <iframe width="519" height="405" src="http://maps.google.com/maps/ms?hl=fr&amp;ie=UTF8&amp;msa=0&amp;msid=111553190976156256484.00045af15978a2bb49a38&amp;s=AARTsJpLDGfDH8QnHiMVhSgvvNfR75HNmw&amp;ll=48.305121,-3.12561&amp;spn=1.479768,2.850952&amp;z=8&amp;output=embed"></iframe><br /><small><a href="http://maps.google.com/maps/ms?hl=fr&amp;ie=UTF8&amp;msa=0&amp;msid=111553190976156256484.00045af15978a2bb49a38&amp;ll=48.305121,-3.12561&amp;spn=1.479768,2.850952&amp;z=8&amp;source=embed">Agrandir le plan</a></small> */}
    </div>
  );
}

