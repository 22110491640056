import React, {useState, useEffect} from "react";
import {Wrap} from "./ElementsMembre";


export default function MembrePointsST({className}) {
 

    return (
        <Wrap className={className}>
          <h3 className="center homemadeTitle initial orange">Mes points de fidélité</h3>
        </Wrap>
  );
}
