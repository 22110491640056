import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const Btn = styled.a`
text-decoration: none;
border: none;
background: ${props => props.theme.redST};
display: inline-block; 
text-align: center;
align-items: center; 
justify-content: center;
align-items: center;
padding: 8px;
width: ${props => props.width || "240px"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;
color: #fff; 
font-size: 17px;
font-weight: 600;
text-transform: uppercase;
transition: all .2s ease-out;
&> span {
  display: block;
  padding: 15px; 
  border: 2px dashed #fff; 
}

  &:hover {
    box-shadow: inset 0 0 0 2px ${props => props.theme.redST};
    background: #fff;
    color: ${props => props.theme.redST};
    text-decoration: none;
    &> span {
  display: block;
  padding: 15px; 
  border-color: ${props => props.theme.redST};
}
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonRouge2ST(props) {


    return <Btn className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} href={props.url}><span>{props.lib}</span></Btn>   


}