import React, {useState, useEffect} from "react";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled,Legal } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 10px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
export default function FormContactST({data}) {
  const [magasins, setMagasins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
    .then((response) => {
      setMagasins(response?.data?.Magasin);
        setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);
    return (
        <Wrap>
            <Txt> 
              <h1>{data?.Titre}</h1>
              <div>{ReactHtmlParser(data?.Corps)}</div>
              </Txt>
                <FormStyled floating>
                  <InputStyled
                      id="prenom"
                      name="prenom"
                      placeholder="Prénom"
                      type="text"
                      autoComplete
                  />
                  <Label for="prenom">Prénom</Label>
                  </FormStyled>

                <FormStyled floating>
                  <InputStyled
                        id="nom"
                        name="nom"
                        placeholder="Nom"
                        type="text"
                        autoComplete
                  />
                  <Label for="nom">Nom</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                      id="exampleEmail"
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      autoComplete
                  />
                  <Label for="exampleEmail">E-mail</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="magasin"
                    name="magasin"
                    placeholder="Votre magasin"
                    type="select"
                  >
                    <option>Choisir un magasin</option>
                    {
                      magasins.map((magasin, index) => {
                        return (
                          <option key={index} value={magasin?.ID}>{magasin?.NomCommercial}</option>
                        )
                      })
                    }
                    </InputStyled>
                  <Label for="magasin">Votre magasin</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="commande"
                    name="commande"
                    placeholder="Numéro de commande"
                    type="text"
                    autoComplete
                />
                  <Label for="commande">Numéro de commande</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="sujet"
                    name="sujet"
                    placeholder="Sujet"
                    type="text"
                    autoComplete
                />
                  <Label for="sujet">Sujet</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="demande"
                    name="demande"
                    placeholder="Demande"
                    type="textarea"
                />
                  <Label for="demande">Demande</Label>
                </FormStyled>

                <BoutonNoirST lib={"Envoyer votre demande"} />
                {/* <Legal>
                  {data?.Corps}
                </Legal> */}
               
          </Wrap>
  );
}
