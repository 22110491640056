import React, {useState} from "react";
import styled from 'styled-components';
import { FaRegClock } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import imgBlogDefault from "../../images/blog.webp"
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlog } from "../DesignSystem/SelfTissus/ContainersST";
import { GiSewingNeedle } from "react-icons/gi";
import { Card } from 'antd';
import TitreH2TextST from "../ContenusST/TitreH2Text";
import ImgDroitTxtST from "../ContenusST/ImgDroitTxt";
import ImgGaucheTxtST from "../ContenusST/ImgGaucheTxt";
import picDefault from "../../assets/images/photo.jpg";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import BlogListeST from "./BlogListeSF";

const Img = styled.img`
flex-basis: 40%;
max-height: 530px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
    /* max-height: 300px; */
}
`;

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 50px;
justify-content: center;
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;

const Txt = styled.div`
    flex-basis: 45%;
`;

const Inside = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
gap: 30px;
`;


const Infos = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
padding: 10px 0 20px 0;
&>svg {
    color: ${props => props.theme.redST};
    font-size: 18px;
    stroke-width: 30px;
}
&>span, a {
color :${props => props.theme.grey09ST} !important;
font-size: 23px;
padding: 0 20px;
}
`;

const ImgBlog = styled.img`
width: 80%;
box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
@media screen and (max-width: 786px) {
    width: 100%;
}
`;

const data = {
    Titre: "Titre",
    Corps: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>",
}

export default function BlogFicheST({titre}) {
  
function FormateDate(date){
  if(date !== undefined && date !== null && date !== ""){
    const dateObj = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString('fr-FR', options);
  }
  return "";
}

  return (
      <Section className="noPadding">
         <WrapperBlog>
         <h1 className="center linesGreen">{'Titre'}</h1>
         <ImgBlog alt="example" src={"https://dropinblog.net/34254688/files/featured/tuto-couture-sac-julia_566ebc54-000e-4ff2-8ad7-c6cd1b9da464-bkqao.jpg"} />
            <Infos>
            <span>{"Auteur"}</span><GiSewingNeedle/>
            <a>{"Categorie"}</a><GiSewingNeedle/>
            <span>{" 11/01/2024"}</span><GiSewingNeedle/>
            <span>{" 3 minutes "}</span>
            </Infos>    
            <TitreH2TextST data={data} padding={"0"}/>
            <Wrap>
                <Inside>
                    <Txt>
                        <h2 className="homemadeTitle green initial">{"Titre"}</h2>
                        {ReactHtmlParser(data?.Corps)}
                    </Txt>
                </Inside>
                <Img src={"https://selftissus.myshopify.com/cdn/shop/files/1705502482_0_260x.jpg?v=34772126533"}
                onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault;
                }} loading="lazy"
                    />
            </Wrap>
            <Wrap>
                <Img src={"https://selftissus.myshopify.com/cdn/shop/files/1705502482_0_260x.jpg?v=34772126533"}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = picDefault;
                    }} loading="lazy"
                    />
                <Inside>
                    <Txt>
                        <h2 className="homemadeTitle green initial">{data?.Titre}</h2>
                        {ReactHtmlParser(data?.Corps)}
                    </Txt>
                </Inside>
              
            </Wrap>
            <BoutonNoirLnkST href={"#"} lib={"Exemple bouton"} target={"_self"}/>
            
        </WrapperBlog>
        <LienNoirST href={"/communaute/tendances/"} lib={"Retour au contenu"}/>
        <BlogListeST titre={"Voir aussi : "}/>
      </Section>
  );
}