import styled from "styled-components";
import {Table } from 'antd';
import { Link as Link2}  from "@reach/router";

export const ButtonInfosLinkMembre = styled(Link2)`
cursor: pointer;
background: ${props => props.theme.bleuHP};
border-radius: 5px;
border: solid 1px #fff !important;
min-width: 305px;
max-width: 305px;
min-height: 65px;
max-height: 65px;
padding: 20px;
display: flex;
flex-direction: row;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
flex-shrink: 0;
position: relative;
overflow: hidden;
transition: all .3s ease; 
box-sizing: border-box;
color: #fff !important;
text-align: center;
font: 600 22px Quicksand, sans-serif;
text-transform: uppercase;
position: relative; 
text-decoration: none;
&.inverse {
  background: #fff;
  color: ${props => props.theme.bleuHP} !important;
  border: solid 1px ${props => props.theme.bleuHP} !important;
  min-width: 355px;
  max-width: 355px;
  min-height: 95px;
  max-height: 95px;
  @media (max-width: 767px) {
    max-width: 100%;
    min-width: 100%;
  }
}
&.lowercase {
    text-transform: lowercase;
}
&.flex {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
&.maxUnset {
    max-width: unset;
}
  &:hover {
    background: #fff;
    color: ${props => props.theme.bleuHP} !important;
    border-color: ${(props) => props.theme.bleuHP} !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    text-decoration: none;
    }
    &.selected {
        border-color: ${(props) => props.theme.rose} !important;
        color: ${(props) => props.theme.rose} !important; 
        /* &:hover {
            color: #fff !important;
            border-color: #fff  !important;
        } */
    }
  @media (max-width: 767px) {
    &.inverse {
    text-transform: capitalize;
    }
    width: 100%;
  }
 
`;


export const StyledTable = styled(Table)`
width: 100%;
  .ant-table-thead .ant-table-cell {
  background-color: ${props => props.theme.bleuHP} !important;
  }
.ant-table-thead > tr > th {
    background: ${(props) => props.theme.bleuHP};
    color: #fff;
    font: 500 16px "Unbounded", sans-serif;
    text-align: center;
}
.ant-table-cell{
    color: ${(props) => props.theme.bleuHP};
    font: 500 15px "Unbounded", sans-serif;
}
`;

export const IdPage = styled.div`
color: #afabab;
transition: color 0.2s ease-in 0.25s;
font-size: 14px;
text-align: right;
&:hover {
  color: ${props => props.theme.bleuHP};
}
`

export const Txt = styled.div`
position: relative;
width: 100%;
padding: 40px;
border: 1px solid #aba796;
box-shadow: rgba(0,0,0,0.25) 0px 4px 4px 0px;
background: rgb(255, 255, 255);
border-radius: 5px;
margin-bottom: 30px;
@media (max-width: 768px) {
  padding: 20px;
}
`

export const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
`;