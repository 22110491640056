const initialState = {
    isLogged: false,
};
 
const firstSliceReducer = (state = initialState, action) => {
   
    switch (action.type) {
        
        case 'SET_ISLOGGED_OK':
          return {
            ...state,
            isLogged: action.payload,
          };
        default:
          return state; // Add this line
      }
       
   
};

export const setIsLogged = (payload) => ({
    type: 'SET_ISLOGGED_OK',
    payload,
  });
 
 
export default firstSliceReducer;