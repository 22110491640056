import React, {useState} from "react";
import styled from 'styled-components';
import { Section, WrapperProds } from "../DesignSystem/SelfTissus/ContainersST";
import { InputStyled, FormStyled } from "../DesignSystem/SelfTissus/Forms";
import { Card, Badge } from 'antd';
import picDefault from "../../assets/images/photo.jpg";
import ReactHtmlParser from 'react-html-parser';

const CardStyled = styled(Card)`
width: 400px;
min-height: 400px;
@media screen and (max-width: 992px) {
  width: 300px;
}
@media screen and (max-width: 768px) {
  width: 100%;
  margin: 0 auto;
  max-height: unset;
  min-height: unset;
}
&>h2 {
  font-size: 17px !important;
  margin: 0;
}
`;

const Prods = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 50px;
width: 70%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const FilterProds = styled.div`
width: 20%;
@media screen and (max-width: 768px) {
  display: none;
}
`;
const FilterProds2 = styled.div`
display: flex;
align-items: center;
width: 100%;
gap: 50px;
justify-content: flex-end;
@media screen and (max-width: 768px) {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Result = styled.p`
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
`

export default function ArticlesListeST({data}) {
const [isLoading, setIsLoading] = useState(true); 
const [dataProduitsNbr, setDataProduitsNbr] = useState(data?.length || 0);
const [filterOption, setFilterOption] = useState('');

const handleFilterChange = (option) => {
  if (typeof option === 'string') {
    setFilterOption(option);
  } else {
    setFilterOption(option.target.value);
  }
};
const getFilteredData = () => {
  let filteredData = [...data];
  switch (filterOption) {
    case 'PrixC':
      filteredData?.sort((a, b) => a?.Prix - b?.Prix);
      break;
    case 'PrixD':
      filteredData?.sort((a, b) => b?.Prix - a?.Prix);
      break;
      case 'a':
        filteredData?.sort((a, b) => {
          if (!a?.libArticle) return 1;
          if (!b?.libArticle) return -1;
          return a.libArticle.localeCompare(b.libArticle);
        });
        break;
      case 'z':
        filteredData?.sort((a, b) => {
          if (!a?.libArticle) return 1;
          if (!b?.libArticle) return -1;
          return b.libArticle.localeCompare(a.libArticle);
        });
      break;
    default:
      break;
  }
  return filteredData;
};
const getBadgeProps = (item) => {
  if (item?.ArticleSemaine) {
      return { text: "Article de la semaine", color: "#79b69f" };
  } else if (item?.Abajourable) {
      return { text: "Abajourable", color: "#da46da" };
  } else if (item?.Nouveaute) {
      return { text: "Nouveauté", color: "#07af5b" };
  } else if (item?.PromoMois) {
      return { text: "Promo du mois", color: "#5089d7" };
  } else if (item?.PromoWeb) {
      return { text: "Promo Web", color: "#f05b32" }; 
  }
  return { text: "", color: "" };
};
  return (
      <Section>
         <WrapperProds>
          <FilterProds>
          <ul className="none">
            <Tri>Trier par</Tri>
            <li><Link onClick={() => handleFilterChange('PrixC')}>Prix croissant</Link></li>
            <li><Link onClick={() => handleFilterChange('PrixD')}>Prix décroissant</Link></li>
            <li><Link onClick={() => handleFilterChange('a')}>De A à Z</Link></li>
            <li><Link onClick={() => handleFilterChange('z')}>Z à A</Link></li>
          </ul>
          </FilterProds>
          <Prods>
            <FilterProds2>
            <FormStyled>
              <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={handleFilterChange}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC">Prix croissant</option>
                  <option value="PrixD">Prix décroissant</option>
                  <option value="a">De A à Z</option>
                  <option value="z">Z à A</option>
                </InputStyled>
              </FormStyled>
              <Result>{dataProduitsNbr} produits</Result>
            </FilterProds2>
          {
            data && getFilteredData().map((item, i) => {
              const { text, color } = getBadgeProps(item);
              return (
                <Badge.Ribbon style={{display:(text !== "") ? "block" : "none"}}
                text={text} color={color} placement={"start"}
                >
                <CardStyled
                  onClick={() => window.location.href = item?.slug || '#'}
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={
                    <img
                      alt="photo de l'article"
                      src={item?.Articles?.[0]?.Photos?.[0]?.image?.url || picDefault}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = picDefault;
                      }}
                      loading="lazy"
                    />
                  }
                >
                  <h2 className="grey center fs23">{ReactHtmlParser(item?.libArticle)}</h2>
                  <p className="center uppercase fw600"><span className="gras ">Prix PUTTC : </span><span className="center uppercase orange fw600">{item?.Prix+" €"}</span></p>
                </CardStyled>
                </Badge.Ribbon>
              );
            })
          }
          </Prods>  
        </WrapperProds>
    
      </Section>
  );
}