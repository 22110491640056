import React, {useState} from "react";
import styled from 'styled-components';
import { MdStar } from "react-icons/md";

const Stars = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
`
const StyledInput = styled.input`
    display: none;
`;
export default function RatingStarsST({ratingData, mode}) {
const [rating, setRating] = useState(ratingData || 0);
const [hover, setHover] = useState(null);


    return (
    <Stars>
        <div>
        {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;

            return (
            <label key={i}>
                <StyledInput
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={mode === "write" ? () => setRating(ratingValue) : undefined}
                />
                <MdStar
                key={i}
                className="star"
                style={{cursor:mode === "write" ? "pointer" : "default", transition: "color 0.2s"}}
                size={50}
                color={ratingValue <= (hover || rating) ? "#ffce00" : "#e4e5e9"}
                onMouseEnter={mode === "write" ? () => setHover(ratingValue) : undefined}
                onMouseLeave={mode === "write" ? () => setHover(null) : undefined}
                />
            </label>
            );
        })}
        </div>
        {mode === "read" ? <p>{rating < 1 ? "Aucun avis pour le moment" : "Notre note : "+rating}</p> : null}
        
    </Stars>
    );
}