import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoArrowForwardOutline } from "react-icons/io5";

const Btn = styled.a`
text-decoration: none;
background: transparent;
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
padding: 10px;
border: 1px solid ;
border-color: ${props => props.color || "#000"};
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0 auto"};
position: relative;
cursor: pointer;
color: ${props => props.color || "#000"};
font-size: 15px;
font-weight: 400;
transition: all .2s ease-out;
&.absolute {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
  

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonTransparentSmallST(props) {
  const handleClick = () => {
    if (props.onClick) {
        props.onClick();
    }
    if (props.url) {
        window.location.href = props.url;
    }
};

    return <Btn className={props.className} color={props.color} margin={props.margin} width={props.width} widthMob={props.widthMob} onClick={handleClick}>{props.lib}</Btn>   


}