import React from "react";
import styled from "styled-components";
import LinkNoirST from "../DesignSystem/SelfTissus/LinkNoir";
import ReactHtmlParser from 'react-html-parser';

const Wrap = styled.div`
background: ${(props) => props.theme.rose03ST};
width: calc(100% + 200px);
margin-left: -100px;
margin-right: -100px;
padding: 60px 50px;
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 50px 20px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const Txt = styled.div`
color: ${(props) => props.theme.bleuHP}; text-align: left;position: relative; width: 100%;
`;

export default function TitreTxtBtnST({data}) {
  return (
      <Wrap >
            <h2 className="center">{data?.Titre}</h2>
            <Txt>
            <p className="center">
              {ReactHtmlParser(data?.Corps)}
            </p>
            </Txt>
            <LinkNoirST lib={data?.BTNLib} target={data?.BTNMode ? "_blank" : "_self"} href={data?.BTNUrl}/>
      </Wrap>
  );
}