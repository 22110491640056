import React from "react";
import styled from "styled-components";
import BoutonVertLienST from "../DesignSystem/SelfTissus/BoutonVertLien";

const Box=styled.div`
width: 100%;
margin: 0 auto;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
@media screen and (max-width: 768px) {
  padding: 0;
}
`
const Title=styled.p`
font-size: 23px;
color: ${(props) => props.theme.greenST};
font-family: "Homemade Apple", sans-serif;
`

export default function LocalisationMobST() {
  return ( 
    <>
    <Box>
        <h2 className="center homemadeTitle initial green">Astuce</h2>
        <p>Choissisez votre magasin pour afficher les produits disponibles en click and collect près de chez vous</p>
        <BoutonVertLienST lib={"Choisir mon magasin"} href={"/localisation"} />
    </Box>
  </>
)
}
