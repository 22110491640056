import styled, {css} from 'styled-components';
import {Row, Col} from "reactstrap";
import Fade from 'reactstrap/lib/Fade';


export const FadeStyled = styled(Fade)`
padding: ${props => props.padding || "40px 0 0 0"};
background-color: ${props => props.bckg || "transparent"};
&.noPadding {
  padding: 0;
}
&.fade:not(.show){
  height: 0px; 
  padding: 0;
  z-index: -1;
  position: relative;
}
&.fade {
  width: 100%;
  height: auto;
  transition: all .15s linear;
}
`

export const DivChecks = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
width: 100%;
gap: 20px;
&.radio {
  gap: 10px;
}
&.column {
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;

}
@media (max-width: 768px) {
  flex-direction: column;
}
`
export const Check = styled.div`
`
export const Legal = styled.div`
padding: 20px 0;
text-align: justify;
&> ul, &>ul> li {
  padding-left: 0;
  list-style-type: none;
  text-align: justify;
  font-size: 13px !important;

}
`